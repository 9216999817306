import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Map: any;
  Time: any;
  Upload: any;
};

export type AddAppInput = {
  apiKey: Scalars["String"];
  authType: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  name: Scalars["String"];
  token: Scalars["String"];
  url: Scalars["String"];
};

export type AddAppShortcutInput = {
  actionID: Scalars["String"];
  appID: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  shortcutType: ShortcutType;
};

export type AddGateIdInput = {
  brandID: Scalars["String"];
  gateIDs: Array<Scalars["String"]>;
};

export type Admin = {
  __typename?: "Admin";
  accountID: Scalars["String"];
  active: Scalars["Boolean"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  passwordIsSet?: Maybe<Scalars["Boolean"]>;
  phone: Scalars["String"];
  role: AdminRole;
  type: Scalars["String"];
  /** @deprecated userID should be deprecated. */
  userID: Scalars["String"];
};

export type AdminDeleted = {
  __typename?: "AdminDeleted";
  shouldLogout: Scalars["Boolean"];
};

export type AdminDeletedInput = {
  accountID: Scalars["String"];
  token: Scalars["String"];
};

export type AdminProfile = {
  __typename?: "AdminProfile";
  accountID: Scalars["String"];
  chatAdmin: Admin;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  /** @deprecated id should be deprecated. */
  id: Scalars["String"];
  lastLogin?: Maybe<Scalars["Int"]>;
  lastName?: Maybe<Scalars["String"]>;
  passwordIsSet: Scalars["Boolean"];
  phone?: Maybe<Scalars["String"]>;
  phoneVerifiedAt?: Maybe<Scalars["Int"]>;
  role?: Maybe<Scalars["String"]>;
};

export enum AdminRole {
  Admin = "ADMIN",
  Supervisor = "SUPERVISOR",
}

export type Admins = {
  __typename?: "Admins";
  items: Array<Maybe<AdminProfile>>;
  pageInfo: PaginationInfo;
};

export type AnalyticsReport = {
  __typename?: "AnalyticsReport";
  avgAgentResponses?: Maybe<Array<Maybe<AvgAgentResp>>>;
  channelActivity?: Maybe<Array<Maybe<ChannelActivity>>>;
  everyAgentDailyFinishedConv?: Maybe<Array<Maybe<ReportListDailyCounts>>>;
  everyAgentDailyNewConv?: Maybe<Array<Maybe<ReportListDailyCounts>>>;
  everyAgentTotalFinishedConv?: Maybe<Array<Maybe<ReportList>>>;
  everyAgentTotalNewConv?: Maybe<Array<Maybe<ReportList>>>;
};

export type AppShortcut = {
  __typename?: "AppShortcut";
  actionID: Scalars["String"];
  appID: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
  shortcutID: Scalars["String"];
  shortcutType: ShortcutType;
};

export type Application = {
  __typename?: "Application";
  appID: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  name: Scalars["String"];
  shortcuts: Array<Maybe<AppShortcut>>;
};

export type ApplicationDetail = {
  __typename?: "ApplicationDetail";
  appID: Scalars["String"];
  description: Scalars["String"];
  icon: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type AvgAgentResp = {
  __typename?: "AvgAgentResp";
  agentID: Scalars["String"];
  agentName: Scalars["String"];
  avgTimeAgentCompResp?: Maybe<Scalars["String"]>;
  /** @deprecated being replaced by channel activity for irrelevant data */
  avgTimeAgentFirstResponseTime?: Maybe<Scalars["String"]>;
  avgTimeAgentOverallResp?: Maybe<Scalars["String"]>;
};

export type BrandGate = {
  __typename?: "BrandGate";
  gates: GateItems;
};

export type BrandInput = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type BroadcastSubscriberDetails = {
  __typename?: "BroadcastSubscriberDetails";
  broadcastID?: Maybe<Scalars["Int"]>;
  broadcastStatus?: Maybe<Scalars["String"]>;
  broadcastTitle?: Maybe<Scalars["String"]>;
  channelID?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isOptIn?: Maybe<Scalars["Boolean"]>;
  messageID?: Maybe<Scalars["String"]>;
  messageStatus?: Maybe<Scalars["String"]>;
  messageTimestamp?: Maybe<Scalars["String"]>;
  sentAt?: Maybe<Scalars["String"]>;
  subscriberCreatedAt?: Maybe<Scalars["String"]>;
  subscriberID?: Maybe<Scalars["Int"]>;
  subscriberName?: Maybe<Scalars["String"]>;
  subscriberPhone?: Maybe<Scalars["String"]>;
  subscriberUpdatedAt?: Maybe<Scalars["String"]>;
  taskID?: Maybe<Scalars["String"]>;
  templateName?: Maybe<Scalars["String"]>;
};

export type Channel = {
  __typename?: "Channel";
  admins: Array<Maybe<Admin>>;
  businessCategory?: Maybe<WhatsappBusinessCategory>;
  channelName?: Maybe<Scalars["String"]>;
  channelStoreName?: Maybe<Scalars["String"]>;
  channelType: ChannelType;
  createdAt: Scalars["Int"];
  details?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  isConnected?: Maybe<Scalars["Boolean"]>;
  number?: Maybe<Scalars["String"]>;
  phoneId?: Maybe<Scalars["String"]>;
  storeAddress?: Maybe<Scalars["String"]>;
  storeDescription?: Maybe<Scalars["String"]>;
  storeImage?: Maybe<Scalars["String"]>;
  storeWebsite?: Maybe<Scalars["String"]>;
  tenantId: Scalars["Int"];
  tier?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Int"];
};

export type ChannelActivity = {
  __typename?: "ChannelActivity";
  channelName: Scalars["String"];
  channelType: Scalars["String"];
  totalClaimedChat: Scalars["Int"];
};

export enum ChannelCode {
  Bklp = "BKLP",
  Blib = "BLIB",
  Jdid = "JDID",
  Lzda = "LZDA",
  Shpe = "SHPE",
  ShpeChat = "SHPE_CHAT",
  Tkpd = "TKPD",
  Zlgo = "ZLGO",
  Zlra = "ZLRA",
}

export type ChannelData = {
  name?: InputMaybe<Scalars["String"]>;
  tenant: Scalars["String"];
  token: Scalars["String"];
  userID: Scalars["String"];
};

export type ChannelFilter = {
  brandId: Scalars["String"];
  channelType: ChannelType;
  gateId: Scalars["String"];
};

export type ChannelList = {
  __typename?: "ChannelList";
  businessName?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["String"]>;
  isConnected?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  tenantID?: Maybe<Scalars["String"]>;
};

export enum ChannelStatus {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
}

export enum ChannelType {
  Shopee = "SHOPEE",
  Tokopedia = "TOKOPEDIA",
  Whatsapp = "WHATSAPP",
}

export type Channels = {
  __typename?: "Channels";
  items: Array<Maybe<Channel>>;
  pageInfo: PaginationInfo;
};

export type ChannelsFilter = {
  brandIds?: InputMaybe<Array<Scalars["ID"]>>;
  channelIds?: InputMaybe<Array<Scalars["ID"]>>;
  channelTypes?: InputMaybe<Array<ChannelType>>;
  isConnected?: InputMaybe<Scalars["Boolean"]>;
  query?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ChannelStatus>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]>>;
  userIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum ChannelsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ChatAdminsFilterInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  role?: InputMaybe<Scalars["String"]>;
};

export type ChatBot = {
  __typename?: "ChatBot";
  enabled: Scalars["Boolean"];
  tenantName: Scalars["String"];
};

export type ChatBroadcast = {
  __typename?: "ChatBroadcast";
  broadcastStatus?: Maybe<Scalars["String"]>;
  broadcastTitle?: Maybe<Scalars["String"]>;
  channelID?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  sentAt?: Maybe<Scalars["String"]>;
  taskID?: Maybe<Scalars["String"]>;
  templateComponents?: Maybe<Array<Maybe<TemplateComponent>>>;
  templateName?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type ChatBroadcastSubscribersDetailsInput = {
  Query?: InputMaybe<Scalars["String"]>;
  broadcastID: Scalars["String"];
  channelID: Scalars["Int"];
  messageStatus?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type ChatBroadcastSubscribersDetailsRes = {
  __typename?: "ChatBroadcastSubscribersDetailsRes";
  cursorAfter?: Maybe<Scalars["Int"]>;
  cursorBefore?: Maybe<Scalars["Int"]>;
  viewSubscribers?: Maybe<Array<Maybe<BroadcastSubscriberDetails>>>;
};

export type ChatBroadcastsInput = {
  channelID: Scalars["Int"];
  pagination?: InputMaybe<PaginationInput>;
  query?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type ChatBroadcastsRes = {
  __typename?: "ChatBroadcastsRes";
  broadcastMessages?: Maybe<Array<Maybe<ChatBroadcast>>>;
  cursorAfter?: Maybe<Scalars["Int"]>;
  cursorBefore?: Maybe<Scalars["Int"]>;
};

export type ChatCancelBroadcastMessageRes = {
  __typename?: "ChatCancelBroadcastMessageRes";
  success?: Maybe<Scalars["Boolean"]>;
};

export type ChatCreateBroadcastMessageInput = {
  broadcastTitle: Scalars["String"];
  channelID: Scalars["Int"];
  recipients: Array<Scalars["Int"]>;
  sentAt?: InputMaybe<Scalars["Int"]>;
  templateName: Scalars["String"];
};

export type ChatCreateBroadcastMessageRes = {
  __typename?: "ChatCreateBroadcastMessageRes";
  success?: Maybe<Scalars["Boolean"]>;
};

export type ChatDetailBroadcastInput = {
  broadcastID: Scalars["String"];
  channelID: Scalars["Int"];
};

export type ChatDetailBroadcastRes = {
  __typename?: "ChatDetailBroadcastRes";
  broadcastMessage?: Maybe<ChatBroadcast>;
  previewTemplate?: Maybe<MessageTemplateList>;
};

export type ChatExistResp = {
  __typename?: "ChatExistResp";
  exist?: Maybe<Scalars["Boolean"]>;
  link?: Maybe<Scalars["String"]>;
};

export type ChatFilter = {
  channelIDs?: InputMaybe<Array<Scalars["Int"]>>;
  isUnread?: InputMaybe<Scalars["Boolean"]>;
  labelIDs?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ChatImportBroadcastContactsInput = {
  channelID: Scalars["Int"];
  file: Scalars["Upload"];
  purpose?: InputMaybe<Scalars["String"]>;
};

export type ChatImportBroadcastContactsRes = {
  __typename?: "ChatImportBroadcastContactsRes";
  contacts?: Maybe<Array<Maybe<Subscriber>>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ChatLabel = {
  __typename?: "ChatLabel";
  color: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  rooms?: Maybe<Array<Maybe<ChatLabelRoom>>>;
  tenantID: Scalars["Int"];
  title: Scalars["String"];
};

export type ChatLabelRoom = {
  __typename?: "ChatLabelRoom";
  agentID?: Maybe<Scalars["Int"]>;
  cUser: RoomContactUser;
  channelID: Scalars["Int"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChatList = PageInfoInterface & {
  __typename?: "ChatList";
  data: Array<Maybe<ChatListData>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChatListAccountUser = {
  __typename?: "ChatListAccountUser";
  accountID: Scalars["String"];
  accountUserID: Scalars["Int"];
  active: Scalars["Boolean"];
  /** @deprecated userID should be deprecated */
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ChatListContactUser = {
  __typename?: "ChatListContactUser";
  dp?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type ChatListData = {
  __typename?: "ChatListData";
  accountUsers?: Maybe<Array<Maybe<ChatListAccountUser>>>;
  contactUser?: Maybe<ChatListContactUser>;
  messages?: Maybe<Array<Maybe<ChatListMessage>>>;
  room?: Maybe<ChatListRoom>;
  unreadMsgCount?: Maybe<Scalars["Int"]>;
};

export type ChatListInput = {
  filter?: InputMaybe<ChatFilter>;
  page?: InputMaybe<Page>;
  tenantID?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  userID: Scalars["String"];
};

export type ChatListMessage = {
  __typename?: "ChatListMessage";
  fileName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  interactive?: Maybe<Scalars["String"]>;
  mediaSize?: Maybe<Scalars["String"]>;
  mediaURL?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  mimeType?: Maybe<Scalars["String"]>;
  receiver: Scalars["String"];
  sendByAgent?: Maybe<Scalars["Boolean"]>;
  sender: Scalars["String"];
  senderName?: Maybe<Scalars["String"]>;
  status: MessageStatus;
  time: Scalars["String"];
  type: MessageType;
};

export type ChatListRoom = {
  __typename?: "ChatListRoom";
  agentID?: Maybe<Scalars["String"]>;
  channelID: Scalars["String"];
  id: Scalars["String"];
  labels?: Maybe<Array<Maybe<ChatLabel>>>;
  lastMessageID: Scalars["String"];
  lastMessageTimestamp: Scalars["String"];
  name: Scalars["String"];
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  accountUser?: Maybe<ChatListAccountUser>;
  add?: Maybe<Scalars["Boolean"]>;
  contactUser?: Maybe<ChatListContactUser>;
  message?: Maybe<ChatListMessage>;
  room?: Maybe<ChatListRoom>;
};

export type ChatMessageStatus = {
  __typename?: "ChatMessageStatus";
  messageID: Scalars["String"];
  messageStatus: MessageStatus;
  roomID: Scalars["String"];
};

export type ChatRebroadcastMessageInput = {
  broadcastId: Scalars["String"];
  channelID: Scalars["Int"];
};

export type ChatRebroadcastMessageRes = {
  __typename?: "ChatRebroadcastMessageRes";
  success?: Maybe<Scalars["Boolean"]>;
};

export type ChatSubscribersInput = {
  channelID: Scalars["Int"];
  isOptIn?: InputMaybe<Scalars["Boolean"]>;
  pageSpec?: InputMaybe<PageSpec>;
  pagination?: InputMaybe<PaginationInput>;
  query?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortDirection?: InputMaybe<Scalars["String"]>;
};

export type ChatSubscribersRes = {
  __typename?: "ChatSubscribersRes";
  cursorAfter?: Maybe<Scalars["Int"]>;
  cursorBefore?: Maybe<Scalars["Int"]>;
  pageInfo?: Maybe<PageSpecInfo>;
  subscribers?: Maybe<Array<Maybe<Subscriber>>>;
};

export type ConnectedChannelInput = {
  brandId: Scalars["String"];
  channelCode?: InputMaybe<ChannelCode>;
  clientKey: Scalars["String"];
  gateId?: InputMaybe<Scalars["String"]>;
};

export type ConnectedSalesChannelsInput = {
  brandId: Scalars["String"];
  channelCodes: Array<InputMaybe<ChannelCode>>;
};

export type Contact = {
  __typename?: "Contact";
  address: Scalars["String"];
  channelContactId: Scalars["String"];
  channelId: Scalars["Int"];
  createdAt: Scalars["Int"];
  details: Scalars["String"];
  id: Scalars["Int"];
  isOptIn: Scalars["Boolean"];
  name: Scalars["String"];
  number: Scalars["String"];
};

export type Count = {
  __typename?: "Count";
  count: Scalars["Int"];
  status: Scalars["String"];
};

export type CreateUserInput = {
  LastName: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
  tenantID: Scalars["String"];
};

export type CreateUserOutput = {
  __typename?: "CreateUserOutput";
  userID: Scalars["String"];
};

export type CredentialResp = {
  __typename?: "CredentialResp";
  type: Scalars["String"];
  url: Scalars["String"];
  urlChannel: Scalars["String"];
};

export type Currency = {
  __typename?: "Currency";
  amount1000?: Maybe<Scalars["Int"]>;
  code?: Maybe<Scalars["String"]>;
  fallbackValue?: Maybe<Scalars["String"]>;
};

export type CursorInput = {
  cursor?: InputMaybe<Scalars["Int"]>;
  cursorBefore?: InputMaybe<Scalars["Boolean"]>;
};

export type DateTime = {
  __typename?: "DateTime";
  calendar?: Maybe<Scalars["String"]>;
  dayOfMonth?: Maybe<Scalars["Int"]>;
  dayOfWeek?: Maybe<Scalars["String"]>;
  fallbackValue?: Maybe<Scalars["String"]>;
  hour?: Maybe<Scalars["Int"]>;
  minute?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type DeleteInteractiveMessageInput = {
  id: Scalars["Int"];
};

export type DeleteTenantLabelOutput = {
  __typename?: "DeleteTenantLabelOutput";
  success?: Maybe<Scalars["Boolean"]>;
};

export type DistrictOption = {
  __typename?: "DistrictOption";
  cityName: Scalars["String"];
  districtName: Scalars["String"];
  key: Scalars["String"];
  provinceName: Scalars["String"];
  value: Scalars["String"];
};

export enum Events {
  MlcbListUpdated = "MLCB_LIST_UPDATED",
  UnclaimedListUpdated = "UNCLAIMED_LIST_UPDATED",
}

export type ExecuteCommandInput = {
  actionID: Scalars["String"];
  appID: Scalars["String"];
  query: Scalars["String"];
  rooomID?: InputMaybe<Scalars["String"]>;
};

export type FetchMessageById = PageInfoInterface & {
  __typename?: "FetchMessageByID";
  accountUsers?: Maybe<Array<Maybe<ChatListAccountUser>>>;
  contactUser?: Maybe<ChatListContactUser>;
  messages: Array<Maybe<ChatListMessage>>;
  pageInfo?: Maybe<PageInfo>;
  room?: Maybe<ChatListRoom>;
};

export type FetchMessageInput = {
  messageID: Scalars["String"];
  page?: InputMaybe<Page>;
  roomID: Scalars["String"];
  scroll: Scalars["String"];
  userID: Scalars["String"];
};

export type Gate = {
  __typename?: "Gate";
  brandID: Scalars["String"];
  createdAt: Scalars["Int"];
  details: Array<GateDetail>;
  id: Scalars["String"];
};

export type GateDetail = {
  __typename?: "GateDetail";
  channelCode: Scalars["String"];
  createdAt: Scalars["Int"];
  credentials: GateDetailCredentials;
  integratedAt: Scalars["Int"];
  isIntegrated: Scalars["Boolean"];
  language: Scalars["String"];
  name: Scalars["String"];
};

export type GateDetailCredentials = {
  __typename?: "GateDetailCredentials";
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  fsId?: Maybe<Scalars["Int"]>;
  shopId?: Maybe<Scalars["Int"]>;
};

export type GateInput = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
};

export type GateItems = {
  __typename?: "GateItems";
  items: Array<Maybe<Gate>>;
  pageInfo: StoreFrontPageInfo;
};

export type GenericDashboardReport = {
  __typename?: "GenericDashboardReport";
  averageResponseTimeAgent?: Maybe<Scalars["String"]>;
  averageResponseTimeWhole?: Maybe<Scalars["String"]>;
  averageTimeSolution?: Maybe<Scalars["String"]>;
  avgTimeFirstResponse?: Maybe<Scalars["String"]>;
  totalConversationsActive?: Maybe<Scalars["String"]>;
  totalConversationsClaimed?: Maybe<Scalars["String"]>;
  totalConversationsDone?: Maybe<Scalars["String"]>;
  totalConversationsInQueue?: Maybe<Scalars["String"]>;
  totalConversationsIncoming?: Maybe<Scalars["String"]>;
};

export type GenericDashboardReportInput = {
  agentID?: InputMaybe<Scalars["String"]>;
  fromDate: Scalars["String"];
  tenantID: Scalars["String"];
  toDate: Scalars["String"];
};

export type GenericReport = {
  __typename?: "GenericReport";
  avgTimeAgentResponse?: Maybe<Scalars["String"]>;
  totalConversationsClaimed?: Maybe<Scalars["String"]>;
  totalConversationsEnter?: Maybe<Scalars["String"]>;
  totalConversationsFinished?: Maybe<Scalars["String"]>;
  totalConversationsInQueue?: Maybe<Scalars["String"]>;
  totalConversationsUnclaimed?: Maybe<Scalars["String"]>;
};

export type GenericReportInput = {
  agentID?: InputMaybe<Scalars["String"]>;
  fromDate: Scalars["String"];
  toDate: Scalars["String"];
};

export type GetDistrictOptions = {
  __typename?: "GetDistrictOptions";
  districtOptions: Array<Maybe<DistrictOption>>;
};

export type GetLabelsByTenantInput = {
  pagination?: InputMaybe<GetLabelsByTenantPagination>;
  withRooms?: InputMaybe<Scalars["Boolean"]>;
};

export type GetLabelsByTenantPagination = {
  cursor?: InputMaybe<GetLabelsByTenantPaginationCursor>;
  limit: Scalars["Int"];
};

export type GetLabelsByTenantPaginationCursor = {
  cursor?: InputMaybe<Scalars["Int"]>;
  cursorBefore?: InputMaybe<Scalars["Boolean"]>;
};

export type GetOperationalHours = {
  __typename?: "GetOperationalHours";
  channelID: Scalars["String"];
  id: Scalars["String"];
  isNewVendor?: Maybe<Scalars["Boolean"]>;
  messageTemplateID?: Maybe<Scalars["String"]>;
  offOperationalHoursInput: Scalars["String"];
  onOperationalText: Scalars["String"];
  operationalHours: Array<Maybe<OperationalHours>>;
  tenantID: Scalars["String"];
};

export type GetRoomsByLabelInput = {
  labelID: Scalars["Int"];
  pagination?: InputMaybe<GetLabelsByTenantPagination>;
};

export type Greetings = {
  __typename?: "Greetings";
  channel: Scalars["String"];
  greetings: Scalars["String"];
  mediaURL?: Maybe<Scalars["String"]>;
  newCustomer: Scalars["Boolean"];
  type: MessageType;
};

export type InteractiveMessage = {
  __typename?: "InteractiveMessage";
  action: InteractiveMessageAction;
  body: InteractiveMessageBody;
  footer?: Maybe<InteractiveMessageFooter>;
  header?: Maybe<InteractiveMessageHeader>;
  type: Scalars["String"];
};

export type InteractiveMessageAction = {
  __typename?: "InteractiveMessageAction";
  button?: Maybe<Scalars["String"]>;
  buttons?: Maybe<Array<InteractiveMessageActionButton>>;
  sections?: Maybe<Array<InteractiveMessageActionSection>>;
};

export type InteractiveMessageActionButton = {
  __typename?: "InteractiveMessageActionButton";
  reply: InteractiveMessageActionButtonReply;
  type: Scalars["String"];
};

export type InteractiveMessageActionButtonInput = {
  reply: InteractiveMessageActionButtonReplyInput;
  type: Scalars["String"];
};

export type InteractiveMessageActionButtonReply = {
  __typename?: "InteractiveMessageActionButtonReply";
  id: Scalars["String"];
  title: Scalars["String"];
};

export type InteractiveMessageActionButtonReplyInput = {
  id: Scalars["String"];
  title: Scalars["String"];
};

export type InteractiveMessageActionInput = {
  button?: InputMaybe<Scalars["String"]>;
  buttons?: InputMaybe<Array<InteractiveMessageActionButtonInput>>;
  sections?: InputMaybe<Array<InteractiveMessageActionSectionInput>>;
};

export type InteractiveMessageActionSection = {
  __typename?: "InteractiveMessageActionSection";
  rows?: Maybe<Array<InteractiveMessageActionSectionRow>>;
  title: Scalars["String"];
};

export type InteractiveMessageActionSectionInput = {
  rows?: InputMaybe<Array<InteractiveMessageActionSectionRowInput>>;
  title: Scalars["String"];
};

export type InteractiveMessageActionSectionRow = {
  __typename?: "InteractiveMessageActionSectionRow";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  title: Scalars["String"];
};

export type InteractiveMessageActionSectionRowInput = {
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  title: Scalars["String"];
};

export type InteractiveMessageBody = {
  __typename?: "InteractiveMessageBody";
  text: Scalars["String"];
};

export type InteractiveMessageBodyInput = {
  text: Scalars["String"];
};

export type InteractiveMessageFooter = {
  __typename?: "InteractiveMessageFooter";
  text: Scalars["String"];
};

export type InteractiveMessageFooterInput = {
  text: Scalars["String"];
};

export type InteractiveMessageHeader = {
  __typename?: "InteractiveMessageHeader";
  document?: Maybe<Media>;
  image?: Maybe<Media>;
  text?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  video?: Maybe<Media>;
};

export type InteractiveMessageHeaderInput = {
  document?: InputMaybe<MediaInput>;
  image?: InputMaybe<MediaInput>;
  text?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
  video?: InputMaybe<MediaInput>;
};

export type InteractiveMessageInput = {
  action: InteractiveMessageActionInput;
  body: InteractiveMessageBodyInput;
  footer?: InputMaybe<InteractiveMessageFooterInput>;
  header?: InputMaybe<InteractiveMessageHeaderInput>;
  type: Scalars["String"];
};

export type IsShopeeConnectedInput = {
  brandId: Scalars["String"];
  clientKey?: InputMaybe<Scalars["String"]>;
  gateId?: InputMaybe<Scalars["String"]>;
};

export type IsTokopediaConnectedInput = {
  brandId: Scalars["String"];
  clientKey?: InputMaybe<Scalars["String"]>;
  gateId?: InputMaybe<Scalars["String"]>;
};

export type LabelsByTenant = {
  __typename?: "LabelsByTenant";
  cursorAfter?: Maybe<Scalars["Int"]>;
  cursorBefore?: Maybe<Scalars["Int"]>;
  labels?: Maybe<Array<Maybe<ChatLabel>>>;
};

export type LinkChannel = {
  channelID: Scalars["String"];
  tenant: Scalars["String"];
  userID: Scalars["String"];
};

export type LoginResp = {
  __typename?: "LoginResp";
  cc: Scalars["String"];
  redirectUrl: Scalars["String"];
};

export type LoginUrlInput = {
  clientId: Scalars["String"];
  redirectUri: Scalars["String"];
  tenantId: Scalars["String"];
};

export type MlcbMenuInput = {
  jsonInput: Scalars["String"];
};

export type Media = {
  __typename?: "Media";
  link?: Maybe<Scalars["String"]>;
};

export type MediaAttributes = {
  __typename?: "MediaAttributes";
  fileName: Scalars["String"];
  mimeType: Scalars["String"];
  size: Scalars["String"];
};

export type MediaInput = {
  link?: InputMaybe<Scalars["String"]>;
};

export type Menu = {
  __typename?: "Menu";
  ParentID: Scalars["String"];
  channelID: Scalars["String"];
  child?: Maybe<Array<Maybe<Menu>>>;
  childCount?: Maybe<Scalars["Int"]>;
  endChat: Scalars["Boolean"];
  id: Scalars["String"];
  name: Scalars["String"];
  status?: Maybe<Scalars["Boolean"]>;
  talkToAgent: Scalars["Boolean"];
  tenantID: Scalars["String"];
  text: Scalars["String"];
};

export type MenuList = {
  __typename?: "MenuList";
  ParentID: Scalars["String"];
  category: Scalars["String"];
  channelID: Scalars["String"];
  cloudMediaID?: Maybe<Scalars["String"]>;
  endChat: Scalars["Boolean"];
  id: Scalars["String"];
  isParentWrapper: Scalars["Boolean"];
  level: Scalars["String"];
  menuOrder?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
  status?: Maybe<Scalars["Boolean"]>;
  talkToAgent: Scalars["Boolean"];
  tenantID: Scalars["String"];
  text: Scalars["String"];
  type: MessageType;
};

export type Message = {
  __typename?: "Message";
  id?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  receiver: Scalars["String"];
  sender: Scalars["String"];
  status: MessageStatus;
  time?: Maybe<Scalars["String"]>;
  type: MessageType;
};

export type MessageList = PageInfoInterface & {
  __typename?: "MessageList";
  accountUsers?: Maybe<Array<Maybe<ChatListAccountUser>>>;
  contactUser?: Maybe<ChatListContactUser>;
  messages: Array<Maybe<ChatListMessage>>;
  pageInfo?: Maybe<PageInfo>;
  room?: Maybe<ChatListRoom>;
};

export type MessageListInput = {
  page?: InputMaybe<Page>;
  roomID: Scalars["String"];
  userID: Scalars["String"];
};

/** List of message status */
export enum MessageStatus {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Read = "READ",
  Received = "RECEIVED",
  Sending = "SENDING",
  Sent = "SENT",
  Unsend = "UNSEND",
}

export type MessageTemplateList = {
  __typename?: "MessageTemplateList";
  activeStatus?: Maybe<Scalars["Boolean"]>;
  channelID: Scalars["String"];
  cloudMediaID?: Maybe<Scalars["String"]>;
  headerType?: Maybe<Scalars["String"]>;
  isEditable?: Maybe<Scalars["Boolean"]>;
  isHeader?: Maybe<Scalars["Boolean"]>;
  templateBody: Scalars["String"];
  templateCategory: Scalars["String"];
  templateID: Scalars["String"];
  templateIDinDB: Scalars["String"];
  templateLang: Scalars["String"];
  templateName: Scalars["String"];
  templateStatus?: Maybe<Scalars["String"]>;
  templateType: Scalars["String"];
  templateVariableCount: Scalars["String"];
  tenantID: Scalars["String"];
};

/** List of supported message type */
export enum MessageType {
  Audio = "AUDIO",
  Button = "BUTTON",
  Document = "DOCUMENT",
  Image = "IMAGE",
  Interactive = "INTERACTIVE",
  Location = "LOCATION",
  Order = "ORDER",
  Product = "PRODUCT",
  QuickReply = "QUICK_REPLY",
  Sticker = "STICKER",
  System = "SYSTEM",
  Template = "TEMPLATE",
  Text = "TEXT",
  Unknown = "UNKNOWN",
  Unsupported = "UNSUPPORTED",
  Video = "VIDEO",
}

export type Mutation = {
  __typename?: "Mutation";
  addAppShortcut: Scalars["String"];
  addApplication: Scalars["String"];
  addChannel: Scalars["String"];
  addGateIdToClient: Array<Maybe<Scalars["String"]>>;
  addMessageTemplate: Scalars["String"];
  bootstrapTokopediaRooms: Scalars["String"];
  chatBotStatus: Scalars["String"];
  chatCancelBroadcastMessage?: Maybe<ChatCancelBroadcastMessageRes>;
  chatCreateBroadcastMessage?: Maybe<ChatCreateBroadcastMessageRes>;
  chatImportBroadcastContacts?: Maybe<ChatImportBroadcastContactsRes>;
  chatRebroadcastMessage?: Maybe<ChatRebroadcastMessageRes>;
  closeChat: Scalars["String"];
  createGreetings: Scalars["String"];
  createOperationalHours: Scalars["String"];
  createQuickReply: Scalars["String"];
  createUser: CreateUserOutput;
  createWebhook: Scalars["String"];
  deleteMenu: Scalars["String"];
  deleteMessageTemplate: Scalars["String"];
  deleteQuickReply: Scalars["String"];
  deleteShopeeChannel: Scalars["String"];
  deleteTenantLabel?: Maybe<DeleteTenantLabelOutput>;
  deleteTokopediaChannel: Scalars["String"];
  executeAppCommand: Scalars["String"];
  getApplication: ApplicationDetail;
  getShopeeCredentials: CredentialResp;
  getSignedURL: SignedUrlResp;
  installApplication: Scalars["String"];
  menuStatus: Scalars["String"];
  mlcbOperations: Scalars["String"];
  ping: PingResponse;
  queueChat: Scalars["String"];
  reconnectTokopedia: Scalars["String"];
  refreshRoomMessages: Scalars["Boolean"];
  registerTokopedia: Scalars["String"];
  requestMarketplaceIntegration: Scalars["Boolean"];
  sendMessage: ChatListMessage;
  sendWebhookMessage: Scalars["String"];
  syncConnectedChannels: Scalars["Boolean"];
  syncGatesByBrand: Array<Maybe<Scalars["String"]>>;
  syncTokopediaChannels: Scalars["String"];
  tokopediaMessageBootstrap: Scalars["Boolean"];
  transferChat: Scalars["String"];
  updateChannel: Scalars["String"];
  updateChannelStatus: Scalars["String"];
  updateMessageTemplateStatus: Scalars["String"];
  updateQuickReply: Scalars["String"];
  updateRoomRead: Scalars["String"];
  updateUser: Scalars["String"];
  uploadFile: UploadData;
  upsertLabel?: Maybe<UpsertLabelOutput>;
  upsertOnboarding: Onboarding;
  upsertRoomLabels?: Maybe<UpsertRoomLabelsOutput>;
  userStatus: Scalars["String"];
  webhookDelete: Scalars["String"];
  webhookRefresh: Scalars["String"];
  webhookStatus: Scalars["String"];
};

export type MutationAddAppShortcutArgs = {
  input: AddAppShortcutInput;
};

export type MutationAddApplicationArgs = {
  input: AddAppInput;
};

export type MutationAddChannelArgs = {
  input: ChannelData;
};

export type MutationAddGateIdToClientArgs = {
  input: AddGateIdInput;
};

export type MutationAddMessageTemplateArgs = {
  input: AddMessageTemplateInput;
};

export type MutationBootstrapTokopediaRoomsArgs = {
  channelID: Scalars["Int"];
};

export type MutationChatBotStatusArgs = {
  status: Scalars["Boolean"];
  tenantID: Scalars["String"];
};

export type MutationChatCancelBroadcastMessageArgs = {
  broadcastID: Scalars["String"];
  channelID: Scalars["Int"];
};

export type MutationChatCreateBroadcastMessageArgs = {
  input: ChatCreateBroadcastMessageInput;
};

export type MutationChatImportBroadcastContactsArgs = {
  input: ChatImportBroadcastContactsInput;
};

export type MutationChatRebroadcastMessageArgs = {
  input: ChatRebroadcastMessageInput;
};

export type MutationCloseChatArgs = {
  roomId: Scalars["String"];
};

export type MutationCreateGreetingsArgs = {
  input: GreetingsInput;
};

export type MutationCreateOperationalHoursArgs = {
  input: OperationalHoursInput;
};

export type MutationCreateQuickReplyArgs = {
  input: QuickReplyInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWebhookArgs = {
  input: Webhook;
};

export type MutationDeleteMenuArgs = {
  menuID: Scalars["String"];
};

export type MutationDeleteMessageTemplateArgs = {
  templateID: Scalars["String"];
};

export type MutationDeleteQuickReplyArgs = {
  id: Scalars["String"];
};

export type MutationDeleteShopeeChannelArgs = {
  channelID: Scalars["Int"];
};

export type MutationDeleteTenantLabelArgs = {
  labelID: Scalars["Int"];
};

export type MutationDeleteTokopediaChannelArgs = {
  channelID: Scalars["Int"];
};

export type MutationExecuteAppCommandArgs = {
  input: ExecuteCommandInput;
};

export type MutationGetApplicationArgs = {
  appId: Scalars["String"];
};

export type MutationGetShopeeCredentialsArgs = {
  input: ShopeeCredentialsInput;
};

export type MutationGetSignedUrlArgs = {
  input: UploadInput;
};

export type MutationInstallApplicationArgs = {
  appID: Scalars["String"];
  tenantID: Scalars["String"];
};

export type MutationMenuStatusArgs = {
  menuID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationMlcbOperationsArgs = {
  input: MlcbMenuInput;
};

export type MutationPingArgs = {
  input: PingMessage;
};

export type MutationQueueChatArgs = {
  roomId: Scalars["String"];
};

export type MutationReconnectTokopediaArgs = {
  channelID: Scalars["Int"];
};

export type MutationRefreshRoomMessagesArgs = {
  id: Scalars["ID"];
};

export type MutationRegisterTokopediaArgs = {
  input: TokopediaRegisterInput;
};

export type MutationRequestMarketplaceIntegrationArgs = {
  input: RequestMarketplaceIntegrationInput;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationSendWebhookMessageArgs = {
  input: WebhookMessage;
};

export type MutationSyncConnectedChannelsArgs = {
  input: ConnectedChannelInput;
};

export type MutationSyncGatesByBrandArgs = {
  brandID: Scalars["String"];
};

export type MutationSyncTokopediaChannelsArgs = {
  gateID?: InputMaybe<Scalars["String"]>;
  tenantID?: InputMaybe<Scalars["Int"]>;
};

export type MutationTokopediaMessageBootstrapArgs = {
  roomID: Scalars["String"];
};

export type MutationTransferChatArgs = {
  input: TransferChatInput;
};

export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};

export type MutationUpdateChannelStatusArgs = {
  channelID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpdateMessageTemplateStatusArgs = {
  status: Scalars["Boolean"];
  templateID: Scalars["String"];
};

export type MutationUpdateQuickReplyArgs = {
  id: Scalars["String"];
  input?: InputMaybe<QuickReplyInput>;
};

export type MutationUpdateRoomReadArgs = {
  custID: Scalars["String"];
  roomID: Scalars["String"];
};

export type MutationUpdateUserArgs = {
  input: UpdateDetail;
};

export type MutationUploadFileArgs = {
  input: SendMessageInput;
};

export type MutationUpsertLabelArgs = {
  input: UpsertLabelInput;
};

export type MutationUpsertOnboardingArgs = {
  input: UpsertOnboardingInput;
};

export type MutationUpsertRoomLabelsArgs = {
  input: UpsertRoomLabelsInput;
};

export type MutationUserStatusArgs = {
  active: Scalars["Boolean"];
  userId: Scalars["String"];
};

export type MutationWebhookDeleteArgs = {
  id: Scalars["String"];
  token: Scalars["String"];
};

export type MutationWebhookRefreshArgs = {
  id: Scalars["String"];
  token: Scalars["String"];
};

export type MutationWebhookStatusArgs = {
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
};

export type OffOperationalHourList = {
  __typename?: "OffOperationalHourList";
  channelID: Scalars["String"];
  id: Scalars["String"];
  isNewVendor: Scalars["Boolean"];
  operationalHourID: Scalars["String"];
  tenantID: Scalars["String"];
  text: Scalars["String"];
  text2: Scalars["String"];
};

export type Onboarding = {
  __typename?: "Onboarding";
  isFinished: Scalars["Boolean"];
  onboardingType: Scalars["String"];
  stepIndex: Scalars["Int"];
  tenantID: Scalars["String"];
};

export type OnboardingInput = {
  onboardingType: Scalars["String"];
};

export type OperationalHourList = {
  __typename?: "OperationalHourList";
  channelID: Scalars["String"];
  id: Scalars["String"];
  newBuyer: Scalars["Boolean"];
  offOperationalText: Scalars["String"];
  onOperationalText: Scalars["String"];
  tenantID: Scalars["String"];
  weekHours: Scalars["String"];
  weekendHours: Scalars["String"];
};

export type OperationalHours = {
  __typename?: "OperationalHours";
  from: Scalars["String"];
  id: Scalars["String"];
  open: Scalars["Boolean"];
  to: Scalars["String"];
  weekDay: Scalars["String"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  hasMore?: Maybe<Scalars["Boolean"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type PageInfoInterface = {
  pageInfo?: Maybe<PageInfo>;
};

export type PageSpec = {
  itemsPerPage?: InputMaybe<Scalars["Int"]>;
  pageNumber?: InputMaybe<Scalars["Int"]>;
};

export type PageSpecInfo = {
  __typename?: "PageSpecInfo";
  itemsPerPage?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  totalItems?: Maybe<Scalars["Int"]>;
};

export type PaginationInfo = {
  __typename?: "PaginationInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  totalItems: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type PaginationInput = {
  cursor?: InputMaybe<CursorInput>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type PingResponse = {
  __typename?: "PingResponse";
  Message: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  MLCBChatList?: Maybe<ChatList>;
  UnclaimedChatList?: Maybe<ChatList>;
  admin?: Maybe<Array<Maybe<Admin>>>;
  admins?: Maybe<Admins>;
  analyticsReport?: Maybe<AnalyticsReport>;
  brands: Array<Maybe<BrandGate>>;
  channel: Channel;
  channels: Channels;
  chatAdmins: Array<Maybe<Admin>>;
  chatBot: ChatBot;
  chatBroadcastSubscribersDetails?: Maybe<ChatBroadcastSubscribersDetailsRes>;
  chatBroadcasts?: Maybe<ChatBroadcastsRes>;
  chatDetailBroadcast?: Maybe<ChatDetailBroadcastRes>;
  chatList?: Maybe<ChatList>;
  chatSubscribers?: Maybe<ChatSubscribersRes>;
  claim: ChatListData;
  connectedSalesChannels: Array<Maybe<SalesChannel>>;
  dashboardConversationsByTenant: GenericReport;
  doesWABAChannelExist: Scalars["Boolean"];
  fetchMessageByID?: Maybe<MessageList>;
  gates: Array<Maybe<Gate>>;
  genericDashboardReport: GenericDashboardReport;
  genericReport: GenericReport;
  getAgentList?: Maybe<UserData>;
  getAppShortcut: Array<Maybe<AppShortcut>>;
  getApplications: Array<Maybe<Application>>;
  getChannel: Array<Maybe<ChannelList>>;
  getChannelsByTenant: Array<Maybe<ChannelList>>;
  getChannelsByUser: Array<Maybe<ChannelList>>;
  getDistrictOptions: GetDistrictOptions;
  getGreetingsByChannel: Greetings;
  getInstalledApplications: Array<Maybe<Application>>;
  getLabelsByTenant: LabelsByTenant;
  getMenu: Array<Maybe<Menu>>;
  getMenuList: Array<Maybe<MenuList>>;
  getMessageTemplateCaterogies: Array<Maybe<Scalars["String"]>>;
  getMessageTemplates?: Maybe<Array<Maybe<MessageTemplateList>>>;
  getOffOperationalHoursByChannel: Array<Maybe<OffOperationalHourList>>;
  getOperationalHoursByChannel: Array<Maybe<GetOperationalHours>>;
  getOperationalHoursByTenant: Array<Maybe<OperationalHourList>>;
  getQuickReply: Array<Maybe<QuickReplyList>>;
  getQuickReplyByID: QuickReplyList;
  getResumableUploadHandle: ResumbaleUploadHandleRes;
  getRoomCount?: Maybe<Array<RoomCountResp>>;
  getRoomsByLabel: RoomsByLabel;
  getShopeeCredentials: CredentialResp;
  getShopeeOrder: ShopeeOrderResp;
  getShopeeProduct: ShopeeProductResp;
  getTenantPlanDetail?: Maybe<TenantPlanDetail>;
  getUnreadCountByAdmin: Scalars["Int"];
  getUnreadCountByTenant: Scalars["Int"];
  getUploadUrl: UploadData;
  getUser: Admin;
  isChatExist?: Maybe<ChatExistResp>;
  isShopeeConnected: Scalars["Boolean"];
  isTokopediaConnected: Scalars["Boolean"];
  linkChannel: Scalars["String"];
  loginUrl?: Maybe<LoginResp>;
  messageList?: Maybe<MessageList>;
  onboarding: Onboarding;
  removeChannelLink: Scalars["String"];
  room: Room;
  rooms: Rooms;
  searchMessage?: Maybe<SearchMessage>;
  searchRooms: SearchRooms;
  singleAdmin: Admin;
  syncWaMessageTemplates?: Maybe<SyncWaMessageTemplateListRes>;
  tenant: Tenant;
  token?: Maybe<Token>;
  tokopediaInvoice: TokopediaInvoice;
  tokopediaProduct: TokopediaProduct;
  unclaimed: Count;
  unclaimedChatCount: Count;
  updateRole: Scalars["String"];
  version: VersionResponse;
  webhookDetail: WebhookDetail;
  webhookList: Array<Maybe<WebhookData>>;
};

export type QueryMlcbChatListArgs = {
  input: ChatListInput;
};

export type QueryUnclaimedChatListArgs = {
  input: ChatListInput;
};

export type QueryAdminArgs = {
  role?: InputMaybe<Scalars["String"]>;
  tenantID?: InputMaybe<Scalars["String"]>;
};

export type QueryAdminsArgs = {
  itemPerPage?: Scalars["Int"];
  pageNumber?: Scalars["Int"];
};

export type QueryAnalyticsReportArgs = {
  input: GenericReportInput;
};

export type QueryBrandsArgs = {
  input: BrandInput;
};

export type QueryChannelArgs = {
  filter?: InputMaybe<ChannelFilter>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryChannelsArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<ChannelsFilter>;
  first?: Scalars["Int"];
  sort?: ChannelsSort;
};

export type QueryChatAdminsArgs = {
  filter?: InputMaybe<ChatAdminsFilterInput>;
  sortBy?: InputMaybe<Scalars["String"]>;
  sortType?: InputMaybe<Scalars["String"]>;
};

export type QueryChatBotArgs = {
  tenantId: Scalars["String"];
};

export type QueryChatBroadcastSubscribersDetailsArgs = {
  params: ChatBroadcastSubscribersDetailsInput;
};

export type QueryChatBroadcastsArgs = {
  params: ChatBroadcastsInput;
};

export type QueryChatDetailBroadcastArgs = {
  params: ChatDetailBroadcastInput;
};

export type QueryChatListArgs = {
  input: ChatListInput;
};

export type QueryChatSubscribersArgs = {
  params: ChatSubscribersInput;
};

export type QueryClaimArgs = {
  roomID?: InputMaybe<Scalars["Int"]>;
  userId: Scalars["String"];
};

export type QueryConnectedSalesChannelsArgs = {
  input: ConnectedSalesChannelsInput;
};

export type QueryDashboardConversationsByTenantArgs = {
  tenantId: Scalars["String"];
  userID?: InputMaybe<Scalars["String"]>;
};

export type QueryDoesWabaChannelExistArgs = {
  tenantId: Scalars["String"];
};

export type QueryFetchMessageByIdArgs = {
  input: FetchMessageInput;
};

export type QueryGatesArgs = {
  input: GateInput;
};

export type QueryGenericDashboardReportArgs = {
  input: GenericDashboardReportInput;
};

export type QueryGenericReportArgs = {
  input: GenericReportInput;
};

export type QueryGetAgentListArgs = {
  channelID: Scalars["String"];
  tenant: Scalars["String"];
};

export type QueryGetAppShortcutArgs = {
  appID: Scalars["String"];
};

export type QueryGetChannelArgs = {
  tenant: Scalars["String"];
};

export type QueryGetChannelsByTenantArgs = {
  tenantId: Scalars["String"];
};

export type QueryGetChannelsByUserArgs = {
  tenantId: Scalars["String"];
  userID?: InputMaybe<Scalars["String"]>;
};

export type QueryGetDistrictOptionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QueryGetGreetingsByChannelArgs = {
  channelID: Scalars["String"];
};

export type QueryGetInstalledApplicationsArgs = {
  tenantId: Scalars["String"];
};

export type QueryGetLabelsByTenantArgs = {
  input: GetLabelsByTenantInput;
};

export type QueryGetMenuArgs = {
  channelId: Scalars["String"];
  parentId: Scalars["String"];
  tenantId: Scalars["String"];
};

export type QueryGetMenuListArgs = {
  tenantId: Scalars["String"];
};

export type QueryGetMessageTemplatesArgs = {
  category?: InputMaybe<Scalars["String"]>;
  channelID?: InputMaybe<Scalars["String"]>;
  excludeCat?: InputMaybe<TemplateCategory>;
  status?: InputMaybe<Scalars["Int"]>;
  tenantID: Scalars["String"];
};

export type QueryGetOffOperationalHoursByChannelArgs = {
  channelID: Scalars["String"];
};

export type QueryGetOperationalHoursByChannelArgs = {
  channelID: Scalars["String"];
};

export type QueryGetOperationalHoursByTenantArgs = {
  tenantId: Scalars["String"];
};

export type QueryGetQuickReplyArgs = {
  status?: InputMaybe<Scalars["Int"]>;
  tenantID: Scalars["String"];
};

export type QueryGetQuickReplyByIdArgs = {
  id: Scalars["String"];
};

export type QueryGetResumableUploadHandleArgs = {
  input: ResumbaleUploadHandleInput;
};

export type QueryGetRoomCountArgs = {
  filter?: InputMaybe<ChatFilter>;
};

export type QueryGetRoomsByLabelArgs = {
  input: GetRoomsByLabelInput;
};

export type QueryGetShopeeCredentialsArgs = {
  input: ShopeeCredentialsInput;
};

export type QueryGetShopeeOrderArgs = {
  input: ShopeeOrderInput;
};

export type QueryGetShopeeProductArgs = {
  input: ShopeeProductInput;
};

export type QueryGetUnreadCountByAdminArgs = {
  userID: Scalars["String"];
};

export type QueryGetUnreadCountByTenantArgs = {
  tenantID: Scalars["String"];
};

export type QueryGetUploadUrlArgs = {
  input?: InputMaybe<UploadFile>;
};

export type QueryGetUserArgs = {
  id: Scalars["String"];
};

export type QueryIsChatExistArgs = {
  tenantId: Scalars["String"];
};

export type QueryIsShopeeConnectedArgs = {
  input: IsShopeeConnectedInput;
};

export type QueryIsTokopediaConnectedArgs = {
  input: IsTokopediaConnectedInput;
};

export type QueryLinkChannelArgs = {
  input?: InputMaybe<LinkChannel>;
};

export type QueryLoginUrlArgs = {
  input: LoginUrlInput;
};

export type QueryMessageListArgs = {
  input: MessageListInput;
};

export type QueryOnboardingArgs = {
  input: OnboardingInput;
};

export type QueryRemoveChannelLinkArgs = {
  input?: InputMaybe<LinkChannel>;
};

export type QueryRoomArgs = {
  id: Scalars["ID"];
};

export type QueryRoomsArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<RoomsFilter>;
  first?: Scalars["Int"];
};

export type QuerySearchMessageArgs = {
  input: SearchMessageInput;
};

export type QuerySearchRoomsArgs = {
  input: SearchRoomsFilter;
  page?: InputMaybe<SearchRoomsPagination>;
};

export type QuerySingleAdminArgs = {
  id: Scalars["ID"];
};

export type QuerySyncWaMessageTemplatesArgs = {
  channelID: Scalars["String"];
};

export type QueryTokenArgs = {
  input: TokenRequest;
};

export type QueryTokopediaInvoiceArgs = {
  input: TokopediaInvoiceInput;
};

export type QueryTokopediaProductArgs = {
  input: TokopediaProductInput;
};

export type QueryUnclaimedChatCountArgs = {
  userID: Scalars["String"];
};

export type QueryUpdateRoleArgs = {
  input?: InputMaybe<UpdateRole>;
};

export type QueryWebhookDetailArgs = {
  webhookID: Scalars["String"];
};

export type QueryWebhookListArgs = {
  tenantId: Scalars["String"];
};

export type QuickReplyList = {
  __typename?: "QuickReplyList";
  cloudMediaID?: Maybe<Scalars["String"]>;
  content: Scalars["String"];
  id: Scalars["Int"];
  signedUrl?: Maybe<Scalars["String"]>;
  status: Scalars["Boolean"];
  tenantID: Scalars["Int"];
  title: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type ReceiveMessageSub = {
  token: Scalars["String"];
  userId: Scalars["String"];
};

export type RequestMarketplaceIntegrationInput = {
  brandId: Scalars["String"];
  channel: Scalars["String"];
  credentials?: InputMaybe<Scalars["Map"]>;
  tenantId: Scalars["String"];
};

export type ResumbaleUploadHandleInput = {
  file: Scalars["Upload"];
};

export type ResumbaleUploadHandleRes = {
  __typename?: "ResumbaleUploadHandleRes";
  handleId?: Maybe<Scalars["String"]>;
};

export type Room = {
  __typename?: "Room";
  activeAccountUserId: Scalars["String"];
  channel?: Maybe<Channel>;
  channelRoomId: Scalars["String"];
  claimedAt?: Maybe<Scalars["Int"]>;
  contact?: Maybe<Contact>;
  contactUserId: Scalars["Int"];
  createdAt: Scalars["Int"];
  currentChannelId?: Maybe<Scalars["Int"]>;
  currentSessionId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  labels?: Maybe<Array<Maybe<ChatLabel>>>;
  lastClosedAt?: Maybe<Scalars["Int"]>;
  roomName?: Maybe<Scalars["String"]>;
  status: RoomStatus;
  tenantId: Scalars["Int"];
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type RoomContactUser = {
  __typename?: "RoomContactUser";
  id: Scalars["Int"];
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type RoomCountResp = {
  __typename?: "RoomCountResp";
  roomCount?: Maybe<Scalars["Int"]>;
  roomGroupIdentifier?: Maybe<Scalars["String"]>;
};

export enum RoomStatus {
  Claimed = "CLAIMED",
  Closed = "CLOSED",
  Mlcb = "MLCB",
  Open = "OPEN",
  Transfer = "TRANSFER",
  Unclaimed = "UNCLAIMED",
}

export type Rooms = {
  __typename?: "Rooms";
  items: Array<Maybe<Room>>;
  pageInfo: PaginationInfo;
};

export type RoomsByLabel = {
  __typename?: "RoomsByLabel";
  cursorAfter?: Maybe<Scalars["Int"]>;
  cursorBefore?: Maybe<Scalars["Int"]>;
  rooms?: Maybe<Array<Maybe<ChatLabelRoom>>>;
};

export type RoomsFilter = {
  brandIds?: InputMaybe<Array<Scalars["ID"]>>;
  channelIds?: InputMaybe<Array<Scalars["ID"]>>;
  channelTypes?: InputMaybe<Array<ChannelType>>;
  query?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<RoomStatus>>;
  tenantIds?: InputMaybe<Array<Scalars["ID"]>>;
  userIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SalesChannel = {
  __typename?: "SalesChannel";
  channelCode: ChannelCode;
  channelName: Scalars["String"];
  credentials?: Maybe<Scalars["Map"]>;
};

export type SearchMessage = PageInfoInterface & {
  __typename?: "SearchMessage";
  data: Array<Maybe<SearchMessageData>>;
  pageInfo?: Maybe<PageInfo>;
  /** @deprecated being replaced by Query.SearchRooms */
  searchUser?: Maybe<Array<Maybe<SearchUsereData>>>;
};

export type SearchMessageData = {
  __typename?: "SearchMessageData";
  accountUsers?: Maybe<Array<Maybe<ChatListAccountUser>>>;
  contactUser?: Maybe<ChatListContactUser>;
  messages?: Maybe<Array<Maybe<SearchMessageList>>>;
  room?: Maybe<ChatListRoom>;
  unreadMsgCount?: Maybe<Scalars["Int"]>;
};

export type SearchMessageInput = {
  filter?: InputMaybe<ChatFilter>;
  keyword: Scalars["String"];
  page?: InputMaybe<Page>;
  userID: Scalars["String"];
};

export type SearchMessageList = {
  __typename?: "SearchMessageList";
  id: Scalars["String"];
  interactive?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  receiver: Scalars["String"];
  sender: Scalars["String"];
  status: MessageStatus;
  time: Scalars["String"];
  type: MessageType;
};

export type SearchRooms = {
  __typename?: "SearchRooms";
  data: Array<Maybe<SearchRoomsData>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SearchRoomsData = {
  __typename?: "SearchRoomsData";
  room?: Maybe<Room>;
  thumbnailMessage?: Maybe<ThumbnailMessage>;
  unreadMessages?: Maybe<Scalars["Int"]>;
};

export type SearchRoomsFilter = {
  channelIDs?: InputMaybe<Array<Scalars["Int"]>>;
  isUnread?: InputMaybe<Scalars["Boolean"]>;
  labelIDs?: InputMaybe<Array<Scalars["Int"]>>;
  page?: InputMaybe<Page>;
  query?: InputMaybe<Scalars["String"]>;
  roomStatus?: InputMaybe<Array<RoomStatus>>;
};

export type SearchRoomsPagination = {
  PageNo?: InputMaybe<Scalars["Int"]>;
  Size?: InputMaybe<Scalars["Int"]>;
};

export type SearchUserList = {
  __typename?: "SearchUserList";
  id: Scalars["String"];
  message: Scalars["String"];
  receiver: Scalars["String"];
  sender: Scalars["String"];
  status: MessageStatus;
  time: Scalars["String"];
  type: MessageType;
};

export type SearchUsereData = {
  __typename?: "SearchUsereData";
  accountUsers?: Maybe<Array<Maybe<ChatListAccountUser>>>;
  contactUser?: Maybe<ChatListContactUser>;
  messages?: Maybe<Array<Maybe<SearchUserList>>>;
  room?: Maybe<ChatListRoom>;
  unreadMsgCount?: Maybe<Scalars["Int"]>;
};

export type SendMessageInput = {
  actionID?: InputMaybe<Scalars["String"]>;
  interactive?: InputMaybe<InteractiveMessageInput>;
  mediaFileName?: InputMaybe<Scalars["String"]>;
  mediaSize?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  messageID?: InputMaybe<Scalars["String"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  receiver: Scalars["String"];
  roomID: Scalars["String"];
  sender: Scalars["String"];
  sentByAccUser: Scalars["Boolean"];
  status: MessageStatus;
  templateID?: InputMaybe<Scalars["String"]>;
  time: Scalars["String"];
  type: MessageType;
};

export type ShopeeCredentialsInput = {
  brandId?: InputMaybe<Scalars["String"]>;
  callbackFailure: Scalars["String"];
  callbackSuccess: Scalars["String"];
  clientKey?: InputMaybe<Scalars["String"]>;
  gateId?: InputMaybe<Scalars["String"]>;
};

export type ShopeeOrderImageInfo = {
  __typename?: "ShopeeOrderImageInfo";
  imageUrl: Scalars["String"];
};

export type ShopeeOrderInput = {
  brandId: Scalars["String"];
  orderId: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
};

export type ShopeeOrderItem = {
  __typename?: "ShopeeOrderItem";
  id: Scalars["Int"];
  imageInfo?: Maybe<ShopeeOrderImageInfo>;
};

export type ShopeeOrderResp = {
  __typename?: "ShopeeOrderResp";
  checkoutShippingCarrier: Scalars["Boolean"];
  cod: Scalars["Boolean"];
  createTime: Scalars["Int"];
  currency: Scalars["String"];
  daysToShip: Scalars["Int"];
  items: Array<Maybe<ShopeeOrderItem>>;
  messageToSeller: Scalars["String"];
  orderChargeableWeightGram: Scalars["String"];
  orderSn: Scalars["String"];
  orderStatus: Scalars["String"];
  orderUrl: Scalars["String"];
  region: Scalars["String"];
  reverseShippingFee: Scalars["Int"];
  shipByDate: Scalars["Int"];
  totalAmount: Scalars["Float"];
  updateTime: Scalars["Int"];
};

export type ShopeeProductInput = {
  brandId: Scalars["String"];
  productId: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
};

export type ShopeeProductPrice = {
  __typename?: "ShopeeProductPrice";
  currency: Scalars["String"];
  currentPrice: Scalars["Int"];
  originalPrice: Scalars["Int"];
};

export type ShopeeProductPriceInfo = {
  __typename?: "ShopeeProductPriceInfo";
  max: ShopeeProductPrice;
  min: ShopeeProductPrice;
};

export type ShopeeProductResp = {
  __typename?: "ShopeeProductResp";
  brandName: Scalars["String"];
  /** @deprecated not supported within shopee product variants */
  currentPrice: Scalars["Int"];
  customerProductUrl: Scalars["String"];
  imageUrl: Scalars["String"];
  itemId: Scalars["Int"];
  itemName: Scalars["String"];
  itemSku: Scalars["String"];
  itemStatus: Scalars["String"];
  /** @deprecated not supported within shopee product variants */
  originalPrice: Scalars["Int"];
  price: ShopeeProductPriceInfo;
  /** @deprecated moved to new field */
  priceCurrency: Scalars["String"];
  productUrl: Scalars["String"];
  weight: Scalars["String"];
};

export enum ShortcutType {
  OrderMessageBubble = "ORDER_MESSAGE_BUBBLE",
  ProductMessageBubble = "PRODUCT_MESSAGE_BUBBLE",
  TextComposer = "TEXT_COMPOSER",
}

export type SignedUrlResp = {
  __typename?: "SignedURLResp";
  cloudMediaID: Scalars["String"];
  url: Scalars["String"];
};

export type StoreFrontPageInfo = {
  __typename?: "StoreFrontPageInfo";
  itemPerPage: Scalars["Int"];
  pageNumber: Scalars["Int"];
  totalItems: Scalars["Int"];
};

export type Subscriber = {
  __typename?: "Subscriber";
  channelContactID?: Maybe<Scalars["String"]>;
  channelID?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isOptIn?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  adminDeleted: AdminDeleted;
  chatListUpdate: Events;
  chatStatus: Count;
  messageStatus: ChatMessageStatus;
  mlcbChat: ChatMessage;
  receiveMessage: ChatMessage;
  transferChatSub?: Maybe<ChatListData>;
  unclaimedChat: ChatMessage;
};

export type SubscriptionAdminDeletedArgs = {
  input: AdminDeletedInput;
};

export type SubscriptionChatListUpdateArgs = {
  userID: Scalars["String"];
};

export type SubscriptionMessageStatusArgs = {
  input: Scalars["String"];
};

export type SubscriptionReceiveMessageArgs = {
  input: ReceiveMessageSub;
};

export type SubscriptionTransferChatSubArgs = {
  input: Scalars["String"];
};

export type SyncWaMessageTemplateListRes = {
  __typename?: "SyncWaMessageTemplateListRes";
  success?: Maybe<Scalars["Boolean"]>;
};

export enum TemplateCategory {
  AccountUpdate = "ACCOUNT_UPDATE",
  AlertUpdate = "ALERT_UPDATE",
  All = "ALL",
  AppointmentUpdate = "APPOINTMENT_UPDATE",
  Authentication = "AUTHENTICATION",
  AutoReply = "AUTO_REPLY",
  Greetings = "GREETINGS",
  IssueResolution = "ISSUE_RESOLUTION",
  Marketing = "MARKETING",
  Otp = "OTP",
  PaymentUpdate = "PAYMENT_UPDATE",
  PersonalFinanceUpdate = "PERSONAL_FINANCE_UPDATE",
  QuickReply = "QUICK_REPLY",
  ReservationUpdate = "RESERVATION_UPDATE",
  ShippingUpdate = "SHIPPING_UPDATE",
  TicketUpdate = "TICKET_UPDATE",
  Transactional = "TRANSACTIONAL",
  TransportationUpdate = "TRANSPORTATION_UPDATE",
  Utility = "UTILITY",
}

export type TemplateComponent = {
  __typename?: "TemplateComponent";
  parameters?: Maybe<Array<Maybe<TemplateComponentParameter>>>;
  subType?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TemplateComponentParameter = {
  __typename?: "TemplateComponentParameter";
  currency?: Maybe<Currency>;
  dateTime?: Maybe<DateTime>;
  document?: Maybe<Media>;
  image?: Maybe<Media>;
  text?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  video?: Maybe<Media>;
};

export type Tenant = {
  __typename?: "Tenant";
  clientType: Scalars["String"];
  createdAt: Scalars["Int"];
  id: Scalars["Int"];
  menu: Scalars["Boolean"];
  name: Scalars["String"];
  updatedAt?: Maybe<Scalars["Int"]>;
};

export type TenantPlanDetail = {
  __typename?: "TenantPlanDetail";
  planName: Scalars["String"];
  planType: Scalars["String"];
};

export type ThumbnailMessage = {
  __typename?: "ThumbnailMessage";
  fileName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  mediaUrl?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  mimeType?: Maybe<Scalars["String"]>;
  receiver?: Maybe<Scalars["String"]>;
  sender?: Maybe<Scalars["String"]>;
  sentAt?: Maybe<Scalars["String"]>;
  status: MessageStatus;
  type: MessageType;
};

export type Token = {
  __typename?: "Token";
  TenantID: Scalars["String"];
  accessToken: Scalars["String"];
  clientType: Scalars["String"];
  expiresIn: Scalars["Int"];
  fullName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  role: Scalars["String"];
  scope: Scalars["String"];
  tokenType: Scalars["String"];
  userName: Scalars["String"];
};

export type TokenRequest = {
  cc: Scalars["String"];
  clientId: Scalars["String"];
  code: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type TokopediaInvoice = {
  __typename?: "TokopediaInvoice";
  invoiceNumber: Scalars["String"];
  invoiceUrl: Scalars["String"];
  orderId: Scalars["Int"];
  orderInfo: TokopediaInvoiceOrderInfo;
  orderStatus: Scalars["String"];
  orderStatusCode: Scalars["Int"];
  totalAmount: Scalars["Int"];
};

export type TokopediaInvoiceInput = {
  brandId: Scalars["String"];
  invoiceUrl: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
};

export type TokopediaInvoiceOrderInfo = {
  __typename?: "TokopediaInvoiceOrderInfo";
  orderDetail: Array<TokopediaInvoiceOrderInfoOrderDetail>;
};

export type TokopediaInvoiceOrderInfoOrderDetail = {
  __typename?: "TokopediaInvoiceOrderInfoOrderDetail";
  currencyId: Scalars["Int"];
  currencyRate: Scalars["Int"];
  insurancePrice: Scalars["Int"];
  isFreeReturns: Scalars["Boolean"];
  minOrder: Scalars["Int"];
  normalPrice: Scalars["Int"];
  productId: Scalars["Int"];
  productName: Scalars["String"];
  productPicture: Scalars["String"];
  productPrice: Scalars["Int"];
  quantity: Scalars["Int"];
  quantityDeliver: Scalars["Int"];
  quantityReject: Scalars["Int"];
  sku: Scalars["String"];
  snapshotUrl: Scalars["String"];
  subtotalPrice: Scalars["Int"];
  totalWeight: Scalars["Float"];
  weight: Scalars["Float"];
};

export type TokopediaProduct = {
  __typename?: "TokopediaProduct";
  basic: TokopediaProductBasic;
  other: TokopediaProductOther;
  pictures: Array<TokopediaProductPicture>;
  price: TokopediaProductPrice;
  stock: TokopediaProductStock;
};

export type TokopediaProductBasic = {
  __typename?: "TokopediaProductBasic";
  name: Scalars["String"];
};

export type TokopediaProductInput = {
  brandId: Scalars["String"];
  productUrl: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
};

export type TokopediaProductOther = {
  __typename?: "TokopediaProductOther";
  mobileURL: Scalars["String"];
  sku: Scalars["String"];
  url: Scalars["String"];
};

export type TokopediaProductPicture = {
  __typename?: "TokopediaProductPicture";
  originalUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
};

export type TokopediaProductPrice = {
  __typename?: "TokopediaProductPrice";
  currency: Scalars["Int"];
  idr: Scalars["Int"];
  value: Scalars["Int"];
};

export type TokopediaProductStock = {
  __typename?: "TokopediaProductStock";
  useStock: Scalars["Boolean"];
  value: Scalars["Int"];
};

export type TokopediaRegisterInput = {
  channelName?: InputMaybe<Scalars["String"]>;
  clientID: Scalars["String"];
  clientSecret: Scalars["String"];
  fsID: Scalars["Int"];
  shopID: Scalars["Int"];
};

export type TransferChatInput = {
  roomID: Scalars["String"];
  userID: Scalars["String"];
};

export type UpdateDetail = {
  email: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
  tenant: Scalars["String"];
};

export type UploadData = {
  __typename?: "UploadData";
  mediaName: Scalars["String"];
  messageID: Scalars["String"];
  url: Scalars["String"];
};

export type UploadInput = {
  mediaFileName: Scalars["String"];
  mimeType: Scalars["String"];
};

export type UpsertInteractiveMessageInput = {
  brandId: Scalars["String"];
  id?: InputMaybe<Scalars["Int"]>;
  sellerId: Scalars["String"];
  status?: InputMaybe<Scalars["String"]>;
  template?: InputMaybe<InteractiveMessageInput>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UpsertLabelInput = {
  color: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type UpsertLabelOutput = {
  __typename?: "UpsertLabelOutput";
  label?: Maybe<ChatLabel>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type UpsertOnboardingInput = {
  isFinished?: Scalars["Boolean"];
  onboardingType: Scalars["String"];
  stepIndex?: Scalars["Int"];
};

export type UpsertRoomLabelsInput = {
  labelIDs?: InputMaybe<Array<Scalars["Int"]>>;
  roomID: Scalars["Int"];
};

export type UpsertRoomLabelsOutput = {
  __typename?: "UpsertRoomLabelsOutput";
  success?: Maybe<Scalars["Boolean"]>;
};

export type UserData = {
  __typename?: "UserData";
  StoreAddress?: Maybe<Scalars["String"]>;
  agentId?: Maybe<Array<Scalars["String"]>>;
  businessName?: Maybe<Scalars["String"]>;
  channelId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Boolean"]>;
  storeDescription?: Maybe<Scalars["String"]>;
  storeImage?: Maybe<Scalars["String"]>;
  storeName?: Maybe<Scalars["String"]>;
  storeWebsite?: Maybe<Scalars["String"]>;
  tenantID?: Maybe<Scalars["String"]>;
};

export type VersionResponse = {
  __typename?: "VersionResponse";
  version: Scalars["String"];
};

export type WebhookData = {
  __typename?: "WebhookData";
  ChannelID: Scalars["String"];
  Enabled: Scalars["Boolean"];
  Name: Scalars["String"];
  SupervisorID: Scalars["String"];
  TemplateID: Scalars["String"];
  TenantID: Scalars["String"];
  id: Scalars["String"];
};

export type WebhookDetail = {
  __typename?: "WebhookDetail";
  ChannelID: Scalars["String"];
  Curl: Scalars["String"];
  Enabled: Scalars["Boolean"];
  Link: Scalars["String"];
  Name: Scalars["String"];
  SupervisorID: Scalars["String"];
  TemplateID: Scalars["String"];
  TenantID: Scalars["String"];
  Token: Scalars["String"];
  TokenExpiry: Scalars["String"];
  TokenUpdatedAt: Scalars["String"];
  id: Scalars["String"];
};

export enum WhatsappBusinessCategory {
  Apparel = "APPAREL",
  Auto = "AUTO",
  Beauty = "BEAUTY",
  Edu = "EDU",
  Entertain = "ENTERTAIN",
  EventPlan = "EVENT_PLAN",
  Finance = "FINANCE",
  Govt = "GOVT",
  Grocery = "GROCERY",
  Health = "HEALTH",
  Hotel = "HOTEL",
  Nonprofit = "NONPROFIT",
  Other = "OTHER",
  ProfServices = "PROF_SERVICES",
  Restaurant = "RESTAURANT",
  Retail = "RETAIL",
  Travel = "TRAVEL",
}

export type AddMessageTemplateInput = {
  channelID?: InputMaybe<Scalars["Int"]>;
  cloudMediaID?: InputMaybe<Scalars["String"]>;
  footer?: InputMaybe<Scalars["String"]>;
  headerFormat?: InputMaybe<MessageType>;
  headerMediaHandler?: InputMaybe<Scalars["String"]>;
  headerText?: InputMaybe<Scalars["String"]>;
  remoteTemplateID?: InputMaybe<Scalars["Int"]>;
  templateBody?: InputMaybe<Scalars["String"]>;
  templateButtonText?: InputMaybe<Scalars["String"]>;
  templateCategory?: InputMaybe<Scalars["String"]>;
  templateLang?: InputMaybe<Scalars["String"]>;
  templateName?: InputMaybe<Scalars["String"]>;
  templateType?: InputMaybe<Scalars["String"]>;
  templateVariableCount?: InputMaybe<Scalars["Int"]>;
  tenantID?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type ChatCounts = {
  __typename?: "chatCounts";
  convCount: Scalars["Int"];
  date: Scalars["String"];
};

export type GreetingsInput = {
  channel: Scalars["String"];
  cloudMediaID?: InputMaybe<Scalars["String"]>;
  greetings: Scalars["String"];
  newCustomer: Scalars["Boolean"];
  type: MessageType;
};

export type OffOperationalHoursInput = {
  channel: Scalars["String"];
  isNewVendor: Scalars["Boolean"];
  messageTemplateID?: InputMaybe<Scalars["String"]>;
  tenant: Scalars["String"];
  text: Scalars["String"];
  text2: Scalars["String"];
};

export type OperationalHoursInput = {
  channel: Scalars["String"];
  messageTemplateID?: InputMaybe<Scalars["String"]>;
  newBuyer: Scalars["Boolean"];
  offOperationalText: Scalars["String"];
  onOperationalText: Scalars["String"];
  tenant: Scalars["String"];
  weekHours: Scalars["String"];
  weekendHours: Scalars["String"];
};

export type Page = {
  PageNo?: InputMaybe<Scalars["Int"]>;
  Size?: InputMaybe<Scalars["Int"]>;
};

export type PingMessage = {
  text: Scalars["String"];
};

export type QuickReplyInput = {
  cloudMediaId?: InputMaybe<Scalars["String"]>;
  content: Scalars["String"];
  status: Scalars["Boolean"];
  tenantID: Scalars["String"];
  title: Scalars["String"];
  type: MessageType;
};

export type ReportList = {
  __typename?: "reportList";
  agentID?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  convCount?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["String"]>;
};

export type ReportListDailyCounts = {
  __typename?: "reportListDailyCounts";
  agentID?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  chatCounts?: Maybe<Array<Maybe<ChatCounts>>>;
};

export type UpdateChannelInput = {
  businessCategory?: InputMaybe<WhatsappBusinessCategory>;
  channelID: Scalars["String"];
  storeAddress?: InputMaybe<Scalars["String"]>;
  storeDescription?: InputMaybe<Scalars["String"]>;
  storeImage?: InputMaybe<Scalars["String"]>;
  storeName?: InputMaybe<Scalars["String"]>;
  storeWebsite?: InputMaybe<Scalars["String"]>;
};

export type UpdateRole = {
  role: Scalars["String"];
  userID: Scalars["String"];
};

export type UploadFile = {
  contentType: Scalars["String"];
  fileName: Scalars["String"];
};

export type Webhook = {
  channelID: Scalars["String"];
  name: Scalars["String"];
  templateID: Scalars["String"];
  tenantID: Scalars["String"];
};

export type WebhookMessage = {
  templateBody: Scalars["String"];
  token: Scalars["String"];
  webhookID: Scalars["String"];
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars["String"];
  phone: Scalars["String"];
  role: Scalars["String"];
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  tenantid: Scalars["String"];
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: { __typename?: "CreateUserOutput"; userID: string };
};

export type GetAdminsQueryVariables = Exact<{
  pageNumber: Scalars["Int"];
  itemPerPage: Scalars["Int"];
}>;

export type GetAdminsQuery = {
  __typename?: "Query";
  admins?: Maybe<{
    __typename?: "Admins";
    items: Array<
      Maybe<{
        __typename?: "AdminProfile";
        id: string;
        accountID: string;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email?: Maybe<string>;
        phone?: Maybe<string>;
        passwordIsSet: boolean;
        lastLogin?: Maybe<number>;
        role?: Maybe<string>;
        phoneVerifiedAt?: Maybe<number>;
        chatAdmin: {
          __typename?: "Admin";
          id: number;
          accountID: string;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          passwordIsSet?: Maybe<boolean>;
          userID: string;
          type: string;
          role: AdminRole;
          active: boolean;
        };
      }>
    >;
    pageInfo: {
      __typename?: "PaginationInfo";
      totalPages: number;
      totalItems: number;
      page: number;
      perPage: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
};

export type GetSingleAdminQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetSingleAdminQuery = {
  __typename?: "Query";
  singleAdmin: {
    __typename?: "Admin";
    id: number;
    accountID: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    passwordIsSet?: Maybe<boolean>;
    userID: string;
    type: string;
    role: AdminRole;
    active: boolean;
  };
};

export type ChatAdminsQueryVariables = Exact<{
  sortBy?: Maybe<Scalars["String"]>;
  sortType?: Maybe<Scalars["String"]>;
  filter?: Maybe<ChatAdminsFilterInput>;
}>;

export type ChatAdminsQuery = {
  __typename?: "Query";
  chatAdmins: Array<
    Maybe<{
      __typename?: "Admin";
      id: number;
      accountID: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      passwordIsSet?: Maybe<boolean>;
      userID: string;
      type: string;
      role: AdminRole;
      active: boolean;
    }>
  >;
};

export type AdminDeletedSubscriptionVariables = Exact<{
  input: AdminDeletedInput;
}>;

export type AdminDeletedSubscription = {
  __typename?: "Subscription";
  adminDeleted: { __typename?: "AdminDeleted"; shouldLogout: boolean };
};

export type AnalyticsReportQueryVariables = Exact<{
  input: GenericReportInput;
}>;

export type AnalyticsReportQuery = {
  __typename?: "Query";
  analyticsReport?: Maybe<{
    __typename?: "AnalyticsReport";
    everyAgentDailyNewConv?: Maybe<
      Array<
        Maybe<{
          __typename?: "reportListDailyCounts";
          agentID?: Maybe<string>;
          agentName?: Maybe<string>;
          chatCounts?: Maybe<
            Array<
              Maybe<{
                __typename?: "chatCounts";
                date: string;
                convCount: number;
              }>
            >
          >;
        }>
      >
    >;
    everyAgentDailyFinishedConv?: Maybe<
      Array<
        Maybe<{
          __typename?: "reportListDailyCounts";
          agentID?: Maybe<string>;
          agentName?: Maybe<string>;
          chatCounts?: Maybe<
            Array<
              Maybe<{
                __typename?: "chatCounts";
                date: string;
                convCount: number;
              }>
            >
          >;
        }>
      >
    >;
    everyAgentTotalNewConv?: Maybe<
      Array<
        Maybe<{
          __typename?: "reportList";
          agentID?: Maybe<string>;
          agentName?: Maybe<string>;
          convCount?: Maybe<number>;
        }>
      >
    >;
    everyAgentTotalFinishedConv?: Maybe<
      Array<
        Maybe<{
          __typename?: "reportList";
          agentID?: Maybe<string>;
          agentName?: Maybe<string>;
          date?: Maybe<string>;
          convCount?: Maybe<number>;
        }>
      >
    >;
    avgAgentResponses?: Maybe<
      Array<
        Maybe<{
          __typename?: "AvgAgentResp";
          agentID: string;
          avgTimeAgentCompResp?: Maybe<string>;
          avgTimeAgentOverallResp?: Maybe<string>;
          agentName: string;
        }>
      >
    >;
    channelActivity?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChannelActivity";
          channelName: string;
          channelType: string;
          totalClaimedChat: number;
        }>
      >
    >;
  }>;
};

export type GenericReportQueryVariables = Exact<{
  input: GenericReportInput;
}>;

export type GenericReportQuery = {
  __typename?: "Query";
  genericReport: {
    __typename?: "GenericReport";
    totalConversationsEnter?: Maybe<string>;
    totalConversationsUnclaimed?: Maybe<string>;
    totalConversationsClaimed?: Maybe<string>;
    totalConversationsFinished?: Maybe<string>;
    avgTimeAgentResponse?: Maybe<string>;
    totalConversationsInQueue?: Maybe<string>;
  };
};

export type GetChannelsByTenantQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type GetChannelsByTenantQuery = {
  __typename?: "Query";
  getChannelsByTenant: Array<
    Maybe<{
      __typename?: "ChannelList";
      id?: Maybe<string>;
      name?: Maybe<string>;
      number?: Maybe<string>;
      tenantID?: Maybe<string>;
      businessName?: Maybe<string>;
      enabled?: Maybe<boolean>;
    }>
  >;
};

export type ExecuteAppCommandMutationVariables = Exact<{
  input: ExecuteCommandInput;
}>;

export type ExecuteAppCommandMutation = {
  __typename?: "Mutation";
  executeAppCommand: string;
};

export type GetInstalledApplicationsQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type GetInstalledApplicationsQuery = {
  __typename?: "Query";
  getInstalledApplications: Array<
    Maybe<{
      __typename?: "Application";
      appID: string;
      name: string;
      description: string;
      icon: string;
      shortcuts: Array<
        Maybe<{
          __typename?: "AppShortcut";
          shortcutID: string;
          appID: string;
          actionID: string;
          name: string;
          description: string;
          shortcutType: ShortcutType;
        }>
      >;
    }>
  >;
};

export type AddChannelMutationVariables = Exact<{
  input: ChannelData;
}>;

export type AddChannelMutation = {
  __typename?: "Mutation";
  addChannel: string;
};

export type ChannelQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  filter?: Maybe<ChannelFilter>;
}>;

export type ChannelQuery = {
  __typename?: "Query";
  channel: {
    __typename?: "Channel";
    id: number;
    channelName?: Maybe<string>;
    businessCategory?: Maybe<WhatsappBusinessCategory>;
    details?: Maybe<string>;
    number?: Maybe<string>;
    phoneId?: Maybe<string>;
    tenantId: number;
    channelStoreName?: Maybe<string>;
    storeDescription?: Maybe<string>;
    storeWebsite?: Maybe<string>;
    storeAddress?: Maybe<string>;
    storeImage?: Maybe<string>;
    enabled?: Maybe<boolean>;
    isConnected?: Maybe<boolean>;
    tier?: Maybe<string>;
    channelType: ChannelType;
    createdAt: number;
    updatedAt: number;
    admins: Array<
      Maybe<{
        __typename?: "Admin";
        id: number;
        accountID: string;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        passwordIsSet?: Maybe<boolean>;
        userID: string;
        type: string;
        role: AdminRole;
        active: boolean;
      }>
    >;
  };
};

export type ChannelsQueryVariables = Exact<{
  filter?: Maybe<ChannelsFilter>;
  sort?: ChannelsSort;
  first?: Scalars["Int"];
  after?: Maybe<Scalars["ID"]>;
  before?: Maybe<Scalars["ID"]>;
}>;

export type ChannelsQuery = {
  __typename?: "Query";
  channels: {
    __typename?: "Channels";
    items: Array<
      Maybe<{
        __typename?: "Channel";
        id: number;
        channelName?: Maybe<string>;
        details?: Maybe<string>;
        number?: Maybe<string>;
        phoneId?: Maybe<string>;
        tenantId: number;
        channelStoreName?: Maybe<string>;
        storeDescription?: Maybe<string>;
        storeWebsite?: Maybe<string>;
        storeAddress?: Maybe<string>;
        storeImage?: Maybe<string>;
        enabled?: Maybe<boolean>;
        isConnected?: Maybe<boolean>;
        tier?: Maybe<string>;
        channelType: ChannelType;
        createdAt: number;
        updatedAt: number;
        admins: Array<
          Maybe<{
            __typename?: "Admin";
            id: number;
            accountID: string;
            firstName: string;
            lastName: string;
            email: string;
            phone: string;
            passwordIsSet?: Maybe<boolean>;
            userID: string;
            type: string;
            role: AdminRole;
            active: boolean;
          }>
        >;
      }>
    >;
    pageInfo: {
      __typename?: "PaginationInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type IsShopeeConnectedQueryVariables = Exact<{
  input: IsShopeeConnectedInput;
}>;

export type IsShopeeConnectedQuery = {
  __typename?: "Query";
  isShopeeConnected: boolean;
};

export type IsTokopediaConnectedQueryVariables = Exact<{
  input: IsTokopediaConnectedInput;
}>;

export type IsTokopediaConnectedQuery = {
  __typename?: "Query";
  isTokopediaConnected: boolean;
};

export type DeleteShopeeChannelMutationVariables = Exact<{
  channelId: Scalars["Int"];
}>;

export type DeleteShopeeChannelMutation = {
  __typename?: "Mutation";
  deleteShopeeChannel: string;
};

export type DeleteTokopediaMutationVariables = Exact<{
  channelId: Scalars["Int"];
}>;

export type DeleteTokopediaMutation = {
  __typename?: "Mutation";
  deleteTokopediaChannel: string;
};

export type GetShopeeCredentialsQueryVariables = Exact<{
  input: ShopeeCredentialsInput;
}>;

export type GetShopeeCredentialsQuery = {
  __typename?: "Query";
  getShopeeCredentials: {
    __typename?: "CredentialResp";
    url: string;
    urlChannel: string;
    type: string;
  };
};

export type ReconnectTokopediaMutationVariables = Exact<{
  channelId: Scalars["Int"];
}>;

export type ReconnectTokopediaMutation = {
  __typename?: "Mutation";
  reconnectTokopedia: string;
};

export type RegisterTokopediaMutationVariables = Exact<{
  input: TokopediaRegisterInput;
}>;

export type RegisterTokopediaMutation = {
  __typename?: "Mutation";
  registerTokopedia: string;
};

export type Chat_RequestMarketplaceIntegrationMutationVariables = Exact<{
  input: RequestMarketplaceIntegrationInput;
}>;

export type Chat_RequestMarketplaceIntegrationMutation = {
  __typename?: "Mutation";
  requestMarketplaceIntegration: boolean;
};

export type SyncConnectedChannelsMutationVariables = Exact<{
  input: ConnectedChannelInput;
}>;

export type SyncConnectedChannelsMutation = {
  __typename?: "Mutation";
  syncConnectedChannels: boolean;
};

export type SyncTokopediaChannelsMutationVariables = Exact<{
  tenantID?: Maybe<Scalars["Int"]>;
  gateID?: Maybe<Scalars["String"]>;
}>;

export type SyncTokopediaChannelsMutation = {
  __typename?: "Mutation";
  syncTokopediaChannels: string;
};

export type ChatBotQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type ChatBotQuery = {
  __typename?: "Query";
  chatBot: { __typename?: "ChatBot"; tenantName: string; enabled: boolean };
};

export type ChatBotStatusMutationVariables = Exact<{
  tenantID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type ChatBotStatusMutation = {
  __typename?: "Mutation";
  chatBotStatus: string;
};

export type GetMenuListQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type GetMenuListQuery = {
  __typename?: "Query";
  getMenuList: Array<
    Maybe<{
      __typename?: "MenuList";
      id: string;
      name: string;
      text: string;
      ParentID: string;
      channelID: string;
      tenantID: string;
      talkToAgent: boolean;
      endChat: boolean;
      level: string;
      type: MessageType;
      cloudMediaID?: Maybe<string>;
      menuOrder?: Maybe<number>;
      isParentWrapper: boolean;
      category: string;
    }>
  >;
};

export type MlcbOperationsMutationVariables = Exact<{
  input: MlcbMenuInput;
}>;

export type MlcbOperationsMutation = {
  __typename?: "Mutation";
  mlcbOperations: string;
};

export type GetDistrictOptionsQueryVariables = Exact<{
  query: Scalars["String"];
  limit: Scalars["Int"];
}>;

export type GetDistrictOptionsQuery = {
  __typename?: "Query";
  getDistrictOptions: {
    __typename?: "GetDistrictOptions";
    districtOptions: Array<
      Maybe<{
        __typename?: "DistrictOption";
        key: string;
        value: string;
        provinceName: string;
        cityName: string;
        districtName: string;
      }>
    >;
  };
};

export type TokopediaInvoiceQueryVariables = Exact<{
  input: TokopediaInvoiceInput;
}>;

export type TokopediaInvoiceQuery = {
  __typename?: "Query";
  tokopediaInvoice: {
    __typename?: "TokopediaInvoice";
    invoiceNumber: string;
    invoiceUrl: string;
    orderId: number;
    orderStatus: string;
    orderStatusCode: number;
    totalAmount: number;
    orderInfo: {
      __typename?: "TokopediaInvoiceOrderInfo";
      orderDetail: Array<{
        __typename?: "TokopediaInvoiceOrderInfoOrderDetail";
        currencyId: number;
        currencyRate: number;
        insurancePrice: number;
        isFreeReturns: boolean;
        minOrder: number;
        normalPrice: number;
        productId: number;
        productName: string;
        productPicture: string;
        productPrice: number;
        quantity: number;
        quantityDeliver: number;
        quantityReject: number;
        sku: string;
        snapshotUrl: string;
        subtotalPrice: number;
        totalWeight: number;
        weight: number;
      }>;
    };
  };
};

export type ChatListQueryVariables = Exact<{
  input: ChatListInput;
}>;

export type ChatListQuery = {
  __typename?: "Query";
  chatList?: Maybe<{
    __typename?: "ChatList";
    data: Array<
      Maybe<{
        __typename?: "ChatListData";
        unreadMsgCount?: Maybe<number>;
        room?: Maybe<{
          __typename?: "ChatListRoom";
          id: string;
          name: string;
          channelID: string;
          lastMessageID: string;
          lastMessageTimestamp: string;
          labels?: Maybe<
            Array<
              Maybe<{
                __typename?: "ChatLabel";
                id?: Maybe<number>;
                title: string;
                description?: Maybe<string>;
                color: string;
                tenantID: number;
                rooms?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: "ChatLabelRoom";
                      id: number;
                      name: string;
                      channelID: number;
                      agentID?: Maybe<number>;
                      cUser: {
                        __typename?: "RoomContactUser";
                        id: number;
                        name: string;
                        phoneNumber: string;
                      };
                    }>
                  >
                >;
              }>
            >
          >;
        }>;
        accountUsers?: Maybe<
          Array<
            Maybe<{
              __typename?: "ChatListAccountUser";
              id: string;
              accountID: string;
              accountUserID: number;
              name: string;
              active: boolean;
            }>
          >
        >;
        contactUser?: Maybe<{
          __typename?: "ChatListContactUser";
          id: string;
          name: string;
          dp?: Maybe<string>;
          phoneNumber?: Maybe<string>;
        }>;
        messages?: Maybe<
          Array<
            Maybe<{
              __typename?: "ChatListMessage";
              id: string;
              sender: string;
              receiver: string;
              type: MessageType;
              status: MessageStatus;
              message: string;
              time: string;
              mediaURL?: Maybe<string>;
              mimeType?: Maybe<string>;
              fileName?: Maybe<string>;
              interactive?: Maybe<string>;
            }>
          >
        >;
      }>
    >;
    pageInfo?: Maybe<{
      __typename?: "PageInfo";
      hasMore?: Maybe<boolean>;
      totalPages?: Maybe<number>;
    }>;
  }>;
};

export type MessageListQueryVariables = Exact<{
  input: MessageListInput;
}>;

export type MessageListQuery = {
  __typename?: "Query";
  messageList?: Maybe<{
    __typename?: "MessageList";
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      lastMessageTimestamp: string;
      lastMessageID: string;
    }>;
    accountUsers?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListAccountUser";
          id: string;
          accountID: string;
          accountUserID: number;
          name: string;
          active: boolean;
        }>
      >
    >;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    messages: Array<
      Maybe<{
        __typename?: "ChatListMessage";
        id: string;
        sender: string;
        receiver: string;
        type: MessageType;
        status: MessageStatus;
        message: string;
        time: string;
        mediaURL?: Maybe<string>;
        mimeType?: Maybe<string>;
        fileName?: Maybe<string>;
        mediaSize?: Maybe<string>;
        sendByAgent?: Maybe<boolean>;
        senderName?: Maybe<string>;
        interactive?: Maybe<string>;
      }>
    >;
    pageInfo?: Maybe<{
      __typename?: "PageInfo";
      hasMore?: Maybe<boolean>;
      totalPages?: Maybe<number>;
    }>;
  }>;
};

export type FetchMessageByIdQueryVariables = Exact<{
  input: FetchMessageInput;
}>;

export type FetchMessageByIdQuery = {
  __typename?: "Query";
  fetchMessageByID?: Maybe<{
    __typename?: "MessageList";
    messages: Array<
      Maybe<{
        __typename?: "ChatListMessage";
        id: string;
        sender: string;
        receiver: string;
        type: MessageType;
        status: MessageStatus;
        message: string;
        time: string;
        mediaURL?: Maybe<string>;
        sendByAgent?: Maybe<boolean>;
        mimeType?: Maybe<string>;
        fileName?: Maybe<string>;
        mediaSize?: Maybe<string>;
        senderName?: Maybe<string>;
        interactive?: Maybe<string>;
      }>
    >;
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      lastMessageTimestamp: string;
      lastMessageID: string;
    }>;
    accountUsers?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListAccountUser";
          id: string;
          accountID: string;
          accountUserID: number;
          name: string;
          active: boolean;
        }>
      >
    >;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    pageInfo?: Maybe<{
      __typename?: "PageInfo";
      hasMore?: Maybe<boolean>;
      totalPages?: Maybe<number>;
    }>;
  }>;
};

export type GetUploadUrlQueryVariables = Exact<{
  input?: Maybe<UploadFile>;
}>;

export type GetUploadUrlQuery = {
  __typename?: "Query";
  getUploadUrl: { __typename?: "UploadData"; url: string; mediaName: string };
};

export type SearchMessageQueryVariables = Exact<{
  input: SearchMessageInput;
}>;

export type SearchMessageQuery = {
  __typename?: "Query";
  searchMessage?: Maybe<{
    __typename?: "SearchMessage";
    data: Array<
      Maybe<{
        __typename?: "SearchMessageData";
        unreadMsgCount?: Maybe<number>;
        room?: Maybe<{
          __typename?: "ChatListRoom";
          id: string;
          name: string;
          channelID: string;
          lastMessageID: string;
          lastMessageTimestamp: string;
          labels?: Maybe<
            Array<
              Maybe<{
                __typename?: "ChatLabel";
                id?: Maybe<number>;
                title: string;
                description?: Maybe<string>;
                color: string;
                tenantID: number;
                rooms?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: "ChatLabelRoom";
                      id: number;
                      name: string;
                      channelID: number;
                      agentID?: Maybe<number>;
                      cUser: {
                        __typename?: "RoomContactUser";
                        id: number;
                        name: string;
                        phoneNumber: string;
                      };
                    }>
                  >
                >;
              }>
            >
          >;
        }>;
        accountUsers?: Maybe<
          Array<
            Maybe<{
              __typename?: "ChatListAccountUser";
              id: string;
              accountID: string;
              accountUserID: number;
              name: string;
              active: boolean;
            }>
          >
        >;
        contactUser?: Maybe<{
          __typename?: "ChatListContactUser";
          id: string;
          name: string;
          dp?: Maybe<string>;
          phoneNumber?: Maybe<string>;
        }>;
        messages?: Maybe<
          Array<
            Maybe<{
              __typename?: "SearchMessageList";
              id: string;
              sender: string;
              receiver: string;
              type: MessageType;
              status: MessageStatus;
              message: string;
              time: string;
              interactive?: Maybe<string>;
            }>
          >
        >;
      }>
    >;
    pageInfo?: Maybe<{
      __typename?: "PageInfo";
      hasMore?: Maybe<boolean>;
      totalPages?: Maybe<number>;
    }>;
  }>;
};

export type OnboardingQueryVariables = Exact<{
  input: OnboardingInput;
}>;

export type OnboardingQuery = {
  __typename?: "Query";
  onboarding: {
    __typename?: "Onboarding";
    tenantID: string;
    onboardingType: string;
    stepIndex: number;
    isFinished: boolean;
  };
};

export type UpsertOnboardingMutationVariables = Exact<{
  input: UpsertOnboardingInput;
}>;

export type UpsertOnboardingMutation = {
  __typename?: "Mutation";
  upsertOnboarding: {
    __typename?: "Onboarding";
    tenantID: string;
    onboardingType: string;
    stepIndex: number;
    isFinished: boolean;
  };
};

export type GetShopeeOrderQueryVariables = Exact<{
  input: ShopeeOrderInput;
}>;

export type GetShopeeOrderQuery = {
  __typename?: "Query";
  getShopeeOrder: {
    __typename?: "ShopeeOrderResp";
    orderSn: string;
    orderStatus: string;
    cod: boolean;
    createTime: number;
    messageToSeller: string;
    orderUrl: string;
    totalAmount: number;
    items: Array<
      Maybe<{
        __typename?: "ShopeeOrderItem";
        imageInfo?: Maybe<{
          __typename?: "ShopeeOrderImageInfo";
          imageUrl: string;
        }>;
      }>
    >;
  };
};

export type GetTenantPlanDetailQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantPlanDetailQuery = {
  __typename?: "Query";
  getTenantPlanDetail?: Maybe<{
    __typename?: "TenantPlanDetail";
    planType: string;
    planName: string;
  }>;
};

export type GetShopeeProductQueryVariables = Exact<{
  input: ShopeeProductInput;
}>;

export type GetShopeeProductQuery = {
  __typename?: "Query";
  getShopeeProduct: {
    __typename?: "ShopeeProductResp";
    itemName: string;
    itemSku: string;
    itemStatus: string;
    brandName: string;
    customerProductUrl: string;
    productUrl: string;
    imageUrl: string;
    price: {
      __typename?: "ShopeeProductPriceInfo";
      min: {
        __typename?: "ShopeeProductPrice";
        currentPrice: number;
        currency: string;
        originalPrice: number;
      };
      max: {
        __typename?: "ShopeeProductPrice";
        currentPrice: number;
        currency: string;
        originalPrice: number;
      };
    };
  };
};

export type TokopediaProductQueryVariables = Exact<{
  input: TokopediaProductInput;
}>;

export type TokopediaProductQuery = {
  __typename?: "Query";
  tokopediaProduct: {
    __typename?: "TokopediaProduct";
    basic: { __typename?: "TokopediaProductBasic"; name: string };
    price: {
      __typename?: "TokopediaProductPrice";
      value: number;
      currency: number;
      idr: number;
    };
    pictures: Array<{
      __typename?: "TokopediaProductPicture";
      originalUrl: string;
      thumbnailUrl: string;
    }>;
    stock: {
      __typename?: "TokopediaProductStock";
      useStock: boolean;
      value: number;
    };
    other: {
      __typename?: "TokopediaProductOther";
      mobileURL: string;
      sku: string;
      url: string;
    };
  };
};

export type RefreshRoomMessagesMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RefreshRoomMessagesMutation = {
  __typename?: "Mutation";
  refreshRoomMessages: boolean;
};

export type RoomQueryVariables = Exact<{
  ID: Scalars["ID"];
}>;

export type RoomQuery = {
  __typename?: "Query";
  room: {
    __typename?: "Room";
    id: number;
    status: RoomStatus;
    channel?: Maybe<{
      __typename?: "Channel";
      id: number;
      channelType: ChannelType;
      channelName?: Maybe<string>;
    }>;
  };
};

export type GetRoomCountQueryVariables = Exact<{
  filter: ChatFilter;
}>;

export type GetRoomCountQuery = {
  __typename?: "Query";
  getRoomCount?: Maybe<
    Array<{
      __typename?: "RoomCountResp";
      roomCount?: Maybe<number>;
      roomGroupIdentifier?: Maybe<string>;
    }>
  >;
};

export type SearchRoomsQueryVariables = Exact<{
  input: SearchRoomsFilter;
}>;

export type SearchRoomsQuery = {
  __typename?: "Query";
  searchRooms: {
    __typename?: "SearchRooms";
    data: Array<
      Maybe<{
        __typename?: "SearchRoomsData";
        unreadMessages?: Maybe<number>;
        room?: Maybe<{
          __typename?: "Room";
          id: number;
          activeAccountUserId: string;
          contactUserId: number;
          roomName?: Maybe<string>;
          updatedAt?: Maybe<number>;
          createdAt: number;
          status: RoomStatus;
          claimedAt?: Maybe<number>;
          currentChannelId?: Maybe<number>;
          currentSessionId?: Maybe<number>;
          lastClosedAt?: Maybe<number>;
          tenantId: number;
          channelRoomId: string;
          contact?: Maybe<{
            __typename?: "Contact";
            id: number;
            channelId: number;
            name: string;
            address: string;
            details: string;
            number: string;
            channelContactId: string;
            isOptIn: boolean;
            createdAt: number;
          }>;
          labels?: Maybe<
            Array<
              Maybe<{
                __typename?: "ChatLabel";
                id?: Maybe<number>;
                title: string;
                description?: Maybe<string>;
                color: string;
                tenantID: number;
              }>
            >
          >;
        }>;
        thumbnailMessage?: Maybe<{
          __typename?: "ThumbnailMessage";
          id?: Maybe<number>;
          sender?: Maybe<string>;
          receiver?: Maybe<string>;
          type: MessageType;
          status: MessageStatus;
          sentAt?: Maybe<string>;
          message?: Maybe<string>;
          mediaUrl?: Maybe<string>;
          mimeType?: Maybe<string>;
          fileName?: Maybe<string>;
        }>;
      }>
    >;
    pageInfo?: Maybe<{
      __typename?: "PageInfo";
      hasMore?: Maybe<boolean>;
      totalPages?: Maybe<number>;
    }>;
  };
};

export type GetResumableUploadHandleQueryVariables = Exact<{
  input: ResumbaleUploadHandleInput;
}>;

export type GetResumableUploadHandleQuery = {
  __typename?: "Query";
  getResumableUploadHandle: {
    __typename?: "ResumbaleUploadHandleRes";
    handleId?: Maybe<string>;
  };
};

export type SyncWaMessageTemplatesQueryVariables = Exact<{
  channelID: Scalars["String"];
}>;

export type SyncWaMessageTemplatesQuery = {
  __typename?: "Query";
  syncWaMessageTemplates?: Maybe<{
    __typename?: "SyncWaMessageTemplateListRes";
    success?: Maybe<boolean>;
  }>;
};

export type UserStatusMutationVariables = Exact<{
  userId: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UserStatusMutation = {
  __typename?: "Mutation";
  userStatus: string;
};

export type ChatCreateBroadcastMessageMutationVariables = Exact<{
  input: ChatCreateBroadcastMessageInput;
}>;

export type ChatCreateBroadcastMessageMutation = {
  __typename?: "Mutation";
  chatCreateBroadcastMessage?: Maybe<{
    __typename?: "ChatCreateBroadcastMessageRes";
    success?: Maybe<boolean>;
  }>;
};

export type ChatImportBroadcastContactsMutationVariables = Exact<{
  input: ChatImportBroadcastContactsInput;
}>;

export type ChatImportBroadcastContactsMutation = {
  __typename?: "Mutation";
  chatImportBroadcastContacts?: Maybe<{
    __typename?: "ChatImportBroadcastContactsRes";
    success?: Maybe<boolean>;
    contacts?: Maybe<
      Array<
        Maybe<{
          __typename?: "Subscriber";
          id?: Maybe<number>;
          channelID?: Maybe<number>;
          name?: Maybe<string>;
          phoneNumber?: Maybe<string>;
          channelContactID?: Maybe<string>;
          isOptIn?: Maybe<boolean>;
        }>
      >
    >;
  }>;
};

export type ChatCancelBroadcastMessageMutationVariables = Exact<{
  broadcastID: Scalars["String"];
  channelID: Scalars["Int"];
}>;

export type ChatCancelBroadcastMessageMutation = {
  __typename?: "Mutation";
  chatCancelBroadcastMessage?: Maybe<{
    __typename?: "ChatCancelBroadcastMessageRes";
    success?: Maybe<boolean>;
  }>;
};

export type ChatRebroadcastMessageMutationVariables = Exact<{
  input: ChatRebroadcastMessageInput;
}>;

export type ChatRebroadcastMessageMutation = {
  __typename?: "Mutation";
  chatRebroadcastMessage?: Maybe<{
    __typename?: "ChatRebroadcastMessageRes";
    success?: Maybe<boolean>;
  }>;
};

export type UpdateChannelStatusMutationVariables = Exact<{
  channelID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdateChannelStatusMutation = {
  __typename?: "Mutation";
  updateChannelStatus: string;
};

export type UpdateAsReadMutationVariables = Exact<{
  custID: Scalars["String"];
  roomID: Scalars["String"];
}>;

export type UpdateAsReadMutation = {
  __typename?: "Mutation";
  updateRoomRead: string;
};

export type CloseChatMutationVariables = Exact<{
  roomID: Scalars["String"];
}>;

export type CloseChatMutation = { __typename?: "Mutation"; closeChat: string };

export type UpdateChannelMutationVariables = Exact<{
  input: UpdateChannelInput;
}>;

export type UpdateChannelMutation = {
  __typename?: "Mutation";
  updateChannel: string;
};

export type TransferChatMutationVariables = Exact<{
  userID: Scalars["String"];
  roomID: Scalars["String"];
}>;

export type TransferChatMutation = {
  __typename?: "Mutation";
  transferChat: string;
};

export type QueueChatMutationVariables = Exact<{
  roomId: Scalars["String"];
}>;

export type QueueChatMutation = { __typename?: "Mutation"; queueChat: string };

export type UpsertLabelMutationVariables = Exact<{
  input: UpsertLabelInput;
}>;

export type UpsertLabelMutation = {
  __typename?: "Mutation";
  upsertLabel?: Maybe<{
    __typename?: "UpsertLabelOutput";
    success?: Maybe<boolean>;
  }>;
};

export type UpsertRoomLabelsMutationVariables = Exact<{
  input: UpsertRoomLabelsInput;
}>;

export type UpsertRoomLabelsMutation = {
  __typename?: "Mutation";
  upsertRoomLabels?: Maybe<{
    __typename?: "UpsertRoomLabelsOutput";
    success?: Maybe<boolean>;
  }>;
};

export type DeleteTenantLabelMutationVariables = Exact<{
  labelID: Scalars["Int"];
}>;

export type DeleteTenantLabelMutation = {
  __typename?: "Mutation";
  deleteTenantLabel?: Maybe<{
    __typename?: "DeleteTenantLabelOutput";
    success?: Maybe<boolean>;
  }>;
};

export type CreateNewMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type CreateNewMessageMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "ChatListMessage";
    id: string;
    sender: string;
    receiver: string;
    message: string;
    status: MessageStatus;
    type: MessageType;
    time: string;
  };
};

export type UploadFileMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile: {
    __typename?: "UploadData";
    url: string;
    mediaName: string;
    messageID: string;
  };
};

export type CreateOperationalHoursMutationVariables = Exact<{
  input: OperationalHoursInput;
}>;

export type CreateOperationalHoursMutation = {
  __typename?: "Mutation";
  createOperationalHours: string;
};

export type CreateGreetingsMutationVariables = Exact<{
  input: GreetingsInput;
}>;

export type CreateGreetingsMutation = {
  __typename?: "Mutation";
  createGreetings: string;
};

export type CreateQuickReplyMutationVariables = Exact<{
  input: QuickReplyInput;
}>;

export type CreateQuickReplyMutation = {
  __typename?: "Mutation";
  createQuickReply: string;
};

export type UpdateQuickReplyMutationVariables = Exact<{
  updateQuickReplyId: Scalars["String"];
  input?: Maybe<QuickReplyInput>;
}>;

export type UpdateQuickReplyMutation = {
  __typename?: "Mutation";
  updateQuickReply: string;
};

export type DeleteQuickReplyMutationVariables = Exact<{
  deleteQuickReplyId: Scalars["String"];
}>;

export type DeleteQuickReplyMutation = {
  __typename?: "Mutation";
  deleteQuickReply: string;
};

export type CreateMessageTemplateMutationVariables = Exact<{
  input: AddMessageTemplateInput;
}>;

export type CreateMessageTemplateMutation = {
  __typename?: "Mutation";
  addMessageTemplate: string;
};

export type UpdateMessageTemplateStatusMutationVariables = Exact<{
  templateId: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdateMessageTemplateStatusMutation = {
  __typename?: "Mutation";
  updateMessageTemplateStatus: string;
};

export type DeleteMessageTemplateMutationVariables = Exact<{
  templateId: Scalars["String"];
}>;

export type DeleteMessageTemplateMutation = {
  __typename?: "Mutation";
  deleteMessageTemplate: string;
};

export type CreateWebhookMutationVariables = Exact<{
  input: Webhook;
}>;

export type CreateWebhookMutation = {
  __typename?: "Mutation";
  createWebhook: string;
};

export type WebhookStatusMutationVariables = Exact<{
  id: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type WebhookStatusMutation = {
  __typename?: "Mutation";
  webhookStatus: string;
};

export type WebhookDeleteMutationVariables = Exact<{
  id: Scalars["String"];
  token: Scalars["String"];
}>;

export type WebhookDeleteMutation = {
  __typename?: "Mutation";
  webhookDelete: string;
};

export type WebhooktTokenRefreshMutationVariables = Exact<{
  id: Scalars["String"];
  token: Scalars["String"];
}>;

export type WebhooktTokenRefreshMutation = {
  __typename?: "Mutation";
  webhookRefresh: string;
};

export type UpdateRoleQueryVariables = Exact<{
  userID: Scalars["String"];
  role: Scalars["String"];
}>;

export type UpdateRoleQuery = { __typename?: "Query"; updateRole: string };

export type GetUserQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser: {
    __typename?: "Admin";
    email: string;
    userID: string;
    phone: string;
    firstName: string;
    lastName: string;
    role: AdminRole;
    active: boolean;
  };
};

export type AdminQueryVariables = Exact<{
  tenantId: Scalars["String"];
  role?: Maybe<Scalars["String"]>;
}>;

export type AdminQuery = {
  __typename?: "Query";
  admin?: Maybe<
    Array<
      Maybe<{
        __typename?: "Admin";
        email: string;
        userID: string;
        firstName: string;
        lastName: string;
        type: string;
        phone: string;
        role: AdminRole;
        active: boolean;
      }>
    >
  >;
};

export type LinkChannelQueryVariables = Exact<{
  channelID: Scalars["String"];
  userID: Scalars["String"];
  tenant: Scalars["String"];
}>;

export type LinkChannelQuery = { __typename?: "Query"; linkChannel: string };

export type RemoveChannelLinkQueryVariables = Exact<{
  channelID: Scalars["String"];
  userID: Scalars["String"];
  tenant: Scalars["String"];
}>;

export type RemoveChannelLinkQuery = {
  __typename?: "Query";
  removeChannelLink: string;
};

export type LoginUrlQueryVariables = Exact<{
  input: LoginUrlInput;
}>;

export type LoginUrlQuery = {
  __typename?: "Query";
  loginUrl?: Maybe<{
    __typename?: "LoginResp";
    redirectUrl: string;
    cc: string;
  }>;
};

export type AccessTokenQueryVariables = Exact<{
  input: TokenRequest;
}>;

export type AccessTokenQuery = {
  __typename?: "Query";
  token?: Maybe<{
    __typename?: "Token";
    accessToken: string;
    expiresIn: number;
    scope: string;
    tokenType: string;
    userName: string;
    id: string;
    role: string;
    TenantID: string;
    fullName?: Maybe<string>;
    clientType: string;
  }>;
};

export type ChatBroadcastsQueryVariables = Exact<{
  params: ChatBroadcastsInput;
}>;

export type ChatBroadcastsQuery = {
  __typename?: "Query";
  chatBroadcasts?: Maybe<{
    __typename?: "ChatBroadcastsRes";
    cursorAfter?: Maybe<number>;
    cursorBefore?: Maybe<number>;
    broadcastMessages?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatBroadcast";
          id?: Maybe<number>;
          channelID?: Maybe<number>;
          broadcastTitle?: Maybe<string>;
          broadcastStatus?: Maybe<string>;
          templateName?: Maybe<string>;
          sentAt?: Maybe<string>;
          taskID?: Maybe<string>;
          createdAt?: Maybe<string>;
          updatedAt?: Maybe<string>;
          templateComponents?: Maybe<
            Array<
              Maybe<{
                __typename?: "TemplateComponent";
                type?: Maybe<string>;
                subType?: Maybe<string>;
                parameters?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: "TemplateComponentParameter";
                      type?: Maybe<string>;
                      text?: Maybe<string>;
                      image?: Maybe<{
                        __typename?: "Media";
                        link?: Maybe<string>;
                      }>;
                      document?: Maybe<{
                        __typename?: "Media";
                        link?: Maybe<string>;
                      }>;
                      video?: Maybe<{
                        __typename?: "Media";
                        link?: Maybe<string>;
                      }>;
                      currency?: Maybe<{
                        __typename?: "Currency";
                        fallbackValue?: Maybe<string>;
                        code?: Maybe<string>;
                        amount1000?: Maybe<number>;
                      }>;
                      dateTime?: Maybe<{
                        __typename?: "DateTime";
                        fallbackValue?: Maybe<string>;
                        dayOfWeek?: Maybe<string>;
                        year?: Maybe<number>;
                        month?: Maybe<number>;
                        dayOfMonth?: Maybe<number>;
                        hour?: Maybe<number>;
                        minute?: Maybe<number>;
                        calendar?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>
            >
          >;
        }>
      >
    >;
  }>;
};

export type ChatBroadcastSubscribersDetailsQueryVariables = Exact<{
  params: ChatBroadcastSubscribersDetailsInput;
}>;

export type ChatBroadcastSubscribersDetailsQuery = {
  __typename?: "Query";
  chatBroadcastSubscribersDetails?: Maybe<{
    __typename?: "ChatBroadcastSubscribersDetailsRes";
    cursorBefore?: Maybe<number>;
    cursorAfter?: Maybe<number>;
    viewSubscribers?: Maybe<
      Array<
        Maybe<{
          __typename?: "BroadcastSubscriberDetails";
          id?: Maybe<number>;
          channelID?: Maybe<number>;
          broadcastID?: Maybe<number>;
          subscriberID?: Maybe<number>;
          messageID?: Maybe<string>;
          messageStatus?: Maybe<string>;
          messageTimestamp?: Maybe<string>;
          templateName?: Maybe<string>;
          broadcastTitle?: Maybe<string>;
          broadcastStatus?: Maybe<string>;
          sentAt?: Maybe<string>;
          taskID?: Maybe<string>;
          subscriberName?: Maybe<string>;
          subscriberPhone?: Maybe<string>;
          isOptIn?: Maybe<boolean>;
          subscriberCreatedAt?: Maybe<string>;
          subscriberUpdatedAt?: Maybe<string>;
        }>
      >
    >;
  }>;
};

export type ChatSubscribersQueryVariables = Exact<{
  params: ChatSubscribersInput;
}>;

export type ChatSubscribersQuery = {
  __typename?: "Query";
  chatSubscribers?: Maybe<{
    __typename?: "ChatSubscribersRes";
    cursorBefore?: Maybe<number>;
    cursorAfter?: Maybe<number>;
    subscribers?: Maybe<
      Array<
        Maybe<{
          __typename?: "Subscriber";
          id?: Maybe<number>;
          channelID?: Maybe<number>;
          name?: Maybe<string>;
          phoneNumber?: Maybe<string>;
          isOptIn?: Maybe<boolean>;
        }>
      >
    >;
    pageInfo?: Maybe<{
      __typename?: "PageSpecInfo";
      itemsPerPage?: Maybe<number>;
      pageNumber?: Maybe<number>;
      totalItems?: Maybe<number>;
    }>;
  }>;
};

export type ChatDetailBroadcastQueryVariables = Exact<{
  params: ChatDetailBroadcastInput;
}>;

export type ChatDetailBroadcastQuery = {
  __typename?: "Query";
  chatDetailBroadcast?: Maybe<{
    __typename?: "ChatDetailBroadcastRes";
    broadcastMessage?: Maybe<{
      __typename?: "ChatBroadcast";
      id?: Maybe<number>;
      channelID?: Maybe<number>;
      broadcastTitle?: Maybe<string>;
      broadcastStatus?: Maybe<string>;
      templateName?: Maybe<string>;
      sentAt?: Maybe<string>;
      taskID?: Maybe<string>;
      createdAt?: Maybe<string>;
      updatedAt?: Maybe<string>;
      templateComponents?: Maybe<
        Array<
          Maybe<{
            __typename?: "TemplateComponent";
            type?: Maybe<string>;
            subType?: Maybe<string>;
            parameters?: Maybe<
              Array<
                Maybe<{
                  __typename?: "TemplateComponentParameter";
                  type?: Maybe<string>;
                  text?: Maybe<string>;
                  image?: Maybe<{ __typename?: "Media"; link?: Maybe<string> }>;
                  document?: Maybe<{
                    __typename?: "Media";
                    link?: Maybe<string>;
                  }>;
                  video?: Maybe<{ __typename?: "Media"; link?: Maybe<string> }>;
                  currency?: Maybe<{
                    __typename?: "Currency";
                    fallbackValue?: Maybe<string>;
                    code?: Maybe<string>;
                    amount1000?: Maybe<number>;
                  }>;
                  dateTime?: Maybe<{
                    __typename?: "DateTime";
                    fallbackValue?: Maybe<string>;
                    dayOfWeek?: Maybe<string>;
                    year?: Maybe<number>;
                    month?: Maybe<number>;
                    dayOfMonth?: Maybe<number>;
                    hour?: Maybe<number>;
                    minute?: Maybe<number>;
                    calendar?: Maybe<string>;
                  }>;
                }>
              >
            >;
          }>
        >
      >;
    }>;
    previewTemplate?: Maybe<{
      __typename?: "MessageTemplateList";
      templateIDinDB: string;
      templateID: string;
      templateName: string;
      templateBody: string;
      templateLang: string;
      templateCategory: string;
      templateVariableCount: string;
      templateType: string;
      isHeader?: Maybe<boolean>;
      headerType?: Maybe<string>;
      cloudMediaID?: Maybe<string>;
      tenantID: string;
      channelID: string;
      templateStatus?: Maybe<string>;
      activeStatus?: Maybe<boolean>;
    }>;
  }>;
};

export type UnclaimedChatCountQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UnclaimedChatCountQuery = {
  __typename?: "Query";
  unclaimedChatCount: { __typename?: "Count"; status: string; count: number };
};

export type ClaimQueryVariables = Exact<{
  userId: Scalars["String"];
  roomID?: Maybe<Scalars["Int"]>;
}>;

export type ClaimQuery = {
  __typename?: "Query";
  claim: {
    __typename?: "ChatListData";
    unreadMsgCount?: Maybe<number>;
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      agentID?: Maybe<string>;
      labels?: Maybe<
        Array<
          Maybe<{
            __typename?: "ChatLabel";
            id?: Maybe<number>;
            title: string;
            description?: Maybe<string>;
            color: string;
            tenantID: number;
            rooms?: Maybe<
              Array<
                Maybe<{
                  __typename?: "ChatLabelRoom";
                  id: number;
                  name: string;
                  channelID: number;
                  agentID?: Maybe<number>;
                  cUser: {
                    __typename?: "RoomContactUser";
                    id: number;
                    name: string;
                    phoneNumber: string;
                  };
                }>
              >
            >;
          }>
        >
      >;
    }>;
    accountUsers?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListAccountUser";
          id: string;
          name: string;
          active: boolean;
        }>
      >
    >;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    messages?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListMessage";
          id: string;
          sender: string;
          receiver: string;
          type: MessageType;
          status: MessageStatus;
          message: string;
          time: string;
          sendByAgent?: Maybe<boolean>;
          mediaURL?: Maybe<string>;
          mimeType?: Maybe<string>;
          fileName?: Maybe<string>;
          mediaSize?: Maybe<string>;
        }>
      >
    >;
  };
};

export type DashboardConversationsByTenantQueryVariables = Exact<{
  tenantId: Scalars["String"];
  userId?: Maybe<Scalars["String"]>;
}>;

export type DashboardConversationsByTenantQuery = {
  __typename?: "Query";
  dashboardConversationsByTenant: {
    __typename?: "GenericReport";
    totalConversationsUnclaimed?: Maybe<string>;
    totalConversationsClaimed?: Maybe<string>;
    totalConversationsFinished?: Maybe<string>;
  };
};

export type GenericDashboardReportQueryVariables = Exact<{
  input: GenericDashboardReportInput;
}>;

export type GenericDashboardReportQuery = {
  __typename?: "Query";
  genericDashboardReport: {
    __typename?: "GenericDashboardReport";
    averageTimeSolution?: Maybe<string>;
    avgTimeFirstResponse?: Maybe<string>;
    averageResponseTimeWhole?: Maybe<string>;
    totalConversationsInQueue?: Maybe<string>;
    totalConversationsActive?: Maybe<string>;
    totalConversationsDone?: Maybe<string>;
    totalConversationsIncoming?: Maybe<string>;
    totalConversationsClaimed?: Maybe<string>;
    averageResponseTimeAgent?: Maybe<string>;
  };
};

export type GetChannelsByUserQueryVariables = Exact<{
  tenantId: Scalars["String"];
  userId: Scalars["String"];
}>;

export type GetChannelsByUserQuery = {
  __typename?: "Query";
  getChannelsByUser: Array<
    Maybe<{
      __typename?: "ChannelList";
      id?: Maybe<string>;
      name?: Maybe<string>;
      number?: Maybe<string>;
      tenantID?: Maybe<string>;
      businessName?: Maybe<string>;
      enabled?: Maybe<boolean>;
    }>
  >;
};

export type GetLabelsByTenantQueryVariables = Exact<{
  input: GetLabelsByTenantInput;
}>;

export type GetLabelsByTenantQuery = {
  __typename?: "Query";
  getLabelsByTenant: {
    __typename?: "LabelsByTenant";
    labels?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatLabel";
          id?: Maybe<number>;
          title: string;
          description?: Maybe<string>;
          color: string;
          tenantID: number;
          rooms?: Maybe<
            Array<
              Maybe<{
                __typename?: "ChatLabelRoom";
                id: number;
                name: string;
                channelID: number;
                agentID?: Maybe<number>;
              }>
            >
          >;
        }>
      >
    >;
  };
};

export type GetRoomsByLabelQueryVariables = Exact<{
  input: GetRoomsByLabelInput;
}>;

export type GetRoomsByLabelQuery = {
  __typename?: "Query";
  getRoomsByLabel: {
    __typename?: "RoomsByLabel";
    cursorAfter?: Maybe<number>;
    cursorBefore?: Maybe<number>;
    rooms?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatLabelRoom";
          id: number;
          name: string;
          channelID: number;
          agentID?: Maybe<number>;
          cUser: {
            __typename?: "RoomContactUser";
            id: number;
            name: string;
            phoneNumber: string;
          };
        }>
      >
    >;
  };
};

export type GetOperationalHoursByTenantQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type GetOperationalHoursByTenantQuery = {
  __typename?: "Query";
  getOperationalHoursByTenant: Array<
    Maybe<{
      __typename?: "OperationalHourList";
      id: string;
      tenantID: string;
      weekHours: string;
      weekendHours: string;
      channelID: string;
      offOperationalText: string;
      onOperationalText: string;
      newBuyer: boolean;
    }>
  >;
};

export type GetOperationalHoursByChannelQueryVariables = Exact<{
  channelId: Scalars["String"];
}>;

export type GetOperationalHoursByChannelQuery = {
  __typename?: "Query";
  getOperationalHoursByChannel: Array<
    Maybe<{
      __typename?: "GetOperationalHours";
      id: string;
      tenantID: string;
      channelID: string;
      onOperationalText: string;
      offOperationalHoursInput: string;
      isNewVendor?: Maybe<boolean>;
      messageTemplateID?: Maybe<string>;
      operationalHours: Array<
        Maybe<{
          __typename?: "OperationalHours";
          id: string;
          weekDay: string;
          from: string;
          to: string;
          open: boolean;
        }>
      >;
    }>
  >;
};

export type GetGreetingsByChannelQueryVariables = Exact<{
  channelId: Scalars["String"];
}>;

export type GetGreetingsByChannelQuery = {
  __typename?: "Query";
  getGreetingsByChannel: {
    __typename?: "Greetings";
    channel: string;
    greetings: string;
    newCustomer: boolean;
    type: MessageType;
    mediaURL?: Maybe<string>;
  };
};

export type GetQuickReplyQueryVariables = Exact<{
  tenantId: Scalars["String"];
  status?: Maybe<Scalars["Int"]>;
}>;

export type GetQuickReplyQuery = {
  __typename?: "Query";
  getQuickReply: Array<
    Maybe<{
      __typename?: "QuickReplyList";
      id: number;
      tenantID: number;
      title: string;
      content: string;
      status: boolean;
      cloudMediaID?: Maybe<string>;
      type?: Maybe<string>;
      signedUrl?: Maybe<string>;
    }>
  >;
};

export type GetQuickReplyByIdQueryVariables = Exact<{
  getQuickReplyByIdId: Scalars["String"];
}>;

export type GetQuickReplyByIdQuery = {
  __typename?: "Query";
  getQuickReplyByID: {
    __typename?: "QuickReplyList";
    id: number;
    tenantID: number;
    title: string;
    content: string;
    status: boolean;
    cloudMediaID?: Maybe<string>;
  };
};

export type GetMessageTemplatesQueryVariables = Exact<{
  tenantId: Scalars["String"];
  category?: Maybe<Scalars["String"]>;
  channelId?: Maybe<Scalars["String"]>;
  excludeCat: TemplateCategory;
  status?: Maybe<Scalars["Int"]>;
}>;

export type GetMessageTemplatesQuery = {
  __typename?: "Query";
  getMessageTemplates?: Maybe<
    Array<
      Maybe<{
        __typename?: "MessageTemplateList";
        isEditable?: Maybe<boolean>;
        templateIDinDB: string;
        templateID: string;
        templateName: string;
        templateBody: string;
        templateLang: string;
        templateCategory: string;
        templateVariableCount: string;
        templateType: string;
        tenantID: string;
        channelID: string;
        templateStatus?: Maybe<string>;
        activeStatus?: Maybe<boolean>;
        cloudMediaID?: Maybe<string>;
      }>
    >
  >;
};

export type TemplateCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type TemplateCategoriesQuery = {
  __typename?: "Query";
  getMessageTemplateCaterogies: Array<Maybe<string>>;
};

export type GetFileUploadUrlQueryVariables = Exact<{
  fileName: Scalars["String"];
  contentType: Scalars["String"];
}>;

export type GetFileUploadUrlQuery = {
  __typename?: "Query";
  getUploadUrl: { __typename?: "UploadData"; url: string; mediaName: string };
};

export type WebhookListQueryVariables = Exact<{
  tenantId: Scalars["String"];
}>;

export type WebhookListQuery = {
  __typename?: "Query";
  webhookList: Array<
    Maybe<{
      __typename?: "WebhookData";
      id: string;
      ChannelID: string;
      TenantID: string;
      TemplateID: string;
      Name: string;
      SupervisorID: string;
      Enabled: boolean;
    }>
  >;
};

export type WebhookDetailQueryVariables = Exact<{
  webhookID: Scalars["String"];
}>;

export type WebhookDetailQuery = {
  __typename?: "Query";
  webhookDetail: {
    __typename?: "WebhookDetail";
    id: string;
    ChannelID: string;
    TenantID: string;
    TemplateID: string;
    Name: string;
    SupervisorID: string;
    Curl: string;
    Link: string;
    Enabled: boolean;
    Token: string;
    TokenExpiry: string;
    TokenUpdatedAt: string;
  };
};

export type ListMessagesSubscriptionVariables = Exact<{
  input: ReceiveMessageSub;
}>;

export type ListMessagesSubscription = {
  __typename?: "Subscription";
  receiveMessage: {
    __typename?: "ChatMessage";
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      lastMessageTimestamp: string;
      lastMessageID: string;
    }>;
    accountUser?: Maybe<{
      __typename?: "ChatListAccountUser";
      id: string;
      name: string;
      active: boolean;
    }>;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    message?: Maybe<{
      __typename?: "ChatListMessage";
      id: string;
      sender: string;
      receiver: string;
      type: MessageType;
      status: MessageStatus;
      message: string;
      time: string;
      mediaURL?: Maybe<string>;
      mimeType?: Maybe<string>;
      mediaSize?: Maybe<string>;
      fileName?: Maybe<string>;
      sendByAgent?: Maybe<boolean>;
      senderName?: Maybe<string>;
    }>;
  };
};

export type MessageStatusSubscriptionVariables = Exact<{
  input: Scalars["String"];
}>;

export type MessageStatusSubscription = {
  __typename?: "Subscription";
  messageStatus: {
    __typename?: "ChatMessageStatus";
    roomID: string;
    messageID: string;
    messageStatus: MessageStatus;
  };
};

export type ChatStatusSubscriptionVariables = Exact<{ [key: string]: never }>;

export type ChatStatusSubscription = {
  __typename?: "Subscription";
  chatStatus: { __typename?: "Count"; count: number };
};

export type TransferChatsubSubscriptionVariables = Exact<{
  input: Scalars["String"];
}>;

export type TransferChatsubSubscription = {
  __typename?: "Subscription";
  transferChatSub?: Maybe<{
    __typename?: "ChatListData";
    messages?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListMessage";
          id: string;
          sender: string;
          receiver: string;
          type: MessageType;
          mediaURL?: Maybe<string>;
          mediaSize?: Maybe<string>;
        }>
      >
    >;
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      agentID?: Maybe<string>;
    }>;
    accountUsers?: Maybe<
      Array<
        Maybe<{
          __typename?: "ChatListAccountUser";
          id: string;
          name: string;
          active: boolean;
        }>
      >
    >;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
  }>;
};

export type UnclaimedChatSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UnclaimedChatSubscription = {
  __typename?: "Subscription";
  unclaimedChat: {
    __typename?: "ChatMessage";
    add?: Maybe<boolean>;
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      agentID?: Maybe<string>;
    }>;
    accountUser?: Maybe<{
      __typename?: "ChatListAccountUser";
      id: string;
      name: string;
      active: boolean;
    }>;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    message?: Maybe<{
      __typename?: "ChatListMessage";
      id: string;
      sender: string;
      receiver: string;
      type: MessageType;
      status: MessageStatus;
      message: string;
      time: string;
      sendByAgent?: Maybe<boolean>;
      mediaURL?: Maybe<string>;
      mimeType?: Maybe<string>;
      fileName?: Maybe<string>;
      mediaSize?: Maybe<string>;
    }>;
  };
};

export type MlcbChatSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MlcbChatSubscription = {
  __typename?: "Subscription";
  mlcbChat: {
    __typename?: "ChatMessage";
    add?: Maybe<boolean>;
    room?: Maybe<{
      __typename?: "ChatListRoom";
      id: string;
      name: string;
      channelID: string;
      agentID?: Maybe<string>;
    }>;
    accountUser?: Maybe<{
      __typename?: "ChatListAccountUser";
      id: string;
      name: string;
      active: boolean;
    }>;
    contactUser?: Maybe<{
      __typename?: "ChatListContactUser";
      id: string;
      name: string;
      dp?: Maybe<string>;
      phoneNumber?: Maybe<string>;
    }>;
    message?: Maybe<{
      __typename?: "ChatListMessage";
      id: string;
      sender: string;
      receiver: string;
      type: MessageType;
      status: MessageStatus;
      message: string;
      time: string;
      sendByAgent?: Maybe<boolean>;
      mediaURL?: Maybe<string>;
      mimeType?: Maybe<string>;
      fileName?: Maybe<string>;
      mediaSize?: Maybe<string>;
    }>;
  };
};

export const CreateUserDocument = gql`
  mutation createUser(
    $email: String!
    $phone: String!
    $role: String!
    $firstname: String!
    $lastname: String!
    $tenantid: String!
  ) {
    createUser(
      input: {
        email: $email
        phone: $phone
        firstName: $firstname
        LastName: $lastname
        role: $role
        tenantID: $tenantid
      }
    ) {
      userID
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      tenantid: // value for 'tenantid'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const GetAdminsDocument = gql`
  query getAdmins($pageNumber: Int!, $itemPerPage: Int!) {
    admins(pageNumber: $pageNumber, itemPerPage: $itemPerPage) {
      items {
        id
        accountID
        firstName
        lastName
        email
        phone
        passwordIsSet
        lastLogin
        role
        phoneVerifiedAt
        chatAdmin {
          id
          accountID
          firstName
          lastName
          email
          phone
          passwordIsSet
          userID
          type
          role
          active
        }
      }
      pageInfo {
        totalPages
        totalItems
        page
        perPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useGetAdminsQuery__
 *
 * To run a query within a React component, call `useGetAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      itemPerPage: // value for 'itemPerPage'
 *   },
 * });
 */
export function useGetAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminsQuery, GetAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminsQuery, GetAdminsQueryVariables>(
    GetAdminsDocument,
    options
  );
}
export function useGetAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminsQuery,
    GetAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminsQuery, GetAdminsQueryVariables>(
    GetAdminsDocument,
    options
  );
}
export type GetAdminsQueryHookResult = ReturnType<typeof useGetAdminsQuery>;
export type GetAdminsLazyQueryHookResult = ReturnType<
  typeof useGetAdminsLazyQuery
>;
export type GetAdminsQueryResult = Apollo.QueryResult<
  GetAdminsQuery,
  GetAdminsQueryVariables
>;
export const GetSingleAdminDocument = gql`
  query getSingleAdmin($id: ID!) {
    singleAdmin(id: $id) {
      id
      accountID
      firstName
      lastName
      email
      phone
      passwordIsSet
      userID
      type
      role
      active
    }
  }
`;

/**
 * __useGetSingleAdminQuery__
 *
 * To run a query within a React component, call `useGetSingleAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSingleAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSingleAdminQuery,
    GetSingleAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSingleAdminQuery, GetSingleAdminQueryVariables>(
    GetSingleAdminDocument,
    options
  );
}
export function useGetSingleAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSingleAdminQuery,
    GetSingleAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSingleAdminQuery, GetSingleAdminQueryVariables>(
    GetSingleAdminDocument,
    options
  );
}
export type GetSingleAdminQueryHookResult = ReturnType<
  typeof useGetSingleAdminQuery
>;
export type GetSingleAdminLazyQueryHookResult = ReturnType<
  typeof useGetSingleAdminLazyQuery
>;
export type GetSingleAdminQueryResult = Apollo.QueryResult<
  GetSingleAdminQuery,
  GetSingleAdminQueryVariables
>;
export const ChatAdminsDocument = gql`
  query chatAdmins(
    $sortBy: String
    $sortType: String
    $filter: ChatAdminsFilterInput
  ) {
    chatAdmins(sortBy: $sortBy, sortType: $sortType, filter: $filter) {
      id
      accountID
      firstName
      lastName
      email
      phone
      passwordIsSet
      userID
      type
      role
      active
    }
  }
`;

/**
 * __useChatAdminsQuery__
 *
 * To run a query within a React component, call `useChatAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatAdminsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortType: // value for 'sortType'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChatAdminsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChatAdminsQuery,
    ChatAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatAdminsQuery, ChatAdminsQueryVariables>(
    ChatAdminsDocument,
    options
  );
}
export function useChatAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatAdminsQuery,
    ChatAdminsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatAdminsQuery, ChatAdminsQueryVariables>(
    ChatAdminsDocument,
    options
  );
}
export type ChatAdminsQueryHookResult = ReturnType<typeof useChatAdminsQuery>;
export type ChatAdminsLazyQueryHookResult = ReturnType<
  typeof useChatAdminsLazyQuery
>;
export type ChatAdminsQueryResult = Apollo.QueryResult<
  ChatAdminsQuery,
  ChatAdminsQueryVariables
>;
export const AdminDeletedDocument = gql`
  subscription adminDeleted($input: AdminDeletedInput!) {
    adminDeleted(input: $input) {
      shouldLogout
    }
  }
`;

/**
 * __useAdminDeletedSubscription__
 *
 * To run a query within a React component, call `useAdminDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdminDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDeletedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AdminDeletedSubscription,
    AdminDeletedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AdminDeletedSubscription,
    AdminDeletedSubscriptionVariables
  >(AdminDeletedDocument, options);
}
export type AdminDeletedSubscriptionHookResult = ReturnType<
  typeof useAdminDeletedSubscription
>;
export type AdminDeletedSubscriptionResult =
  Apollo.SubscriptionResult<AdminDeletedSubscription>;
export const AnalyticsReportDocument = gql`
  query analyticsReport($input: GenericReportInput!) {
    analyticsReport(input: $input) {
      everyAgentDailyNewConv {
        agentID
        agentName
        chatCounts {
          date
          convCount
        }
      }
      everyAgentDailyFinishedConv {
        agentID
        agentName
        chatCounts {
          date
          convCount
        }
      }
      everyAgentTotalNewConv {
        agentID
        agentName
        convCount
      }
      everyAgentTotalFinishedConv {
        agentID
        agentName
        date
        convCount
      }
      avgAgentResponses {
        agentID
        avgTimeAgentCompResp
        avgTimeAgentOverallResp
        agentName
      }
      channelActivity {
        channelName
        channelType
        totalClaimedChat
      }
    }
  }
`;

/**
 * __useAnalyticsReportQuery__
 *
 * To run a query within a React component, call `useAnalyticsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsReportQuery, AnalyticsReportQueryVariables>(
    AnalyticsReportDocument,
    options
  );
}
export function useAnalyticsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AnalyticsReportQuery,
    AnalyticsReportQueryVariables
  >(AnalyticsReportDocument, options);
}
export type AnalyticsReportQueryHookResult = ReturnType<
  typeof useAnalyticsReportQuery
>;
export type AnalyticsReportLazyQueryHookResult = ReturnType<
  typeof useAnalyticsReportLazyQuery
>;
export type AnalyticsReportQueryResult = Apollo.QueryResult<
  AnalyticsReportQuery,
  AnalyticsReportQueryVariables
>;
export const GenericReportDocument = gql`
  query genericReport($input: GenericReportInput!) {
    genericReport(input: $input) {
      totalConversationsEnter
      totalConversationsUnclaimed
      totalConversationsClaimed
      totalConversationsFinished
      avgTimeAgentResponse
      totalConversationsInQueue
    }
  }
`;

/**
 * __useGenericReportQuery__
 *
 * To run a query within a React component, call `useGenericReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenericReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenericReportQuery,
    GenericReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenericReportQuery, GenericReportQueryVariables>(
    GenericReportDocument,
    options
  );
}
export function useGenericReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenericReportQuery,
    GenericReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenericReportQuery, GenericReportQueryVariables>(
    GenericReportDocument,
    options
  );
}
export type GenericReportQueryHookResult = ReturnType<
  typeof useGenericReportQuery
>;
export type GenericReportLazyQueryHookResult = ReturnType<
  typeof useGenericReportLazyQuery
>;
export type GenericReportQueryResult = Apollo.QueryResult<
  GenericReportQuery,
  GenericReportQueryVariables
>;
export const GetChannelsByTenantDocument = gql`
  query GetChannelsByTenant($tenantId: String!) {
    getChannelsByTenant(tenantId: $tenantId) {
      id
      name
      number
      tenantID
      businessName
      enabled
    }
  }
`;

/**
 * __useGetChannelsByTenantQuery__
 *
 * To run a query within a React component, call `useGetChannelsByTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsByTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsByTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetChannelsByTenantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChannelsByTenantQuery,
    GetChannelsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChannelsByTenantQuery,
    GetChannelsByTenantQueryVariables
  >(GetChannelsByTenantDocument, options);
}
export function useGetChannelsByTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelsByTenantQuery,
    GetChannelsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChannelsByTenantQuery,
    GetChannelsByTenantQueryVariables
  >(GetChannelsByTenantDocument, options);
}
export type GetChannelsByTenantQueryHookResult = ReturnType<
  typeof useGetChannelsByTenantQuery
>;
export type GetChannelsByTenantLazyQueryHookResult = ReturnType<
  typeof useGetChannelsByTenantLazyQuery
>;
export type GetChannelsByTenantQueryResult = Apollo.QueryResult<
  GetChannelsByTenantQuery,
  GetChannelsByTenantQueryVariables
>;
export const ExecuteAppCommandDocument = gql`
  mutation ExecuteAppCommand($input: ExecuteCommandInput!) {
    executeAppCommand(input: $input)
  }
`;
export type ExecuteAppCommandMutationFn = Apollo.MutationFunction<
  ExecuteAppCommandMutation,
  ExecuteAppCommandMutationVariables
>;

/**
 * __useExecuteAppCommandMutation__
 *
 * To run a mutation, you first call `useExecuteAppCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteAppCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeAppCommandMutation, { data, loading, error }] = useExecuteAppCommandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteAppCommandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteAppCommandMutation,
    ExecuteAppCommandMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExecuteAppCommandMutation,
    ExecuteAppCommandMutationVariables
  >(ExecuteAppCommandDocument, options);
}
export type ExecuteAppCommandMutationHookResult = ReturnType<
  typeof useExecuteAppCommandMutation
>;
export type ExecuteAppCommandMutationResult =
  Apollo.MutationResult<ExecuteAppCommandMutation>;
export type ExecuteAppCommandMutationOptions = Apollo.BaseMutationOptions<
  ExecuteAppCommandMutation,
  ExecuteAppCommandMutationVariables
>;
export const GetInstalledApplicationsDocument = gql`
  query GetInstalledApplications($tenantId: String!) {
    getInstalledApplications(tenantId: $tenantId) {
      appID
      name
      description
      icon
      shortcuts {
        shortcutID
        appID
        actionID
        name
        description
        shortcutType
      }
    }
  }
`;

/**
 * __useGetInstalledApplicationsQuery__
 *
 * To run a query within a React component, call `useGetInstalledApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstalledApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstalledApplicationsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetInstalledApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstalledApplicationsQuery,
    GetInstalledApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInstalledApplicationsQuery,
    GetInstalledApplicationsQueryVariables
  >(GetInstalledApplicationsDocument, options);
}
export function useGetInstalledApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstalledApplicationsQuery,
    GetInstalledApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInstalledApplicationsQuery,
    GetInstalledApplicationsQueryVariables
  >(GetInstalledApplicationsDocument, options);
}
export type GetInstalledApplicationsQueryHookResult = ReturnType<
  typeof useGetInstalledApplicationsQuery
>;
export type GetInstalledApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetInstalledApplicationsLazyQuery
>;
export type GetInstalledApplicationsQueryResult = Apollo.QueryResult<
  GetInstalledApplicationsQuery,
  GetInstalledApplicationsQueryVariables
>;
export const AddChannelDocument = gql`
  mutation addChannel($input: ChannelData!) {
    addChannel(input: $input)
  }
`;
export type AddChannelMutationFn = Apollo.MutationFunction<
  AddChannelMutation,
  AddChannelMutationVariables
>;

/**
 * __useAddChannelMutation__
 *
 * To run a mutation, you first call `useAddChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChannelMutation, { data, loading, error }] = useAddChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChannelMutation,
    AddChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddChannelMutation, AddChannelMutationVariables>(
    AddChannelDocument,
    options
  );
}
export type AddChannelMutationHookResult = ReturnType<
  typeof useAddChannelMutation
>;
export type AddChannelMutationResult =
  Apollo.MutationResult<AddChannelMutation>;
export type AddChannelMutationOptions = Apollo.BaseMutationOptions<
  AddChannelMutation,
  AddChannelMutationVariables
>;
export const ChannelDocument = gql`
  query channel($id: ID, $filter: ChannelFilter) {
    channel(id: $id, filter: $filter) {
      id
      admins {
        id
        accountID
        firstName
        lastName
        email
        phone
        passwordIsSet
        userID
        type
        role
        active
      }
      channelName
      businessCategory
      details
      number
      phoneId
      tenantId
      channelStoreName
      storeDescription
      storeWebsite
      storeAddress
      storeImage
      enabled
      isConnected
      tier
      channelType
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useChannelQuery__
 *
 * To run a query within a React component, call `useChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChannelQuery(
  baseOptions?: Apollo.QueryHookOptions<ChannelQuery, ChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChannelQuery, ChannelQueryVariables>(
    ChannelDocument,
    options
  );
}
export function useChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChannelQuery, ChannelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChannelQuery, ChannelQueryVariables>(
    ChannelDocument,
    options
  );
}
export type ChannelQueryHookResult = ReturnType<typeof useChannelQuery>;
export type ChannelLazyQueryHookResult = ReturnType<typeof useChannelLazyQuery>;
export type ChannelQueryResult = Apollo.QueryResult<
  ChannelQuery,
  ChannelQueryVariables
>;
export const ChannelsDocument = gql`
  query channels(
    $filter: ChannelsFilter
    $sort: ChannelsSort! = ID_ASC
    $first: Int! = 20
    $after: ID
    $before: ID
  ) {
    channels(
      filter: $filter
      sort: $sort
      first: $first
      after: $after
      before: $before
    ) {
      items {
        id
        admins {
          id
          accountID
          firstName
          lastName
          email
          phone
          passwordIsSet
          userID
          type
          role
          active
        }
        channelName
        details
        number
        phoneId
        tenantId
        channelStoreName
        storeDescription
        storeWebsite
        storeAddress
        storeImage
        enabled
        isConnected
        tier
        channelType
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(
    ChannelsDocument,
    options
  );
}
export function useChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChannelsQuery,
    ChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(
    ChannelsDocument,
    options
  );
}
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<
  typeof useChannelsLazyQuery
>;
export type ChannelsQueryResult = Apollo.QueryResult<
  ChannelsQuery,
  ChannelsQueryVariables
>;
export const IsShopeeConnectedDocument = gql`
  query isShopeeConnected($input: IsShopeeConnectedInput!) {
    isShopeeConnected(input: $input)
  }
`;

/**
 * __useIsShopeeConnectedQuery__
 *
 * To run a query within a React component, call `useIsShopeeConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsShopeeConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsShopeeConnectedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsShopeeConnectedQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsShopeeConnectedQuery,
    IsShopeeConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsShopeeConnectedQuery,
    IsShopeeConnectedQueryVariables
  >(IsShopeeConnectedDocument, options);
}
export function useIsShopeeConnectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsShopeeConnectedQuery,
    IsShopeeConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsShopeeConnectedQuery,
    IsShopeeConnectedQueryVariables
  >(IsShopeeConnectedDocument, options);
}
export type IsShopeeConnectedQueryHookResult = ReturnType<
  typeof useIsShopeeConnectedQuery
>;
export type IsShopeeConnectedLazyQueryHookResult = ReturnType<
  typeof useIsShopeeConnectedLazyQuery
>;
export type IsShopeeConnectedQueryResult = Apollo.QueryResult<
  IsShopeeConnectedQuery,
  IsShopeeConnectedQueryVariables
>;
export const IsTokopediaConnectedDocument = gql`
  query isTokopediaConnected($input: IsTokopediaConnectedInput!) {
    isTokopediaConnected(input: $input)
  }
`;

/**
 * __useIsTokopediaConnectedQuery__
 *
 * To run a query within a React component, call `useIsTokopediaConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTokopediaConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTokopediaConnectedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsTokopediaConnectedQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsTokopediaConnectedQuery,
    IsTokopediaConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsTokopediaConnectedQuery,
    IsTokopediaConnectedQueryVariables
  >(IsTokopediaConnectedDocument, options);
}
export function useIsTokopediaConnectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsTokopediaConnectedQuery,
    IsTokopediaConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsTokopediaConnectedQuery,
    IsTokopediaConnectedQueryVariables
  >(IsTokopediaConnectedDocument, options);
}
export type IsTokopediaConnectedQueryHookResult = ReturnType<
  typeof useIsTokopediaConnectedQuery
>;
export type IsTokopediaConnectedLazyQueryHookResult = ReturnType<
  typeof useIsTokopediaConnectedLazyQuery
>;
export type IsTokopediaConnectedQueryResult = Apollo.QueryResult<
  IsTokopediaConnectedQuery,
  IsTokopediaConnectedQueryVariables
>;
export const DeleteShopeeChannelDocument = gql`
  mutation deleteShopeeChannel($channelId: Int!) {
    deleteShopeeChannel(channelID: $channelId)
  }
`;
export type DeleteShopeeChannelMutationFn = Apollo.MutationFunction<
  DeleteShopeeChannelMutation,
  DeleteShopeeChannelMutationVariables
>;

/**
 * __useDeleteShopeeChannelMutation__
 *
 * To run a mutation, you first call `useDeleteShopeeChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopeeChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopeeChannelMutation, { data, loading, error }] = useDeleteShopeeChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteShopeeChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShopeeChannelMutation,
    DeleteShopeeChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteShopeeChannelMutation,
    DeleteShopeeChannelMutationVariables
  >(DeleteShopeeChannelDocument, options);
}
export type DeleteShopeeChannelMutationHookResult = ReturnType<
  typeof useDeleteShopeeChannelMutation
>;
export type DeleteShopeeChannelMutationResult =
  Apollo.MutationResult<DeleteShopeeChannelMutation>;
export type DeleteShopeeChannelMutationOptions = Apollo.BaseMutationOptions<
  DeleteShopeeChannelMutation,
  DeleteShopeeChannelMutationVariables
>;
export const DeleteTokopediaDocument = gql`
  mutation deleteTokopedia($channelId: Int!) {
    deleteTokopediaChannel(channelID: $channelId)
  }
`;
export type DeleteTokopediaMutationFn = Apollo.MutationFunction<
  DeleteTokopediaMutation,
  DeleteTokopediaMutationVariables
>;

/**
 * __useDeleteTokopediaMutation__
 *
 * To run a mutation, you first call `useDeleteTokopediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokopediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokopediaMutation, { data, loading, error }] = useDeleteTokopediaMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteTokopediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTokopediaMutation,
    DeleteTokopediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTokopediaMutation,
    DeleteTokopediaMutationVariables
  >(DeleteTokopediaDocument, options);
}
export type DeleteTokopediaMutationHookResult = ReturnType<
  typeof useDeleteTokopediaMutation
>;
export type DeleteTokopediaMutationResult =
  Apollo.MutationResult<DeleteTokopediaMutation>;
export type DeleteTokopediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteTokopediaMutation,
  DeleteTokopediaMutationVariables
>;
export const GetShopeeCredentialsDocument = gql`
  query getShopeeCredentials($input: ShopeeCredentialsInput!) {
    getShopeeCredentials(input: $input) {
      url
      urlChannel
      type
    }
  }
`;

/**
 * __useGetShopeeCredentialsQuery__
 *
 * To run a query within a React component, call `useGetShopeeCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopeeCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopeeCredentialsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShopeeCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopeeCredentialsQuery,
    GetShopeeCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShopeeCredentialsQuery,
    GetShopeeCredentialsQueryVariables
  >(GetShopeeCredentialsDocument, options);
}
export function useGetShopeeCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopeeCredentialsQuery,
    GetShopeeCredentialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShopeeCredentialsQuery,
    GetShopeeCredentialsQueryVariables
  >(GetShopeeCredentialsDocument, options);
}
export type GetShopeeCredentialsQueryHookResult = ReturnType<
  typeof useGetShopeeCredentialsQuery
>;
export type GetShopeeCredentialsLazyQueryHookResult = ReturnType<
  typeof useGetShopeeCredentialsLazyQuery
>;
export type GetShopeeCredentialsQueryResult = Apollo.QueryResult<
  GetShopeeCredentialsQuery,
  GetShopeeCredentialsQueryVariables
>;
export const ReconnectTokopediaDocument = gql`
  mutation reconnectTokopedia($channelId: Int!) {
    reconnectTokopedia(channelID: $channelId)
  }
`;
export type ReconnectTokopediaMutationFn = Apollo.MutationFunction<
  ReconnectTokopediaMutation,
  ReconnectTokopediaMutationVariables
>;

/**
 * __useReconnectTokopediaMutation__
 *
 * To run a mutation, you first call `useReconnectTokopediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectTokopediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectTokopediaMutation, { data, loading, error }] = useReconnectTokopediaMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useReconnectTokopediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconnectTokopediaMutation,
    ReconnectTokopediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReconnectTokopediaMutation,
    ReconnectTokopediaMutationVariables
  >(ReconnectTokopediaDocument, options);
}
export type ReconnectTokopediaMutationHookResult = ReturnType<
  typeof useReconnectTokopediaMutation
>;
export type ReconnectTokopediaMutationResult =
  Apollo.MutationResult<ReconnectTokopediaMutation>;
export type ReconnectTokopediaMutationOptions = Apollo.BaseMutationOptions<
  ReconnectTokopediaMutation,
  ReconnectTokopediaMutationVariables
>;
export const RegisterTokopediaDocument = gql`
  mutation registerTokopedia($input: TokopediaRegisterInput!) {
    registerTokopedia(input: $input)
  }
`;
export type RegisterTokopediaMutationFn = Apollo.MutationFunction<
  RegisterTokopediaMutation,
  RegisterTokopediaMutationVariables
>;

/**
 * __useRegisterTokopediaMutation__
 *
 * To run a mutation, you first call `useRegisterTokopediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTokopediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTokopediaMutation, { data, loading, error }] = useRegisterTokopediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterTokopediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterTokopediaMutation,
    RegisterTokopediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterTokopediaMutation,
    RegisterTokopediaMutationVariables
  >(RegisterTokopediaDocument, options);
}
export type RegisterTokopediaMutationHookResult = ReturnType<
  typeof useRegisterTokopediaMutation
>;
export type RegisterTokopediaMutationResult =
  Apollo.MutationResult<RegisterTokopediaMutation>;
export type RegisterTokopediaMutationOptions = Apollo.BaseMutationOptions<
  RegisterTokopediaMutation,
  RegisterTokopediaMutationVariables
>;
export const Chat_RequestMarketplaceIntegrationDocument = gql`
  mutation chat_requestMarketplaceIntegration(
    $input: RequestMarketplaceIntegrationInput!
  ) {
    requestMarketplaceIntegration(input: $input)
  }
`;
export type Chat_RequestMarketplaceIntegrationMutationFn =
  Apollo.MutationFunction<
    Chat_RequestMarketplaceIntegrationMutation,
    Chat_RequestMarketplaceIntegrationMutationVariables
  >;

/**
 * __useChat_RequestMarketplaceIntegrationMutation__
 *
 * To run a mutation, you first call `useChat_RequestMarketplaceIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChat_RequestMarketplaceIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRequestMarketplaceIntegrationMutation, { data, loading, error }] = useChat_RequestMarketplaceIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChat_RequestMarketplaceIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Chat_RequestMarketplaceIntegrationMutation,
    Chat_RequestMarketplaceIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Chat_RequestMarketplaceIntegrationMutation,
    Chat_RequestMarketplaceIntegrationMutationVariables
  >(Chat_RequestMarketplaceIntegrationDocument, options);
}
export type Chat_RequestMarketplaceIntegrationMutationHookResult = ReturnType<
  typeof useChat_RequestMarketplaceIntegrationMutation
>;
export type Chat_RequestMarketplaceIntegrationMutationResult =
  Apollo.MutationResult<Chat_RequestMarketplaceIntegrationMutation>;
export type Chat_RequestMarketplaceIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    Chat_RequestMarketplaceIntegrationMutation,
    Chat_RequestMarketplaceIntegrationMutationVariables
  >;
export const SyncConnectedChannelsDocument = gql`
  mutation syncConnectedChannels($input: ConnectedChannelInput!) {
    syncConnectedChannels(input: $input)
  }
`;
export type SyncConnectedChannelsMutationFn = Apollo.MutationFunction<
  SyncConnectedChannelsMutation,
  SyncConnectedChannelsMutationVariables
>;

/**
 * __useSyncConnectedChannelsMutation__
 *
 * To run a mutation, you first call `useSyncConnectedChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConnectedChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConnectedChannelsMutation, { data, loading, error }] = useSyncConnectedChannelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncConnectedChannelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncConnectedChannelsMutation,
    SyncConnectedChannelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncConnectedChannelsMutation,
    SyncConnectedChannelsMutationVariables
  >(SyncConnectedChannelsDocument, options);
}
export type SyncConnectedChannelsMutationHookResult = ReturnType<
  typeof useSyncConnectedChannelsMutation
>;
export type SyncConnectedChannelsMutationResult =
  Apollo.MutationResult<SyncConnectedChannelsMutation>;
export type SyncConnectedChannelsMutationOptions = Apollo.BaseMutationOptions<
  SyncConnectedChannelsMutation,
  SyncConnectedChannelsMutationVariables
>;
export const SyncTokopediaChannelsDocument = gql`
  mutation syncTokopediaChannels($tenantID: Int, $gateID: String) {
    syncTokopediaChannels(tenantID: $tenantID, gateID: $gateID)
  }
`;
export type SyncTokopediaChannelsMutationFn = Apollo.MutationFunction<
  SyncTokopediaChannelsMutation,
  SyncTokopediaChannelsMutationVariables
>;

/**
 * __useSyncTokopediaChannelsMutation__
 *
 * To run a mutation, you first call `useSyncTokopediaChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncTokopediaChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncTokopediaChannelsMutation, { data, loading, error }] = useSyncTokopediaChannelsMutation({
 *   variables: {
 *      tenantID: // value for 'tenantID'
 *      gateID: // value for 'gateID'
 *   },
 * });
 */
export function useSyncTokopediaChannelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncTokopediaChannelsMutation,
    SyncTokopediaChannelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncTokopediaChannelsMutation,
    SyncTokopediaChannelsMutationVariables
  >(SyncTokopediaChannelsDocument, options);
}
export type SyncTokopediaChannelsMutationHookResult = ReturnType<
  typeof useSyncTokopediaChannelsMutation
>;
export type SyncTokopediaChannelsMutationResult =
  Apollo.MutationResult<SyncTokopediaChannelsMutation>;
export type SyncTokopediaChannelsMutationOptions = Apollo.BaseMutationOptions<
  SyncTokopediaChannelsMutation,
  SyncTokopediaChannelsMutationVariables
>;
export const ChatBotDocument = gql`
  query ChatBot($tenantId: String!) {
    chatBot(tenantId: $tenantId) {
      tenantName
      enabled
    }
  }
`;

/**
 * __useChatBotQuery__
 *
 * To run a query within a React component, call `useChatBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatBotQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useChatBotQuery(
  baseOptions: Apollo.QueryHookOptions<ChatBotQuery, ChatBotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatBotQuery, ChatBotQueryVariables>(
    ChatBotDocument,
    options
  );
}
export function useChatBotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatBotQuery, ChatBotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatBotQuery, ChatBotQueryVariables>(
    ChatBotDocument,
    options
  );
}
export type ChatBotQueryHookResult = ReturnType<typeof useChatBotQuery>;
export type ChatBotLazyQueryHookResult = ReturnType<typeof useChatBotLazyQuery>;
export type ChatBotQueryResult = Apollo.QueryResult<
  ChatBotQuery,
  ChatBotQueryVariables
>;
export const ChatBotStatusDocument = gql`
  mutation ChatBotStatus($tenantID: String!, $status: Boolean!) {
    chatBotStatus(tenantID: $tenantID, status: $status)
  }
`;
export type ChatBotStatusMutationFn = Apollo.MutationFunction<
  ChatBotStatusMutation,
  ChatBotStatusMutationVariables
>;

/**
 * __useChatBotStatusMutation__
 *
 * To run a mutation, you first call `useChatBotStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatBotStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatBotStatusMutation, { data, loading, error }] = useChatBotStatusMutation({
 *   variables: {
 *      tenantID: // value for 'tenantID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChatBotStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatBotStatusMutation,
    ChatBotStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatBotStatusMutation,
    ChatBotStatusMutationVariables
  >(ChatBotStatusDocument, options);
}
export type ChatBotStatusMutationHookResult = ReturnType<
  typeof useChatBotStatusMutation
>;
export type ChatBotStatusMutationResult =
  Apollo.MutationResult<ChatBotStatusMutation>;
export type ChatBotStatusMutationOptions = Apollo.BaseMutationOptions<
  ChatBotStatusMutation,
  ChatBotStatusMutationVariables
>;
export const GetMenuListDocument = gql`
  query GetMenuList($tenantId: String!) {
    getMenuList(tenantId: $tenantId) {
      id
      name
      text
      ParentID
      channelID
      tenantID
      talkToAgent
      endChat
      level
      type
      cloudMediaID
      menuOrder
      isParentWrapper
      category
    }
  }
`;

/**
 * __useGetMenuListQuery__
 *
 * To run a query within a React component, call `useGetMenuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuListQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetMenuListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMenuListQuery,
    GetMenuListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMenuListQuery, GetMenuListQueryVariables>(
    GetMenuListDocument,
    options
  );
}
export function useGetMenuListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMenuListQuery,
    GetMenuListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMenuListQuery, GetMenuListQueryVariables>(
    GetMenuListDocument,
    options
  );
}
export type GetMenuListQueryHookResult = ReturnType<typeof useGetMenuListQuery>;
export type GetMenuListLazyQueryHookResult = ReturnType<
  typeof useGetMenuListLazyQuery
>;
export type GetMenuListQueryResult = Apollo.QueryResult<
  GetMenuListQuery,
  GetMenuListQueryVariables
>;
export const MlcbOperationsDocument = gql`
  mutation MlcbOperations($input: MLCBMenuInput!) {
    mlcbOperations(input: $input)
  }
`;
export type MlcbOperationsMutationFn = Apollo.MutationFunction<
  MlcbOperationsMutation,
  MlcbOperationsMutationVariables
>;

/**
 * __useMlcbOperationsMutation__
 *
 * To run a mutation, you first call `useMlcbOperationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMlcbOperationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mlcbOperationsMutation, { data, loading, error }] = useMlcbOperationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMlcbOperationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MlcbOperationsMutation,
    MlcbOperationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MlcbOperationsMutation,
    MlcbOperationsMutationVariables
  >(MlcbOperationsDocument, options);
}
export type MlcbOperationsMutationHookResult = ReturnType<
  typeof useMlcbOperationsMutation
>;
export type MlcbOperationsMutationResult =
  Apollo.MutationResult<MlcbOperationsMutation>;
export type MlcbOperationsMutationOptions = Apollo.BaseMutationOptions<
  MlcbOperationsMutation,
  MlcbOperationsMutationVariables
>;
export const GetDistrictOptionsDocument = gql`
  query getDistrictOptions($query: String!, $limit: Int!) {
    getDistrictOptions(query: $query, limit: $limit) {
      districtOptions {
        key
        value
        provinceName
        cityName
        districtName
      }
    }
  }
`;

/**
 * __useGetDistrictOptionsQuery__
 *
 * To run a query within a React component, call `useGetDistrictOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictOptionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDistrictOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDistrictOptionsQuery,
    GetDistrictOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistrictOptionsQuery,
    GetDistrictOptionsQueryVariables
  >(GetDistrictOptionsDocument, options);
}
export function useGetDistrictOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistrictOptionsQuery,
    GetDistrictOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistrictOptionsQuery,
    GetDistrictOptionsQueryVariables
  >(GetDistrictOptionsDocument, options);
}
export type GetDistrictOptionsQueryHookResult = ReturnType<
  typeof useGetDistrictOptionsQuery
>;
export type GetDistrictOptionsLazyQueryHookResult = ReturnType<
  typeof useGetDistrictOptionsLazyQuery
>;
export type GetDistrictOptionsQueryResult = Apollo.QueryResult<
  GetDistrictOptionsQuery,
  GetDistrictOptionsQueryVariables
>;
export const TokopediaInvoiceDocument = gql`
  query tokopediaInvoice($input: TokopediaInvoiceInput!) {
    tokopediaInvoice(input: $input) {
      invoiceNumber
      invoiceUrl
      orderId
      orderStatus
      orderStatusCode
      totalAmount
      orderInfo {
        orderDetail {
          currencyId
          currencyRate
          insurancePrice
          isFreeReturns
          minOrder
          normalPrice
          productId
          productName
          productPicture
          productPrice
          quantity
          quantityDeliver
          quantityReject
          sku
          snapshotUrl
          subtotalPrice
          totalWeight
          weight
        }
      }
    }
  }
`;

/**
 * __useTokopediaInvoiceQuery__
 *
 * To run a query within a React component, call `useTokopediaInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokopediaInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokopediaInvoiceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokopediaInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokopediaInvoiceQuery,
    TokopediaInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokopediaInvoiceQuery, TokopediaInvoiceQueryVariables>(
    TokopediaInvoiceDocument,
    options
  );
}
export function useTokopediaInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokopediaInvoiceQuery,
    TokopediaInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TokopediaInvoiceQuery,
    TokopediaInvoiceQueryVariables
  >(TokopediaInvoiceDocument, options);
}
export type TokopediaInvoiceQueryHookResult = ReturnType<
  typeof useTokopediaInvoiceQuery
>;
export type TokopediaInvoiceLazyQueryHookResult = ReturnType<
  typeof useTokopediaInvoiceLazyQuery
>;
export type TokopediaInvoiceQueryResult = Apollo.QueryResult<
  TokopediaInvoiceQuery,
  TokopediaInvoiceQueryVariables
>;
export const ChatListDocument = gql`
  query chatList($input: ChatListInput!) {
    chatList(input: $input) {
      data {
        room {
          id
          name
          channelID
          lastMessageID
          lastMessageTimestamp
          labels {
            id
            title
            description
            color
            tenantID
            rooms {
              id
              name
              channelID
              agentID
              cUser {
                id
                name
                phoneNumber
              }
            }
          }
        }
        accountUsers {
          id
          accountID
          accountUserID
          name
          active
        }
        contactUser {
          id
          name
          dp
          phoneNumber
        }
        messages {
          id
          sender
          receiver
          type
          status
          message
          time
          mediaURL
          mimeType
          fileName
          interactive
        }
        unreadMsgCount
      }
      pageInfo {
        hasMore
        totalPages
      }
    }
  }
`;

/**
 * __useChatListQuery__
 *
 * To run a query within a React component, call `useChatListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatListQuery(
  baseOptions: Apollo.QueryHookOptions<ChatListQuery, ChatListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatListQuery, ChatListQueryVariables>(
    ChatListDocument,
    options
  );
}
export function useChatListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatListQuery,
    ChatListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatListQuery, ChatListQueryVariables>(
    ChatListDocument,
    options
  );
}
export type ChatListQueryHookResult = ReturnType<typeof useChatListQuery>;
export type ChatListLazyQueryHookResult = ReturnType<
  typeof useChatListLazyQuery
>;
export type ChatListQueryResult = Apollo.QueryResult<
  ChatListQuery,
  ChatListQueryVariables
>;
export const MessageListDocument = gql`
  query messageList($input: MessageListInput!) {
    messageList(input: $input) {
      room {
        id
        name
        channelID
        lastMessageTimestamp
        lastMessageID
      }
      accountUsers {
        id
        accountID
        accountUserID
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      messages {
        id
        sender
        receiver
        type
        status
        message
        time
        mediaURL
        mimeType
        fileName
        mediaSize
        sendByAgent
        senderName
        interactive
      }
      pageInfo {
        hasMore
        totalPages
      }
    }
  }
`;

/**
 * __useMessageListQuery__
 *
 * To run a query within a React component, call `useMessageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageListQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessageListQuery,
    MessageListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageListQuery, MessageListQueryVariables>(
    MessageListDocument,
    options
  );
}
export function useMessageListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageListQuery,
    MessageListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessageListQuery, MessageListQueryVariables>(
    MessageListDocument,
    options
  );
}
export type MessageListQueryHookResult = ReturnType<typeof useMessageListQuery>;
export type MessageListLazyQueryHookResult = ReturnType<
  typeof useMessageListLazyQuery
>;
export type MessageListQueryResult = Apollo.QueryResult<
  MessageListQuery,
  MessageListQueryVariables
>;
export const FetchMessageByIdDocument = gql`
  query FetchMessageByID($input: FetchMessageInput!) {
    fetchMessageByID(input: $input) {
      messages {
        id
        sender
        receiver
        type
        status
        message
        time
        mediaURL
        sendByAgent
        mimeType
        fileName
        mediaSize
        sendByAgent
        senderName
        interactive
      }
      room {
        id
        name
        channelID
        lastMessageTimestamp
        lastMessageID
      }
      accountUsers {
        id
        accountID
        accountUserID
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      pageInfo {
        hasMore
        totalPages
      }
    }
  }
`;

/**
 * __useFetchMessageByIdQuery__
 *
 * To run a query within a React component, call `useFetchMessageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchMessageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMessageByIdQuery,
    FetchMessageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMessageByIdQuery, FetchMessageByIdQueryVariables>(
    FetchMessageByIdDocument,
    options
  );
}
export function useFetchMessageByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMessageByIdQuery,
    FetchMessageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchMessageByIdQuery,
    FetchMessageByIdQueryVariables
  >(FetchMessageByIdDocument, options);
}
export type FetchMessageByIdQueryHookResult = ReturnType<
  typeof useFetchMessageByIdQuery
>;
export type FetchMessageByIdLazyQueryHookResult = ReturnType<
  typeof useFetchMessageByIdLazyQuery
>;
export type FetchMessageByIdQueryResult = Apollo.QueryResult<
  FetchMessageByIdQuery,
  FetchMessageByIdQueryVariables
>;
export const GetUploadUrlDocument = gql`
  query getUploadUrl($input: uploadFile) {
    getUploadUrl(input: $input) {
      url
      mediaName
    }
  }
`;

/**
 * __useGetUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUploadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUploadUrlQuery,
    GetUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument,
    options
  );
}
export function useGetUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUploadUrlQuery,
    GetUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(
    GetUploadUrlDocument,
    options
  );
}
export type GetUploadUrlQueryHookResult = ReturnType<
  typeof useGetUploadUrlQuery
>;
export type GetUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadUrlLazyQuery
>;
export type GetUploadUrlQueryResult = Apollo.QueryResult<
  GetUploadUrlQuery,
  GetUploadUrlQueryVariables
>;
export const SearchMessageDocument = gql`
  query SearchMessage($input: SearchMessageInput!) {
    searchMessage(input: $input) {
      data {
        room {
          id
          name
          channelID
          lastMessageID
          lastMessageTimestamp
          labels {
            id
            title
            description
            color
            tenantID
            rooms {
              id
              name
              channelID
              agentID
              cUser {
                id
                name
                phoneNumber
              }
            }
          }
        }
        accountUsers {
          id
          accountID
          accountUserID
          name
          active
        }
        contactUser {
          id
          name
          dp
          phoneNumber
        }
        messages {
          id
          sender
          receiver
          type
          status
          message
          time
          interactive
        }
        unreadMsgCount
      }
      pageInfo {
        hasMore
        totalPages
      }
    }
  }
`;

/**
 * __useSearchMessageQuery__
 *
 * To run a query within a React component, call `useSearchMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMessageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMessageQuery,
    SearchMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchMessageQuery, SearchMessageQueryVariables>(
    SearchMessageDocument,
    options
  );
}
export function useSearchMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMessageQuery,
    SearchMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchMessageQuery, SearchMessageQueryVariables>(
    SearchMessageDocument,
    options
  );
}
export type SearchMessageQueryHookResult = ReturnType<
  typeof useSearchMessageQuery
>;
export type SearchMessageLazyQueryHookResult = ReturnType<
  typeof useSearchMessageLazyQuery
>;
export type SearchMessageQueryResult = Apollo.QueryResult<
  SearchMessageQuery,
  SearchMessageQueryVariables
>;
export const OnboardingDocument = gql`
  query onboarding($input: OnboardingInput!) {
    onboarding(input: $input) {
      tenantID
      onboardingType
      stepIndex
      isFinished
    }
  }
`;

/**
 * __useOnboardingQuery__
 *
 * To run a query within a React component, call `useOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingQuery,
    OnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingQuery, OnboardingQueryVariables>(
    OnboardingDocument,
    options
  );
}
export function useOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingQuery,
    OnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingQuery, OnboardingQueryVariables>(
    OnboardingDocument,
    options
  );
}
export type OnboardingQueryHookResult = ReturnType<typeof useOnboardingQuery>;
export type OnboardingLazyQueryHookResult = ReturnType<
  typeof useOnboardingLazyQuery
>;
export type OnboardingQueryResult = Apollo.QueryResult<
  OnboardingQuery,
  OnboardingQueryVariables
>;
export const UpsertOnboardingDocument = gql`
  mutation upsertOnboarding($input: UpsertOnboardingInput!) {
    upsertOnboarding(input: $input) {
      tenantID
      onboardingType
      stepIndex
      isFinished
    }
  }
`;
export type UpsertOnboardingMutationFn = Apollo.MutationFunction<
  UpsertOnboardingMutation,
  UpsertOnboardingMutationVariables
>;

/**
 * __useUpsertOnboardingMutation__
 *
 * To run a mutation, you first call `useUpsertOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOnboardingMutation, { data, loading, error }] = useUpsertOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertOnboardingMutation,
    UpsertOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertOnboardingMutation,
    UpsertOnboardingMutationVariables
  >(UpsertOnboardingDocument, options);
}
export type UpsertOnboardingMutationHookResult = ReturnType<
  typeof useUpsertOnboardingMutation
>;
export type UpsertOnboardingMutationResult =
  Apollo.MutationResult<UpsertOnboardingMutation>;
export type UpsertOnboardingMutationOptions = Apollo.BaseMutationOptions<
  UpsertOnboardingMutation,
  UpsertOnboardingMutationVariables
>;
export const GetShopeeOrderDocument = gql`
  query getShopeeOrder($input: ShopeeOrderInput!) {
    getShopeeOrder(input: $input) {
      orderSn
      orderStatus
      cod
      createTime
      messageToSeller
      orderUrl
      totalAmount
      items {
        imageInfo {
          imageUrl
        }
      }
    }
  }
`;

/**
 * __useGetShopeeOrderQuery__
 *
 * To run a query within a React component, call `useGetShopeeOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopeeOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopeeOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShopeeOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopeeOrderQuery,
    GetShopeeOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShopeeOrderQuery, GetShopeeOrderQueryVariables>(
    GetShopeeOrderDocument,
    options
  );
}
export function useGetShopeeOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopeeOrderQuery,
    GetShopeeOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShopeeOrderQuery, GetShopeeOrderQueryVariables>(
    GetShopeeOrderDocument,
    options
  );
}
export type GetShopeeOrderQueryHookResult = ReturnType<
  typeof useGetShopeeOrderQuery
>;
export type GetShopeeOrderLazyQueryHookResult = ReturnType<
  typeof useGetShopeeOrderLazyQuery
>;
export type GetShopeeOrderQueryResult = Apollo.QueryResult<
  GetShopeeOrderQuery,
  GetShopeeOrderQueryVariables
>;
export const GetTenantPlanDetailDocument = gql`
  query getTenantPlanDetail {
    getTenantPlanDetail {
      planType
      planName
    }
  }
`;

/**
 * __useGetTenantPlanDetailQuery__
 *
 * To run a query within a React component, call `useGetTenantPlanDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantPlanDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantPlanDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantPlanDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenantPlanDetailQuery,
    GetTenantPlanDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTenantPlanDetailQuery,
    GetTenantPlanDetailQueryVariables
  >(GetTenantPlanDetailDocument, options);
}
export function useGetTenantPlanDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantPlanDetailQuery,
    GetTenantPlanDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTenantPlanDetailQuery,
    GetTenantPlanDetailQueryVariables
  >(GetTenantPlanDetailDocument, options);
}
export type GetTenantPlanDetailQueryHookResult = ReturnType<
  typeof useGetTenantPlanDetailQuery
>;
export type GetTenantPlanDetailLazyQueryHookResult = ReturnType<
  typeof useGetTenantPlanDetailLazyQuery
>;
export type GetTenantPlanDetailQueryResult = Apollo.QueryResult<
  GetTenantPlanDetailQuery,
  GetTenantPlanDetailQueryVariables
>;
export const GetShopeeProductDocument = gql`
  query getShopeeProduct($input: ShopeeProductInput!) {
    getShopeeProduct(input: $input) {
      itemName
      itemSku
      itemStatus
      brandName
      customerProductUrl
      productUrl
      imageUrl
      price {
        min {
          currentPrice
          currency
          originalPrice
        }
        max {
          currentPrice
          currency
          originalPrice
        }
      }
    }
  }
`;

/**
 * __useGetShopeeProductQuery__
 *
 * To run a query within a React component, call `useGetShopeeProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopeeProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopeeProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShopeeProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShopeeProductQuery,
    GetShopeeProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShopeeProductQuery, GetShopeeProductQueryVariables>(
    GetShopeeProductDocument,
    options
  );
}
export function useGetShopeeProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShopeeProductQuery,
    GetShopeeProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShopeeProductQuery,
    GetShopeeProductQueryVariables
  >(GetShopeeProductDocument, options);
}
export type GetShopeeProductQueryHookResult = ReturnType<
  typeof useGetShopeeProductQuery
>;
export type GetShopeeProductLazyQueryHookResult = ReturnType<
  typeof useGetShopeeProductLazyQuery
>;
export type GetShopeeProductQueryResult = Apollo.QueryResult<
  GetShopeeProductQuery,
  GetShopeeProductQueryVariables
>;
export const TokopediaProductDocument = gql`
  query tokopediaProduct($input: TokopediaProductInput!) {
    tokopediaProduct(input: $input) {
      basic {
        name
      }
      price {
        value
        currency
        idr
      }
      pictures {
        originalUrl
        thumbnailUrl
      }
      stock {
        useStock
        value
      }
      other {
        mobileURL
        sku
        url
      }
    }
  }
`;

/**
 * __useTokopediaProductQuery__
 *
 * To run a query within a React component, call `useTokopediaProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokopediaProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokopediaProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokopediaProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    TokopediaProductQuery,
    TokopediaProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokopediaProductQuery, TokopediaProductQueryVariables>(
    TokopediaProductDocument,
    options
  );
}
export function useTokopediaProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokopediaProductQuery,
    TokopediaProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TokopediaProductQuery,
    TokopediaProductQueryVariables
  >(TokopediaProductDocument, options);
}
export type TokopediaProductQueryHookResult = ReturnType<
  typeof useTokopediaProductQuery
>;
export type TokopediaProductLazyQueryHookResult = ReturnType<
  typeof useTokopediaProductLazyQuery
>;
export type TokopediaProductQueryResult = Apollo.QueryResult<
  TokopediaProductQuery,
  TokopediaProductQueryVariables
>;
export const RefreshRoomMessagesDocument = gql`
  mutation refreshRoomMessages($id: ID!) {
    refreshRoomMessages(id: $id)
  }
`;
export type RefreshRoomMessagesMutationFn = Apollo.MutationFunction<
  RefreshRoomMessagesMutation,
  RefreshRoomMessagesMutationVariables
>;

/**
 * __useRefreshRoomMessagesMutation__
 *
 * To run a mutation, you first call `useRefreshRoomMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshRoomMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshRoomMessagesMutation, { data, loading, error }] = useRefreshRoomMessagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshRoomMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshRoomMessagesMutation,
    RefreshRoomMessagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshRoomMessagesMutation,
    RefreshRoomMessagesMutationVariables
  >(RefreshRoomMessagesDocument, options);
}
export type RefreshRoomMessagesMutationHookResult = ReturnType<
  typeof useRefreshRoomMessagesMutation
>;
export type RefreshRoomMessagesMutationResult =
  Apollo.MutationResult<RefreshRoomMessagesMutation>;
export type RefreshRoomMessagesMutationOptions = Apollo.BaseMutationOptions<
  RefreshRoomMessagesMutation,
  RefreshRoomMessagesMutationVariables
>;
export const RoomDocument = gql`
  query room($ID: ID!) {
    room(id: $ID) {
      id
      status
      channel {
        id
        channelType
        channelName
      }
    }
  }
`;

/**
 * __useRoomQuery__
 *
 * To run a query within a React component, call `useRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRoomQuery(
  baseOptions: Apollo.QueryHookOptions<RoomQuery, RoomQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoomQuery, RoomQueryVariables>(RoomDocument, options);
}
export function useRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoomQuery, RoomQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoomQuery, RoomQueryVariables>(
    RoomDocument,
    options
  );
}
export type RoomQueryHookResult = ReturnType<typeof useRoomQuery>;
export type RoomLazyQueryHookResult = ReturnType<typeof useRoomLazyQuery>;
export type RoomQueryResult = Apollo.QueryResult<RoomQuery, RoomQueryVariables>;
export const GetRoomCountDocument = gql`
  query getRoomCount($filter: ChatFilter!) {
    getRoomCount(filter: $filter) {
      roomCount
      roomGroupIdentifier
    }
  }
`;

/**
 * __useGetRoomCountQuery__
 *
 * To run a query within a React component, call `useGetRoomCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRoomCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomCountQuery,
    GetRoomCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomCountQuery, GetRoomCountQueryVariables>(
    GetRoomCountDocument,
    options
  );
}
export function useGetRoomCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomCountQuery,
    GetRoomCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoomCountQuery, GetRoomCountQueryVariables>(
    GetRoomCountDocument,
    options
  );
}
export type GetRoomCountQueryHookResult = ReturnType<
  typeof useGetRoomCountQuery
>;
export type GetRoomCountLazyQueryHookResult = ReturnType<
  typeof useGetRoomCountLazyQuery
>;
export type GetRoomCountQueryResult = Apollo.QueryResult<
  GetRoomCountQuery,
  GetRoomCountQueryVariables
>;
export const SearchRoomsDocument = gql`
  query searchRooms($input: SearchRoomsFilter!) {
    searchRooms(input: $input) {
      data {
        room {
          id
          activeAccountUserId
          contactUserId
          roomName
          updatedAt
          createdAt
          status
          claimedAt
          currentChannelId
          currentSessionId
          lastClosedAt
          tenantId
          channelRoomId
          contact {
            id
            channelId
            name
            address
            details
            number
            channelContactId
            isOptIn
            createdAt
          }
          labels {
            id
            title
            description
            color
            tenantID
          }
        }
        thumbnailMessage {
          id
          sender
          receiver
          type
          status
          sentAt
          message
          mediaUrl
          mimeType
          fileName
        }
        unreadMessages
      }
      pageInfo {
        hasMore
        totalPages
      }
    }
  }
`;

/**
 * __useSearchRoomsQuery__
 *
 * To run a query within a React component, call `useSearchRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRoomsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchRoomsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchRoomsQuery,
    SearchRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchRoomsQuery, SearchRoomsQueryVariables>(
    SearchRoomsDocument,
    options
  );
}
export function useSearchRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchRoomsQuery,
    SearchRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchRoomsQuery, SearchRoomsQueryVariables>(
    SearchRoomsDocument,
    options
  );
}
export type SearchRoomsQueryHookResult = ReturnType<typeof useSearchRoomsQuery>;
export type SearchRoomsLazyQueryHookResult = ReturnType<
  typeof useSearchRoomsLazyQuery
>;
export type SearchRoomsQueryResult = Apollo.QueryResult<
  SearchRoomsQuery,
  SearchRoomsQueryVariables
>;
export const GetResumableUploadHandleDocument = gql`
  query getResumableUploadHandle($input: ResumbaleUploadHandleInput!) {
    getResumableUploadHandle(input: $input) {
      handleId
    }
  }
`;

/**
 * __useGetResumableUploadHandleQuery__
 *
 * To run a query within a React component, call `useGetResumableUploadHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResumableUploadHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResumableUploadHandleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetResumableUploadHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResumableUploadHandleQuery,
    GetResumableUploadHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetResumableUploadHandleQuery,
    GetResumableUploadHandleQueryVariables
  >(GetResumableUploadHandleDocument, options);
}
export function useGetResumableUploadHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResumableUploadHandleQuery,
    GetResumableUploadHandleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResumableUploadHandleQuery,
    GetResumableUploadHandleQueryVariables
  >(GetResumableUploadHandleDocument, options);
}
export type GetResumableUploadHandleQueryHookResult = ReturnType<
  typeof useGetResumableUploadHandleQuery
>;
export type GetResumableUploadHandleLazyQueryHookResult = ReturnType<
  typeof useGetResumableUploadHandleLazyQuery
>;
export type GetResumableUploadHandleQueryResult = Apollo.QueryResult<
  GetResumableUploadHandleQuery,
  GetResumableUploadHandleQueryVariables
>;
export const SyncWaMessageTemplatesDocument = gql`
  query syncWaMessageTemplates($channelID: String!) {
    syncWaMessageTemplates(channelID: $channelID) {
      success
    }
  }
`;

/**
 * __useSyncWaMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useSyncWaMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncWaMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncWaMessageTemplatesQuery({
 *   variables: {
 *      channelID: // value for 'channelID'
 *   },
 * });
 */
export function useSyncWaMessageTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyncWaMessageTemplatesQuery,
    SyncWaMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SyncWaMessageTemplatesQuery,
    SyncWaMessageTemplatesQueryVariables
  >(SyncWaMessageTemplatesDocument, options);
}
export function useSyncWaMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyncWaMessageTemplatesQuery,
    SyncWaMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SyncWaMessageTemplatesQuery,
    SyncWaMessageTemplatesQueryVariables
  >(SyncWaMessageTemplatesDocument, options);
}
export type SyncWaMessageTemplatesQueryHookResult = ReturnType<
  typeof useSyncWaMessageTemplatesQuery
>;
export type SyncWaMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useSyncWaMessageTemplatesLazyQuery
>;
export type SyncWaMessageTemplatesQueryResult = Apollo.QueryResult<
  SyncWaMessageTemplatesQuery,
  SyncWaMessageTemplatesQueryVariables
>;
export const UserStatusDocument = gql`
  mutation userStatus($userId: String!, $status: Boolean!) {
    userStatus(userId: $userId, active: $status)
  }
`;
export type UserStatusMutationFn = Apollo.MutationFunction<
  UserStatusMutation,
  UserStatusMutationVariables
>;

/**
 * __useUserStatusMutation__
 *
 * To run a mutation, you first call `useUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStatusMutation, { data, loading, error }] = useUserStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserStatusMutation,
    UserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserStatusMutation, UserStatusMutationVariables>(
    UserStatusDocument,
    options
  );
}
export type UserStatusMutationHookResult = ReturnType<
  typeof useUserStatusMutation
>;
export type UserStatusMutationResult =
  Apollo.MutationResult<UserStatusMutation>;
export type UserStatusMutationOptions = Apollo.BaseMutationOptions<
  UserStatusMutation,
  UserStatusMutationVariables
>;
export const ChatCreateBroadcastMessageDocument = gql`
  mutation chatCreateBroadcastMessage(
    $input: ChatCreateBroadcastMessageInput!
  ) {
    chatCreateBroadcastMessage(input: $input) {
      success
    }
  }
`;
export type ChatCreateBroadcastMessageMutationFn = Apollo.MutationFunction<
  ChatCreateBroadcastMessageMutation,
  ChatCreateBroadcastMessageMutationVariables
>;

/**
 * __useChatCreateBroadcastMessageMutation__
 *
 * To run a mutation, you first call `useChatCreateBroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatCreateBroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatCreateBroadcastMessageMutation, { data, loading, error }] = useChatCreateBroadcastMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCreateBroadcastMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatCreateBroadcastMessageMutation,
    ChatCreateBroadcastMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatCreateBroadcastMessageMutation,
    ChatCreateBroadcastMessageMutationVariables
  >(ChatCreateBroadcastMessageDocument, options);
}
export type ChatCreateBroadcastMessageMutationHookResult = ReturnType<
  typeof useChatCreateBroadcastMessageMutation
>;
export type ChatCreateBroadcastMessageMutationResult =
  Apollo.MutationResult<ChatCreateBroadcastMessageMutation>;
export type ChatCreateBroadcastMessageMutationOptions =
  Apollo.BaseMutationOptions<
    ChatCreateBroadcastMessageMutation,
    ChatCreateBroadcastMessageMutationVariables
  >;
export const ChatImportBroadcastContactsDocument = gql`
  mutation chatImportBroadcastContacts(
    $input: ChatImportBroadcastContactsInput!
  ) {
    chatImportBroadcastContacts(input: $input) {
      success
      contacts {
        id
        channelID
        name
        phoneNumber
        channelContactID
        isOptIn
      }
    }
  }
`;
export type ChatImportBroadcastContactsMutationFn = Apollo.MutationFunction<
  ChatImportBroadcastContactsMutation,
  ChatImportBroadcastContactsMutationVariables
>;

/**
 * __useChatImportBroadcastContactsMutation__
 *
 * To run a mutation, you first call `useChatImportBroadcastContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatImportBroadcastContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatImportBroadcastContactsMutation, { data, loading, error }] = useChatImportBroadcastContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatImportBroadcastContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatImportBroadcastContactsMutation,
    ChatImportBroadcastContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatImportBroadcastContactsMutation,
    ChatImportBroadcastContactsMutationVariables
  >(ChatImportBroadcastContactsDocument, options);
}
export type ChatImportBroadcastContactsMutationHookResult = ReturnType<
  typeof useChatImportBroadcastContactsMutation
>;
export type ChatImportBroadcastContactsMutationResult =
  Apollo.MutationResult<ChatImportBroadcastContactsMutation>;
export type ChatImportBroadcastContactsMutationOptions =
  Apollo.BaseMutationOptions<
    ChatImportBroadcastContactsMutation,
    ChatImportBroadcastContactsMutationVariables
  >;
export const ChatCancelBroadcastMessageDocument = gql`
  mutation chatCancelBroadcastMessage($broadcastID: String!, $channelID: Int!) {
    chatCancelBroadcastMessage(
      broadcastID: $broadcastID
      channelID: $channelID
    ) {
      success
    }
  }
`;
export type ChatCancelBroadcastMessageMutationFn = Apollo.MutationFunction<
  ChatCancelBroadcastMessageMutation,
  ChatCancelBroadcastMessageMutationVariables
>;

/**
 * __useChatCancelBroadcastMessageMutation__
 *
 * To run a mutation, you first call `useChatCancelBroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatCancelBroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatCancelBroadcastMessageMutation, { data, loading, error }] = useChatCancelBroadcastMessageMutation({
 *   variables: {
 *      broadcastID: // value for 'broadcastID'
 *      channelID: // value for 'channelID'
 *   },
 * });
 */
export function useChatCancelBroadcastMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatCancelBroadcastMessageMutation,
    ChatCancelBroadcastMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatCancelBroadcastMessageMutation,
    ChatCancelBroadcastMessageMutationVariables
  >(ChatCancelBroadcastMessageDocument, options);
}
export type ChatCancelBroadcastMessageMutationHookResult = ReturnType<
  typeof useChatCancelBroadcastMessageMutation
>;
export type ChatCancelBroadcastMessageMutationResult =
  Apollo.MutationResult<ChatCancelBroadcastMessageMutation>;
export type ChatCancelBroadcastMessageMutationOptions =
  Apollo.BaseMutationOptions<
    ChatCancelBroadcastMessageMutation,
    ChatCancelBroadcastMessageMutationVariables
  >;
export const ChatRebroadcastMessageDocument = gql`
  mutation chatRebroadcastMessage($input: ChatRebroadcastMessageInput!) {
    chatRebroadcastMessage(input: $input) {
      success
    }
  }
`;
export type ChatRebroadcastMessageMutationFn = Apollo.MutationFunction<
  ChatRebroadcastMessageMutation,
  ChatRebroadcastMessageMutationVariables
>;

/**
 * __useChatRebroadcastMessageMutation__
 *
 * To run a mutation, you first call `useChatRebroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatRebroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRebroadcastMessageMutation, { data, loading, error }] = useChatRebroadcastMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatRebroadcastMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChatRebroadcastMessageMutation,
    ChatRebroadcastMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChatRebroadcastMessageMutation,
    ChatRebroadcastMessageMutationVariables
  >(ChatRebroadcastMessageDocument, options);
}
export type ChatRebroadcastMessageMutationHookResult = ReturnType<
  typeof useChatRebroadcastMessageMutation
>;
export type ChatRebroadcastMessageMutationResult =
  Apollo.MutationResult<ChatRebroadcastMessageMutation>;
export type ChatRebroadcastMessageMutationOptions = Apollo.BaseMutationOptions<
  ChatRebroadcastMessageMutation,
  ChatRebroadcastMessageMutationVariables
>;
export const UpdateChannelStatusDocument = gql`
  mutation UpdateChannelStatus($channelID: String!, $status: Boolean!) {
    updateChannelStatus(channelID: $channelID, status: $status)
  }
`;
export type UpdateChannelStatusMutationFn = Apollo.MutationFunction<
  UpdateChannelStatusMutation,
  UpdateChannelStatusMutationVariables
>;

/**
 * __useUpdateChannelStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChannelStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelStatusMutation, { data, loading, error }] = useUpdateChannelStatusMutation({
 *   variables: {
 *      channelID: // value for 'channelID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateChannelStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelStatusMutation,
    UpdateChannelStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelStatusMutation,
    UpdateChannelStatusMutationVariables
  >(UpdateChannelStatusDocument, options);
}
export type UpdateChannelStatusMutationHookResult = ReturnType<
  typeof useUpdateChannelStatusMutation
>;
export type UpdateChannelStatusMutationResult =
  Apollo.MutationResult<UpdateChannelStatusMutation>;
export type UpdateChannelStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelStatusMutation,
  UpdateChannelStatusMutationVariables
>;
export const UpdateAsReadDocument = gql`
  mutation UpdateAsRead($custID: String!, $roomID: String!) {
    updateRoomRead(custID: $custID, roomID: $roomID)
  }
`;
export type UpdateAsReadMutationFn = Apollo.MutationFunction<
  UpdateAsReadMutation,
  UpdateAsReadMutationVariables
>;

/**
 * __useUpdateAsReadMutation__
 *
 * To run a mutation, you first call `useUpdateAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAsReadMutation, { data, loading, error }] = useUpdateAsReadMutation({
 *   variables: {
 *      custID: // value for 'custID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useUpdateAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAsReadMutation,
    UpdateAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAsReadMutation,
    UpdateAsReadMutationVariables
  >(UpdateAsReadDocument, options);
}
export type UpdateAsReadMutationHookResult = ReturnType<
  typeof useUpdateAsReadMutation
>;
export type UpdateAsReadMutationResult =
  Apollo.MutationResult<UpdateAsReadMutation>;
export type UpdateAsReadMutationOptions = Apollo.BaseMutationOptions<
  UpdateAsReadMutation,
  UpdateAsReadMutationVariables
>;
export const CloseChatDocument = gql`
  mutation closeChat($roomID: String!) {
    closeChat(roomId: $roomID)
  }
`;
export type CloseChatMutationFn = Apollo.MutationFunction<
  CloseChatMutation,
  CloseChatMutationVariables
>;

/**
 * __useCloseChatMutation__
 *
 * To run a mutation, you first call `useCloseChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeChatMutation, { data, loading, error }] = useCloseChatMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useCloseChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseChatMutation,
    CloseChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseChatMutation, CloseChatMutationVariables>(
    CloseChatDocument,
    options
  );
}
export type CloseChatMutationHookResult = ReturnType<
  typeof useCloseChatMutation
>;
export type CloseChatMutationResult = Apollo.MutationResult<CloseChatMutation>;
export type CloseChatMutationOptions = Apollo.BaseMutationOptions<
  CloseChatMutation,
  CloseChatMutationVariables
>;
export const UpdateChannelDocument = gql`
  mutation UpdateChannel($input: updateChannelInput!) {
    updateChannel(input: $input)
  }
`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelMutation,
    UpdateChannelMutationVariables
  >(UpdateChannelDocument, options);
}
export type UpdateChannelMutationHookResult = ReturnType<
  typeof useUpdateChannelMutation
>;
export type UpdateChannelMutationResult =
  Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelMutation,
  UpdateChannelMutationVariables
>;
export const TransferChatDocument = gql`
  mutation transferChat($userID: String!, $roomID: String!) {
    transferChat(input: { userID: $userID, roomID: $roomID })
  }
`;
export type TransferChatMutationFn = Apollo.MutationFunction<
  TransferChatMutation,
  TransferChatMutationVariables
>;

/**
 * __useTransferChatMutation__
 *
 * To run a mutation, you first call `useTransferChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferChatMutation, { data, loading, error }] = useTransferChatMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useTransferChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferChatMutation,
    TransferChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferChatMutation,
    TransferChatMutationVariables
  >(TransferChatDocument, options);
}
export type TransferChatMutationHookResult = ReturnType<
  typeof useTransferChatMutation
>;
export type TransferChatMutationResult =
  Apollo.MutationResult<TransferChatMutation>;
export type TransferChatMutationOptions = Apollo.BaseMutationOptions<
  TransferChatMutation,
  TransferChatMutationVariables
>;
export const QueueChatDocument = gql`
  mutation queueChat($roomId: String!) {
    queueChat(roomId: $roomId)
  }
`;
export type QueueChatMutationFn = Apollo.MutationFunction<
  QueueChatMutation,
  QueueChatMutationVariables
>;

/**
 * __useQueueChatMutation__
 *
 * To run a mutation, you first call `useQueueChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueChatMutation, { data, loading, error }] = useQueueChatMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useQueueChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QueueChatMutation,
    QueueChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QueueChatMutation, QueueChatMutationVariables>(
    QueueChatDocument,
    options
  );
}
export type QueueChatMutationHookResult = ReturnType<
  typeof useQueueChatMutation
>;
export type QueueChatMutationResult = Apollo.MutationResult<QueueChatMutation>;
export type QueueChatMutationOptions = Apollo.BaseMutationOptions<
  QueueChatMutation,
  QueueChatMutationVariables
>;
export const UpsertLabelDocument = gql`
  mutation upsertLabel($input: UpsertLabelInput!) {
    upsertLabel(input: $input) {
      success
    }
  }
`;
export type UpsertLabelMutationFn = Apollo.MutationFunction<
  UpsertLabelMutation,
  UpsertLabelMutationVariables
>;

/**
 * __useUpsertLabelMutation__
 *
 * To run a mutation, you first call `useUpsertLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLabelMutation, { data, loading, error }] = useUpsertLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertLabelMutation,
    UpsertLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertLabelMutation, UpsertLabelMutationVariables>(
    UpsertLabelDocument,
    options
  );
}
export type UpsertLabelMutationHookResult = ReturnType<
  typeof useUpsertLabelMutation
>;
export type UpsertLabelMutationResult =
  Apollo.MutationResult<UpsertLabelMutation>;
export type UpsertLabelMutationOptions = Apollo.BaseMutationOptions<
  UpsertLabelMutation,
  UpsertLabelMutationVariables
>;
export const UpsertRoomLabelsDocument = gql`
  mutation upsertRoomLabels($input: UpsertRoomLabelsInput!) {
    upsertRoomLabels(input: $input) {
      success
    }
  }
`;
export type UpsertRoomLabelsMutationFn = Apollo.MutationFunction<
  UpsertRoomLabelsMutation,
  UpsertRoomLabelsMutationVariables
>;

/**
 * __useUpsertRoomLabelsMutation__
 *
 * To run a mutation, you first call `useUpsertRoomLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRoomLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRoomLabelsMutation, { data, loading, error }] = useUpsertRoomLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRoomLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertRoomLabelsMutation,
    UpsertRoomLabelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertRoomLabelsMutation,
    UpsertRoomLabelsMutationVariables
  >(UpsertRoomLabelsDocument, options);
}
export type UpsertRoomLabelsMutationHookResult = ReturnType<
  typeof useUpsertRoomLabelsMutation
>;
export type UpsertRoomLabelsMutationResult =
  Apollo.MutationResult<UpsertRoomLabelsMutation>;
export type UpsertRoomLabelsMutationOptions = Apollo.BaseMutationOptions<
  UpsertRoomLabelsMutation,
  UpsertRoomLabelsMutationVariables
>;
export const DeleteTenantLabelDocument = gql`
  mutation deleteTenantLabel($labelID: Int!) {
    deleteTenantLabel(labelID: $labelID) {
      success
    }
  }
`;
export type DeleteTenantLabelMutationFn = Apollo.MutationFunction<
  DeleteTenantLabelMutation,
  DeleteTenantLabelMutationVariables
>;

/**
 * __useDeleteTenantLabelMutation__
 *
 * To run a mutation, you first call `useDeleteTenantLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantLabelMutation, { data, loading, error }] = useDeleteTenantLabelMutation({
 *   variables: {
 *      labelID: // value for 'labelID'
 *   },
 * });
 */
export function useDeleteTenantLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTenantLabelMutation,
    DeleteTenantLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTenantLabelMutation,
    DeleteTenantLabelMutationVariables
  >(DeleteTenantLabelDocument, options);
}
export type DeleteTenantLabelMutationHookResult = ReturnType<
  typeof useDeleteTenantLabelMutation
>;
export type DeleteTenantLabelMutationResult =
  Apollo.MutationResult<DeleteTenantLabelMutation>;
export type DeleteTenantLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteTenantLabelMutation,
  DeleteTenantLabelMutationVariables
>;
export const CreateNewMessageDocument = gql`
  mutation CreateNewMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
      sender
      receiver
      message
      status
      type
      time
    }
  }
`;
export type CreateNewMessageMutationFn = Apollo.MutationFunction<
  CreateNewMessageMutation,
  CreateNewMessageMutationVariables
>;

/**
 * __useCreateNewMessageMutation__
 *
 * To run a mutation, you first call `useCreateNewMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMessageMutation, { data, loading, error }] = useCreateNewMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewMessageMutation,
    CreateNewMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNewMessageMutation,
    CreateNewMessageMutationVariables
  >(CreateNewMessageDocument, options);
}
export type CreateNewMessageMutationHookResult = ReturnType<
  typeof useCreateNewMessageMutation
>;
export type CreateNewMessageMutationResult =
  Apollo.MutationResult<CreateNewMessageMutation>;
export type CreateNewMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateNewMessageMutation,
  CreateNewMessageMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($input: SendMessageInput!) {
    uploadFile(input: $input) {
      url
      mediaName
      messageID
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const CreateOperationalHoursDocument = gql`
  mutation CreateOperationalHours($input: operationalHoursInput!) {
    createOperationalHours(input: $input)
  }
`;
export type CreateOperationalHoursMutationFn = Apollo.MutationFunction<
  CreateOperationalHoursMutation,
  CreateOperationalHoursMutationVariables
>;

/**
 * __useCreateOperationalHoursMutation__
 *
 * To run a mutation, you first call `useCreateOperationalHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationalHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationalHoursMutation, { data, loading, error }] = useCreateOperationalHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOperationalHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOperationalHoursMutation,
    CreateOperationalHoursMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOperationalHoursMutation,
    CreateOperationalHoursMutationVariables
  >(CreateOperationalHoursDocument, options);
}
export type CreateOperationalHoursMutationHookResult = ReturnType<
  typeof useCreateOperationalHoursMutation
>;
export type CreateOperationalHoursMutationResult =
  Apollo.MutationResult<CreateOperationalHoursMutation>;
export type CreateOperationalHoursMutationOptions = Apollo.BaseMutationOptions<
  CreateOperationalHoursMutation,
  CreateOperationalHoursMutationVariables
>;
export const CreateGreetingsDocument = gql`
  mutation CreateGreetings($input: greetingsInput!) {
    createGreetings(input: $input)
  }
`;
export type CreateGreetingsMutationFn = Apollo.MutationFunction<
  CreateGreetingsMutation,
  CreateGreetingsMutationVariables
>;

/**
 * __useCreateGreetingsMutation__
 *
 * To run a mutation, you first call `useCreateGreetingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGreetingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGreetingsMutation, { data, loading, error }] = useCreateGreetingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGreetingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGreetingsMutation,
    CreateGreetingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGreetingsMutation,
    CreateGreetingsMutationVariables
  >(CreateGreetingsDocument, options);
}
export type CreateGreetingsMutationHookResult = ReturnType<
  typeof useCreateGreetingsMutation
>;
export type CreateGreetingsMutationResult =
  Apollo.MutationResult<CreateGreetingsMutation>;
export type CreateGreetingsMutationOptions = Apollo.BaseMutationOptions<
  CreateGreetingsMutation,
  CreateGreetingsMutationVariables
>;
export const CreateQuickReplyDocument = gql`
  mutation CreateQuickReply($input: quickReplyInput!) {
    createQuickReply(input: $input)
  }
`;
export type CreateQuickReplyMutationFn = Apollo.MutationFunction<
  CreateQuickReplyMutation,
  CreateQuickReplyMutationVariables
>;

/**
 * __useCreateQuickReplyMutation__
 *
 * To run a mutation, you first call `useCreateQuickReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickReplyMutation, { data, loading, error }] = useCreateQuickReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuickReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuickReplyMutation,
    CreateQuickReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuickReplyMutation,
    CreateQuickReplyMutationVariables
  >(CreateQuickReplyDocument, options);
}
export type CreateQuickReplyMutationHookResult = ReturnType<
  typeof useCreateQuickReplyMutation
>;
export type CreateQuickReplyMutationResult =
  Apollo.MutationResult<CreateQuickReplyMutation>;
export type CreateQuickReplyMutationOptions = Apollo.BaseMutationOptions<
  CreateQuickReplyMutation,
  CreateQuickReplyMutationVariables
>;
export const UpdateQuickReplyDocument = gql`
  mutation UpdateQuickReply(
    $updateQuickReplyId: String!
    $input: quickReplyInput
  ) {
    updateQuickReply(id: $updateQuickReplyId, input: $input)
  }
`;
export type UpdateQuickReplyMutationFn = Apollo.MutationFunction<
  UpdateQuickReplyMutation,
  UpdateQuickReplyMutationVariables
>;

/**
 * __useUpdateQuickReplyMutation__
 *
 * To run a mutation, you first call `useUpdateQuickReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickReplyMutation, { data, loading, error }] = useUpdateQuickReplyMutation({
 *   variables: {
 *      updateQuickReplyId: // value for 'updateQuickReplyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuickReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuickReplyMutation,
    UpdateQuickReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateQuickReplyMutation,
    UpdateQuickReplyMutationVariables
  >(UpdateQuickReplyDocument, options);
}
export type UpdateQuickReplyMutationHookResult = ReturnType<
  typeof useUpdateQuickReplyMutation
>;
export type UpdateQuickReplyMutationResult =
  Apollo.MutationResult<UpdateQuickReplyMutation>;
export type UpdateQuickReplyMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuickReplyMutation,
  UpdateQuickReplyMutationVariables
>;
export const DeleteQuickReplyDocument = gql`
  mutation DeleteQuickReply($deleteQuickReplyId: String!) {
    deleteQuickReply(id: $deleteQuickReplyId)
  }
`;
export type DeleteQuickReplyMutationFn = Apollo.MutationFunction<
  DeleteQuickReplyMutation,
  DeleteQuickReplyMutationVariables
>;

/**
 * __useDeleteQuickReplyMutation__
 *
 * To run a mutation, you first call `useDeleteQuickReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickReplyMutation, { data, loading, error }] = useDeleteQuickReplyMutation({
 *   variables: {
 *      deleteQuickReplyId: // value for 'deleteQuickReplyId'
 *   },
 * });
 */
export function useDeleteQuickReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQuickReplyMutation,
    DeleteQuickReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteQuickReplyMutation,
    DeleteQuickReplyMutationVariables
  >(DeleteQuickReplyDocument, options);
}
export type DeleteQuickReplyMutationHookResult = ReturnType<
  typeof useDeleteQuickReplyMutation
>;
export type DeleteQuickReplyMutationResult =
  Apollo.MutationResult<DeleteQuickReplyMutation>;
export type DeleteQuickReplyMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuickReplyMutation,
  DeleteQuickReplyMutationVariables
>;
export const CreateMessageTemplateDocument = gql`
  mutation CreateMessageTemplate($input: addMessageTemplateInput!) {
    addMessageTemplate(input: $input)
  }
`;
export type CreateMessageTemplateMutationFn = Apollo.MutationFunction<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;

/**
 * __useCreateMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageTemplateMutation, { data, loading, error }] = useCreateMessageTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >(CreateMessageTemplateDocument, options);
}
export type CreateMessageTemplateMutationHookResult = ReturnType<
  typeof useCreateMessageTemplateMutation
>;
export type CreateMessageTemplateMutationResult =
  Apollo.MutationResult<CreateMessageTemplateMutation>;
export type CreateMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables
>;
export const UpdateMessageTemplateStatusDocument = gql`
  mutation UpdateMessageTemplateStatus(
    $templateId: String!
    $status: Boolean!
  ) {
    updateMessageTemplateStatus(templateID: $templateId, status: $status)
  }
`;
export type UpdateMessageTemplateStatusMutationFn = Apollo.MutationFunction<
  UpdateMessageTemplateStatusMutation,
  UpdateMessageTemplateStatusMutationVariables
>;

/**
 * __useUpdateMessageTemplateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMessageTemplateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageTemplateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageTemplateStatusMutation, { data, loading, error }] = useUpdateMessageTemplateStatusMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMessageTemplateStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageTemplateStatusMutation,
    UpdateMessageTemplateStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageTemplateStatusMutation,
    UpdateMessageTemplateStatusMutationVariables
  >(UpdateMessageTemplateStatusDocument, options);
}
export type UpdateMessageTemplateStatusMutationHookResult = ReturnType<
  typeof useUpdateMessageTemplateStatusMutation
>;
export type UpdateMessageTemplateStatusMutationResult =
  Apollo.MutationResult<UpdateMessageTemplateStatusMutation>;
export type UpdateMessageTemplateStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMessageTemplateStatusMutation,
    UpdateMessageTemplateStatusMutationVariables
  >;
export const DeleteMessageTemplateDocument = gql`
  mutation DeleteMessageTemplate($templateId: String!) {
    deleteMessageTemplate(templateID: $templateId)
  }
`;
export type DeleteMessageTemplateMutationFn = Apollo.MutationFunction<
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables
>;

/**
 * __useDeleteMessageTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageTemplateMutation, { data, loading, error }] = useDeleteMessageTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteMessageTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageTemplateMutation,
    DeleteMessageTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMessageTemplateMutation,
    DeleteMessageTemplateMutationVariables
  >(DeleteMessageTemplateDocument, options);
}
export type DeleteMessageTemplateMutationHookResult = ReturnType<
  typeof useDeleteMessageTemplateMutation
>;
export type DeleteMessageTemplateMutationResult =
  Apollo.MutationResult<DeleteMessageTemplateMutation>;
export type DeleteMessageTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables
>;
export const CreateWebhookDocument = gql`
  mutation CreateWebhook($input: webhook!) {
    createWebhook(input: $input)
  }
`;
export type CreateWebhookMutationFn = Apollo.MutationFunction<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >(CreateWebhookDocument, options);
}
export type CreateWebhookMutationHookResult = ReturnType<
  typeof useCreateWebhookMutation
>;
export type CreateWebhookMutationResult =
  Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>;
export const WebhookStatusDocument = gql`
  mutation WebhookStatus($id: String!, $status: Boolean!) {
    webhookStatus(id: $id, enabled: $status)
  }
`;
export type WebhookStatusMutationFn = Apollo.MutationFunction<
  WebhookStatusMutation,
  WebhookStatusMutationVariables
>;

/**
 * __useWebhookStatusMutation__
 *
 * To run a mutation, you first call `useWebhookStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebhookStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webhookStatusMutation, { data, loading, error }] = useWebhookStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useWebhookStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebhookStatusMutation,
    WebhookStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebhookStatusMutation,
    WebhookStatusMutationVariables
  >(WebhookStatusDocument, options);
}
export type WebhookStatusMutationHookResult = ReturnType<
  typeof useWebhookStatusMutation
>;
export type WebhookStatusMutationResult =
  Apollo.MutationResult<WebhookStatusMutation>;
export type WebhookStatusMutationOptions = Apollo.BaseMutationOptions<
  WebhookStatusMutation,
  WebhookStatusMutationVariables
>;
export const WebhookDeleteDocument = gql`
  mutation WebhookDelete($id: String!, $token: String!) {
    webhookDelete(id: $id, token: $token)
  }
`;
export type WebhookDeleteMutationFn = Apollo.MutationFunction<
  WebhookDeleteMutation,
  WebhookDeleteMutationVariables
>;

/**
 * __useWebhookDeleteMutation__
 *
 * To run a mutation, you first call `useWebhookDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebhookDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webhookDeleteMutation, { data, loading, error }] = useWebhookDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useWebhookDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebhookDeleteMutation,
    WebhookDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebhookDeleteMutation,
    WebhookDeleteMutationVariables
  >(WebhookDeleteDocument, options);
}
export type WebhookDeleteMutationHookResult = ReturnType<
  typeof useWebhookDeleteMutation
>;
export type WebhookDeleteMutationResult =
  Apollo.MutationResult<WebhookDeleteMutation>;
export type WebhookDeleteMutationOptions = Apollo.BaseMutationOptions<
  WebhookDeleteMutation,
  WebhookDeleteMutationVariables
>;
export const WebhooktTokenRefreshDocument = gql`
  mutation WebhooktTokenRefresh($id: String!, $token: String!) {
    webhookRefresh(id: $id, token: $token)
  }
`;
export type WebhooktTokenRefreshMutationFn = Apollo.MutationFunction<
  WebhooktTokenRefreshMutation,
  WebhooktTokenRefreshMutationVariables
>;

/**
 * __useWebhooktTokenRefreshMutation__
 *
 * To run a mutation, you first call `useWebhooktTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebhooktTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webhooktTokenRefreshMutation, { data, loading, error }] = useWebhooktTokenRefreshMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useWebhooktTokenRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebhooktTokenRefreshMutation,
    WebhooktTokenRefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebhooktTokenRefreshMutation,
    WebhooktTokenRefreshMutationVariables
  >(WebhooktTokenRefreshDocument, options);
}
export type WebhooktTokenRefreshMutationHookResult = ReturnType<
  typeof useWebhooktTokenRefreshMutation
>;
export type WebhooktTokenRefreshMutationResult =
  Apollo.MutationResult<WebhooktTokenRefreshMutation>;
export type WebhooktTokenRefreshMutationOptions = Apollo.BaseMutationOptions<
  WebhooktTokenRefreshMutation,
  WebhooktTokenRefreshMutationVariables
>;
export const UpdateRoleDocument = gql`
  query updateRole($userID: String!, $role: String!) {
    updateRole(input: { userID: $userID, role: $role })
  }
`;

/**
 * __useUpdateRoleQuery__
 *
 * To run a query within a React component, call `useUpdateRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateRoleQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateRoleQuery,
    UpdateRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateRoleQuery, UpdateRoleQueryVariables>(
    UpdateRoleDocument,
    options
  );
}
export function useUpdateRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateRoleQuery,
    UpdateRoleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdateRoleQuery, UpdateRoleQueryVariables>(
    UpdateRoleDocument,
    options
  );
}
export type UpdateRoleQueryHookResult = ReturnType<typeof useUpdateRoleQuery>;
export type UpdateRoleLazyQueryHookResult = ReturnType<
  typeof useUpdateRoleLazyQuery
>;
export type UpdateRoleQueryResult = Apollo.QueryResult<
  UpdateRoleQuery,
  UpdateRoleQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      email
      userID
      phone
      firstName
      lastName
      role
      active
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const AdminDocument = gql`
  query admin($tenantId: String!, $role: String) {
    admin(tenantID: $tenantId, role: $role) {
      email
      userID
      firstName
      lastName
      type
      phone
      role
      active
    }
  }
`;

/**
 * __useAdminQuery__
 *
 * To run a query within a React component, call `useAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAdminQuery(
  baseOptions: Apollo.QueryHookOptions<AdminQuery, AdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminQuery, AdminQueryVariables>(
    AdminDocument,
    options
  );
}
export function useAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminQuery, AdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminQuery, AdminQueryVariables>(
    AdminDocument,
    options
  );
}
export type AdminQueryHookResult = ReturnType<typeof useAdminQuery>;
export type AdminLazyQueryHookResult = ReturnType<typeof useAdminLazyQuery>;
export type AdminQueryResult = Apollo.QueryResult<
  AdminQuery,
  AdminQueryVariables
>;
export const LinkChannelDocument = gql`
  query linkChannel($channelID: String!, $userID: String!, $tenant: String!) {
    linkChannel(
      input: { channelID: $channelID, userID: $userID, tenant: $tenant }
    )
  }
`;

/**
 * __useLinkChannelQuery__
 *
 * To run a query within a React component, call `useLinkChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkChannelQuery({
 *   variables: {
 *      channelID: // value for 'channelID'
 *      userID: // value for 'userID'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useLinkChannelQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkChannelQuery,
    LinkChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkChannelQuery, LinkChannelQueryVariables>(
    LinkChannelDocument,
    options
  );
}
export function useLinkChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkChannelQuery,
    LinkChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinkChannelQuery, LinkChannelQueryVariables>(
    LinkChannelDocument,
    options
  );
}
export type LinkChannelQueryHookResult = ReturnType<typeof useLinkChannelQuery>;
export type LinkChannelLazyQueryHookResult = ReturnType<
  typeof useLinkChannelLazyQuery
>;
export type LinkChannelQueryResult = Apollo.QueryResult<
  LinkChannelQuery,
  LinkChannelQueryVariables
>;
export const RemoveChannelLinkDocument = gql`
  query removeChannelLink(
    $channelID: String!
    $userID: String!
    $tenant: String!
  ) {
    removeChannelLink(
      input: { channelID: $channelID, userID: $userID, tenant: $tenant }
    )
  }
`;

/**
 * __useRemoveChannelLinkQuery__
 *
 * To run a query within a React component, call `useRemoveChannelLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoveChannelLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoveChannelLinkQuery({
 *   variables: {
 *      channelID: // value for 'channelID'
 *      userID: // value for 'userID'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useRemoveChannelLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemoveChannelLinkQuery,
    RemoveChannelLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RemoveChannelLinkQuery,
    RemoveChannelLinkQueryVariables
  >(RemoveChannelLinkDocument, options);
}
export function useRemoveChannelLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemoveChannelLinkQuery,
    RemoveChannelLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RemoveChannelLinkQuery,
    RemoveChannelLinkQueryVariables
  >(RemoveChannelLinkDocument, options);
}
export type RemoveChannelLinkQueryHookResult = ReturnType<
  typeof useRemoveChannelLinkQuery
>;
export type RemoveChannelLinkLazyQueryHookResult = ReturnType<
  typeof useRemoveChannelLinkLazyQuery
>;
export type RemoveChannelLinkQueryResult = Apollo.QueryResult<
  RemoveChannelLinkQuery,
  RemoveChannelLinkQueryVariables
>;
export const LoginUrlDocument = gql`
  query LoginUrl($input: LoginUrlInput!) {
    loginUrl(input: $input) {
      redirectUrl
      cc
    }
  }
`;

/**
 * __useLoginUrlQuery__
 *
 * To run a query within a React component, call `useLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUrlQuery(
  baseOptions: Apollo.QueryHookOptions<LoginUrlQuery, LoginUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginUrlQuery, LoginUrlQueryVariables>(
    LoginUrlDocument,
    options
  );
}
export function useLoginUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoginUrlQuery,
    LoginUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginUrlQuery, LoginUrlQueryVariables>(
    LoginUrlDocument,
    options
  );
}
export type LoginUrlQueryHookResult = ReturnType<typeof useLoginUrlQuery>;
export type LoginUrlLazyQueryHookResult = ReturnType<
  typeof useLoginUrlLazyQuery
>;
export type LoginUrlQueryResult = Apollo.QueryResult<
  LoginUrlQuery,
  LoginUrlQueryVariables
>;
export const AccessTokenDocument = gql`
  query AccessToken($input: TokenRequest!) {
    token(input: $input) {
      accessToken
      expiresIn
      scope
      tokenType
      userName
      id
      role
      TenantID
      fullName
      clientType
    }
  }
`;

/**
 * __useAccessTokenQuery__
 *
 * To run a query within a React component, call `useAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessTokenQuery,
    AccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(
    AccessTokenDocument,
    options
  );
}
export function useAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessTokenQuery,
    AccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessTokenQuery, AccessTokenQueryVariables>(
    AccessTokenDocument,
    options
  );
}
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<
  typeof useAccessTokenLazyQuery
>;
export type AccessTokenQueryResult = Apollo.QueryResult<
  AccessTokenQuery,
  AccessTokenQueryVariables
>;
export const ChatBroadcastsDocument = gql`
  query chatBroadcasts($params: ChatBroadcastsInput!) {
    chatBroadcasts(params: $params) {
      cursorAfter
      cursorBefore
      broadcastMessages {
        id
        channelID
        broadcastTitle
        broadcastStatus
        templateName
        sentAt
        taskID
        createdAt
        updatedAt
        templateComponents {
          type
          subType
          parameters {
            type
            text
            image {
              link
            }
            document {
              link
            }
            video {
              link
            }
            currency {
              fallbackValue
              code
              amount1000
            }
            dateTime {
              fallbackValue
              dayOfWeek
              year
              month
              dayOfMonth
              hour
              minute
              calendar
            }
          }
        }
      }
    }
  }
`;

/**
 * __useChatBroadcastsQuery__
 *
 * To run a query within a React component, call `useChatBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatBroadcastsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useChatBroadcastsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatBroadcastsQuery,
    ChatBroadcastsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatBroadcastsQuery, ChatBroadcastsQueryVariables>(
    ChatBroadcastsDocument,
    options
  );
}
export function useChatBroadcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatBroadcastsQuery,
    ChatBroadcastsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatBroadcastsQuery, ChatBroadcastsQueryVariables>(
    ChatBroadcastsDocument,
    options
  );
}
export type ChatBroadcastsQueryHookResult = ReturnType<
  typeof useChatBroadcastsQuery
>;
export type ChatBroadcastsLazyQueryHookResult = ReturnType<
  typeof useChatBroadcastsLazyQuery
>;
export type ChatBroadcastsQueryResult = Apollo.QueryResult<
  ChatBroadcastsQuery,
  ChatBroadcastsQueryVariables
>;
export const ChatBroadcastSubscribersDetailsDocument = gql`
  query chatBroadcastSubscribersDetails(
    $params: ChatBroadcastSubscribersDetailsInput!
  ) {
    chatBroadcastSubscribersDetails(params: $params) {
      cursorBefore
      cursorAfter
      viewSubscribers {
        id
        channelID
        broadcastID
        subscriberID
        messageID
        messageStatus
        messageTimestamp
        templateName
        broadcastTitle
        broadcastStatus
        sentAt
        taskID
        subscriberName
        subscriberPhone
        isOptIn
        subscriberCreatedAt
        subscriberUpdatedAt
      }
    }
  }
`;

/**
 * __useChatBroadcastSubscribersDetailsQuery__
 *
 * To run a query within a React component, call `useChatBroadcastSubscribersDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatBroadcastSubscribersDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatBroadcastSubscribersDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useChatBroadcastSubscribersDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatBroadcastSubscribersDetailsQuery,
    ChatBroadcastSubscribersDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatBroadcastSubscribersDetailsQuery,
    ChatBroadcastSubscribersDetailsQueryVariables
  >(ChatBroadcastSubscribersDetailsDocument, options);
}
export function useChatBroadcastSubscribersDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatBroadcastSubscribersDetailsQuery,
    ChatBroadcastSubscribersDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatBroadcastSubscribersDetailsQuery,
    ChatBroadcastSubscribersDetailsQueryVariables
  >(ChatBroadcastSubscribersDetailsDocument, options);
}
export type ChatBroadcastSubscribersDetailsQueryHookResult = ReturnType<
  typeof useChatBroadcastSubscribersDetailsQuery
>;
export type ChatBroadcastSubscribersDetailsLazyQueryHookResult = ReturnType<
  typeof useChatBroadcastSubscribersDetailsLazyQuery
>;
export type ChatBroadcastSubscribersDetailsQueryResult = Apollo.QueryResult<
  ChatBroadcastSubscribersDetailsQuery,
  ChatBroadcastSubscribersDetailsQueryVariables
>;
export const ChatSubscribersDocument = gql`
  query chatSubscribers($params: ChatSubscribersInput!) {
    chatSubscribers(params: $params) {
      cursorBefore
      cursorAfter
      subscribers {
        id
        channelID
        name
        phoneNumber
        isOptIn
      }
      pageInfo {
        itemsPerPage
        pageNumber
        totalItems
      }
    }
  }
`;

/**
 * __useChatSubscribersQuery__
 *
 * To run a query within a React component, call `useChatSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSubscribersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useChatSubscribersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatSubscribersQuery,
    ChatSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatSubscribersQuery, ChatSubscribersQueryVariables>(
    ChatSubscribersDocument,
    options
  );
}
export function useChatSubscribersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatSubscribersQuery,
    ChatSubscribersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatSubscribersQuery,
    ChatSubscribersQueryVariables
  >(ChatSubscribersDocument, options);
}
export type ChatSubscribersQueryHookResult = ReturnType<
  typeof useChatSubscribersQuery
>;
export type ChatSubscribersLazyQueryHookResult = ReturnType<
  typeof useChatSubscribersLazyQuery
>;
export type ChatSubscribersQueryResult = Apollo.QueryResult<
  ChatSubscribersQuery,
  ChatSubscribersQueryVariables
>;
export const ChatDetailBroadcastDocument = gql`
  query chatDetailBroadcast($params: ChatDetailBroadcastInput!) {
    chatDetailBroadcast(params: $params) {
      broadcastMessage {
        id
        channelID
        broadcastTitle
        broadcastStatus
        templateName
        sentAt
        taskID
        createdAt
        updatedAt
        templateComponents {
          type
          subType
          parameters {
            type
            text
            image {
              link
            }
            document {
              link
            }
            video {
              link
            }
            currency {
              fallbackValue
              code
              amount1000
            }
            dateTime {
              fallbackValue
              dayOfWeek
              year
              month
              dayOfMonth
              hour
              minute
              calendar
            }
          }
        }
      }
      previewTemplate {
        templateIDinDB
        templateID
        templateName
        templateBody
        templateLang
        templateCategory
        templateVariableCount
        templateType
        isHeader
        headerType
        cloudMediaID
        tenantID
        channelID
        templateStatus
        activeStatus
      }
    }
  }
`;

/**
 * __useChatDetailBroadcastQuery__
 *
 * To run a query within a React component, call `useChatDetailBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatDetailBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatDetailBroadcastQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useChatDetailBroadcastQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatDetailBroadcastQuery,
    ChatDetailBroadcastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatDetailBroadcastQuery,
    ChatDetailBroadcastQueryVariables
  >(ChatDetailBroadcastDocument, options);
}
export function useChatDetailBroadcastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatDetailBroadcastQuery,
    ChatDetailBroadcastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatDetailBroadcastQuery,
    ChatDetailBroadcastQueryVariables
  >(ChatDetailBroadcastDocument, options);
}
export type ChatDetailBroadcastQueryHookResult = ReturnType<
  typeof useChatDetailBroadcastQuery
>;
export type ChatDetailBroadcastLazyQueryHookResult = ReturnType<
  typeof useChatDetailBroadcastLazyQuery
>;
export type ChatDetailBroadcastQueryResult = Apollo.QueryResult<
  ChatDetailBroadcastQuery,
  ChatDetailBroadcastQueryVariables
>;
export const UnclaimedChatCountDocument = gql`
  query UnclaimedChatCount($userId: String!) {
    unclaimedChatCount(userID: $userId) {
      status
      count
    }
  }
`;

/**
 * __useUnclaimedChatCountQuery__
 *
 * To run a query within a React component, call `useUnclaimedChatCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnclaimedChatCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnclaimedChatCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnclaimedChatCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnclaimedChatCountQuery,
    UnclaimedChatCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnclaimedChatCountQuery,
    UnclaimedChatCountQueryVariables
  >(UnclaimedChatCountDocument, options);
}
export function useUnclaimedChatCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnclaimedChatCountQuery,
    UnclaimedChatCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnclaimedChatCountQuery,
    UnclaimedChatCountQueryVariables
  >(UnclaimedChatCountDocument, options);
}
export type UnclaimedChatCountQueryHookResult = ReturnType<
  typeof useUnclaimedChatCountQuery
>;
export type UnclaimedChatCountLazyQueryHookResult = ReturnType<
  typeof useUnclaimedChatCountLazyQuery
>;
export type UnclaimedChatCountQueryResult = Apollo.QueryResult<
  UnclaimedChatCountQuery,
  UnclaimedChatCountQueryVariables
>;
export const ClaimDocument = gql`
  query claim($userId: String!, $roomID: Int) {
    claim(userId: $userId, roomID: $roomID) {
      room {
        id
        name
        channelID
        agentID
        labels {
          id
          title
          description
          color
          tenantID
          rooms {
            id
            name
            channelID
            agentID
            cUser {
              id
              name
              phoneNumber
            }
          }
        }
      }
      accountUsers {
        id
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      messages {
        id
        sender
        receiver
        type
        status
        message
        time
        sendByAgent
        mediaURL
        mimeType
        fileName
        mediaSize
      }
      unreadMsgCount
    }
  }
`;

/**
 * __useClaimQuery__
 *
 * To run a query within a React component, call `useClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useClaimQuery(
  baseOptions: Apollo.QueryHookOptions<ClaimQuery, ClaimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimQuery, ClaimQueryVariables>(
    ClaimDocument,
    options
  );
}
export function useClaimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClaimQuery, ClaimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimQuery, ClaimQueryVariables>(
    ClaimDocument,
    options
  );
}
export type ClaimQueryHookResult = ReturnType<typeof useClaimQuery>;
export type ClaimLazyQueryHookResult = ReturnType<typeof useClaimLazyQuery>;
export type ClaimQueryResult = Apollo.QueryResult<
  ClaimQuery,
  ClaimQueryVariables
>;
export const DashboardConversationsByTenantDocument = gql`
  query DashboardConversationsByTenant($tenantId: String!, $userId: String) {
    dashboardConversationsByTenant(tenantId: $tenantId, userID: $userId) {
      totalConversationsUnclaimed
      totalConversationsClaimed
      totalConversationsFinished
    }
  }
`;

/**
 * __useDashboardConversationsByTenantQuery__
 *
 * To run a query within a React component, call `useDashboardConversationsByTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardConversationsByTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardConversationsByTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardConversationsByTenantQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardConversationsByTenantQuery,
    DashboardConversationsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardConversationsByTenantQuery,
    DashboardConversationsByTenantQueryVariables
  >(DashboardConversationsByTenantDocument, options);
}
export function useDashboardConversationsByTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardConversationsByTenantQuery,
    DashboardConversationsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardConversationsByTenantQuery,
    DashboardConversationsByTenantQueryVariables
  >(DashboardConversationsByTenantDocument, options);
}
export type DashboardConversationsByTenantQueryHookResult = ReturnType<
  typeof useDashboardConversationsByTenantQuery
>;
export type DashboardConversationsByTenantLazyQueryHookResult = ReturnType<
  typeof useDashboardConversationsByTenantLazyQuery
>;
export type DashboardConversationsByTenantQueryResult = Apollo.QueryResult<
  DashboardConversationsByTenantQuery,
  DashboardConversationsByTenantQueryVariables
>;
export const GenericDashboardReportDocument = gql`
  query GenericDashboardReport($input: GenericDashboardReportInput!) {
    genericDashboardReport(input: $input) {
      averageTimeSolution
      avgTimeFirstResponse
      averageResponseTimeWhole
      totalConversationsInQueue
      totalConversationsActive
      totalConversationsDone
      totalConversationsIncoming
      totalConversationsClaimed
      averageResponseTimeAgent
    }
  }
`;

/**
 * __useGenericDashboardReportQuery__
 *
 * To run a query within a React component, call `useGenericDashboardReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericDashboardReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericDashboardReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenericDashboardReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenericDashboardReportQuery,
    GenericDashboardReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GenericDashboardReportQuery,
    GenericDashboardReportQueryVariables
  >(GenericDashboardReportDocument, options);
}
export function useGenericDashboardReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenericDashboardReportQuery,
    GenericDashboardReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenericDashboardReportQuery,
    GenericDashboardReportQueryVariables
  >(GenericDashboardReportDocument, options);
}
export type GenericDashboardReportQueryHookResult = ReturnType<
  typeof useGenericDashboardReportQuery
>;
export type GenericDashboardReportLazyQueryHookResult = ReturnType<
  typeof useGenericDashboardReportLazyQuery
>;
export type GenericDashboardReportQueryResult = Apollo.QueryResult<
  GenericDashboardReportQuery,
  GenericDashboardReportQueryVariables
>;
export const GetChannelsByUserDocument = gql`
  query getChannelsByUser($tenantId: String!, $userId: String!) {
    getChannelsByUser(tenantId: $tenantId, userID: $userId) {
      id
      name
      number
      tenantID
      businessName
      enabled
    }
  }
`;

/**
 * __useGetChannelsByUserQuery__
 *
 * To run a query within a React component, call `useGetChannelsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsByUserQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChannelsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChannelsByUserQuery,
    GetChannelsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChannelsByUserQuery,
    GetChannelsByUserQueryVariables
  >(GetChannelsByUserDocument, options);
}
export function useGetChannelsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelsByUserQuery,
    GetChannelsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChannelsByUserQuery,
    GetChannelsByUserQueryVariables
  >(GetChannelsByUserDocument, options);
}
export type GetChannelsByUserQueryHookResult = ReturnType<
  typeof useGetChannelsByUserQuery
>;
export type GetChannelsByUserLazyQueryHookResult = ReturnType<
  typeof useGetChannelsByUserLazyQuery
>;
export type GetChannelsByUserQueryResult = Apollo.QueryResult<
  GetChannelsByUserQuery,
  GetChannelsByUserQueryVariables
>;
export const GetLabelsByTenantDocument = gql`
  query getLabelsByTenant($input: GetLabelsByTenantInput!) {
    getLabelsByTenant(input: $input) {
      labels {
        id
        title
        description
        color
        tenantID
        rooms {
          id
          name
          channelID
          agentID
        }
      }
    }
  }
`;

/**
 * __useGetLabelsByTenantQuery__
 *
 * To run a query within a React component, call `useGetLabelsByTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsByTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsByTenantQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLabelsByTenantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabelsByTenantQuery,
    GetLabelsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLabelsByTenantQuery,
    GetLabelsByTenantQueryVariables
  >(GetLabelsByTenantDocument, options);
}
export function useGetLabelsByTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelsByTenantQuery,
    GetLabelsByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabelsByTenantQuery,
    GetLabelsByTenantQueryVariables
  >(GetLabelsByTenantDocument, options);
}
export type GetLabelsByTenantQueryHookResult = ReturnType<
  typeof useGetLabelsByTenantQuery
>;
export type GetLabelsByTenantLazyQueryHookResult = ReturnType<
  typeof useGetLabelsByTenantLazyQuery
>;
export type GetLabelsByTenantQueryResult = Apollo.QueryResult<
  GetLabelsByTenantQuery,
  GetLabelsByTenantQueryVariables
>;
export const GetRoomsByLabelDocument = gql`
  query getRoomsByLabel($input: GetRoomsByLabelInput!) {
    getRoomsByLabel(input: $input) {
      cursorAfter
      cursorBefore
      rooms {
        id
        name
        channelID
        agentID
        cUser {
          id
          name
          phoneNumber
        }
      }
    }
  }
`;

/**
 * __useGetRoomsByLabelQuery__
 *
 * To run a query within a React component, call `useGetRoomsByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsByLabelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoomsByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomsByLabelQuery,
    GetRoomsByLabelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomsByLabelQuery, GetRoomsByLabelQueryVariables>(
    GetRoomsByLabelDocument,
    options
  );
}
export function useGetRoomsByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomsByLabelQuery,
    GetRoomsByLabelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoomsByLabelQuery,
    GetRoomsByLabelQueryVariables
  >(GetRoomsByLabelDocument, options);
}
export type GetRoomsByLabelQueryHookResult = ReturnType<
  typeof useGetRoomsByLabelQuery
>;
export type GetRoomsByLabelLazyQueryHookResult = ReturnType<
  typeof useGetRoomsByLabelLazyQuery
>;
export type GetRoomsByLabelQueryResult = Apollo.QueryResult<
  GetRoomsByLabelQuery,
  GetRoomsByLabelQueryVariables
>;
export const GetOperationalHoursByTenantDocument = gql`
  query GetOperationalHoursByTenant($tenantId: String!) {
    getOperationalHoursByTenant(tenantId: $tenantId) {
      id
      tenantID
      weekHours
      weekendHours
      channelID
      offOperationalText
      onOperationalText
      newBuyer
    }
  }
`;

/**
 * __useGetOperationalHoursByTenantQuery__
 *
 * To run a query within a React component, call `useGetOperationalHoursByTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationalHoursByTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationalHoursByTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetOperationalHoursByTenantQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOperationalHoursByTenantQuery,
    GetOperationalHoursByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOperationalHoursByTenantQuery,
    GetOperationalHoursByTenantQueryVariables
  >(GetOperationalHoursByTenantDocument, options);
}
export function useGetOperationalHoursByTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperationalHoursByTenantQuery,
    GetOperationalHoursByTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperationalHoursByTenantQuery,
    GetOperationalHoursByTenantQueryVariables
  >(GetOperationalHoursByTenantDocument, options);
}
export type GetOperationalHoursByTenantQueryHookResult = ReturnType<
  typeof useGetOperationalHoursByTenantQuery
>;
export type GetOperationalHoursByTenantLazyQueryHookResult = ReturnType<
  typeof useGetOperationalHoursByTenantLazyQuery
>;
export type GetOperationalHoursByTenantQueryResult = Apollo.QueryResult<
  GetOperationalHoursByTenantQuery,
  GetOperationalHoursByTenantQueryVariables
>;
export const GetOperationalHoursByChannelDocument = gql`
  query GetOperationalHoursByChannel($channelId: String!) {
    getOperationalHoursByChannel(channelID: $channelId) {
      id
      tenantID
      channelID
      onOperationalText
      offOperationalHoursInput
      isNewVendor
      messageTemplateID
      operationalHours {
        id
        weekDay
        from
        to
        open
      }
    }
  }
`;

/**
 * __useGetOperationalHoursByChannelQuery__
 *
 * To run a query within a React component, call `useGetOperationalHoursByChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationalHoursByChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationalHoursByChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetOperationalHoursByChannelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOperationalHoursByChannelQuery,
    GetOperationalHoursByChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOperationalHoursByChannelQuery,
    GetOperationalHoursByChannelQueryVariables
  >(GetOperationalHoursByChannelDocument, options);
}
export function useGetOperationalHoursByChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperationalHoursByChannelQuery,
    GetOperationalHoursByChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperationalHoursByChannelQuery,
    GetOperationalHoursByChannelQueryVariables
  >(GetOperationalHoursByChannelDocument, options);
}
export type GetOperationalHoursByChannelQueryHookResult = ReturnType<
  typeof useGetOperationalHoursByChannelQuery
>;
export type GetOperationalHoursByChannelLazyQueryHookResult = ReturnType<
  typeof useGetOperationalHoursByChannelLazyQuery
>;
export type GetOperationalHoursByChannelQueryResult = Apollo.QueryResult<
  GetOperationalHoursByChannelQuery,
  GetOperationalHoursByChannelQueryVariables
>;
export const GetGreetingsByChannelDocument = gql`
  query GetGreetingsByChannel($channelId: String!) {
    getGreetingsByChannel(channelID: $channelId) {
      channel
      greetings
      newCustomer
      type
      mediaURL
    }
  }
`;

/**
 * __useGetGreetingsByChannelQuery__
 *
 * To run a query within a React component, call `useGetGreetingsByChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGreetingsByChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGreetingsByChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetGreetingsByChannelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGreetingsByChannelQuery,
    GetGreetingsByChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGreetingsByChannelQuery,
    GetGreetingsByChannelQueryVariables
  >(GetGreetingsByChannelDocument, options);
}
export function useGetGreetingsByChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGreetingsByChannelQuery,
    GetGreetingsByChannelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGreetingsByChannelQuery,
    GetGreetingsByChannelQueryVariables
  >(GetGreetingsByChannelDocument, options);
}
export type GetGreetingsByChannelQueryHookResult = ReturnType<
  typeof useGetGreetingsByChannelQuery
>;
export type GetGreetingsByChannelLazyQueryHookResult = ReturnType<
  typeof useGetGreetingsByChannelLazyQuery
>;
export type GetGreetingsByChannelQueryResult = Apollo.QueryResult<
  GetGreetingsByChannelQuery,
  GetGreetingsByChannelQueryVariables
>;
export const GetQuickReplyDocument = gql`
  query GetQuickReply($tenantId: String!, $status: Int) {
    getQuickReply(tenantID: $tenantId, status: $status) {
      id
      tenantID
      title
      content
      status
      cloudMediaID
      type
      signedUrl
    }
  }
`;

/**
 * __useGetQuickReplyQuery__
 *
 * To run a query within a React component, call `useGetQuickReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickReplyQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetQuickReplyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuickReplyQuery,
    GetQuickReplyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuickReplyQuery, GetQuickReplyQueryVariables>(
    GetQuickReplyDocument,
    options
  );
}
export function useGetQuickReplyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuickReplyQuery,
    GetQuickReplyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuickReplyQuery, GetQuickReplyQueryVariables>(
    GetQuickReplyDocument,
    options
  );
}
export type GetQuickReplyQueryHookResult = ReturnType<
  typeof useGetQuickReplyQuery
>;
export type GetQuickReplyLazyQueryHookResult = ReturnType<
  typeof useGetQuickReplyLazyQuery
>;
export type GetQuickReplyQueryResult = Apollo.QueryResult<
  GetQuickReplyQuery,
  GetQuickReplyQueryVariables
>;
export const GetQuickReplyByIdDocument = gql`
  query GetQuickReplyByID($getQuickReplyByIdId: String!) {
    getQuickReplyByID(id: $getQuickReplyByIdId) {
      id
      tenantID
      title
      content
      status
      cloudMediaID
    }
  }
`;

/**
 * __useGetQuickReplyByIdQuery__
 *
 * To run a query within a React component, call `useGetQuickReplyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickReplyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickReplyByIdQuery({
 *   variables: {
 *      getQuickReplyByIdId: // value for 'getQuickReplyByIdId'
 *   },
 * });
 */
export function useGetQuickReplyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuickReplyByIdQuery,
    GetQuickReplyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetQuickReplyByIdQuery,
    GetQuickReplyByIdQueryVariables
  >(GetQuickReplyByIdDocument, options);
}
export function useGetQuickReplyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuickReplyByIdQuery,
    GetQuickReplyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQuickReplyByIdQuery,
    GetQuickReplyByIdQueryVariables
  >(GetQuickReplyByIdDocument, options);
}
export type GetQuickReplyByIdQueryHookResult = ReturnType<
  typeof useGetQuickReplyByIdQuery
>;
export type GetQuickReplyByIdLazyQueryHookResult = ReturnType<
  typeof useGetQuickReplyByIdLazyQuery
>;
export type GetQuickReplyByIdQueryResult = Apollo.QueryResult<
  GetQuickReplyByIdQuery,
  GetQuickReplyByIdQueryVariables
>;
export const GetMessageTemplatesDocument = gql`
  query GetMessageTemplates(
    $tenantId: String!
    $category: String
    $channelId: String
    $excludeCat: TemplateCategory!
    $status: Int
  ) {
    getMessageTemplates(
      tenantID: $tenantId
      category: $category
      channelID: $channelId
      excludeCat: $excludeCat
      status: $status
    ) {
      isEditable
      templateIDinDB
      templateID
      templateName
      templateBody
      templateLang
      templateCategory
      templateVariableCount
      templateType
      tenantID
      channelID
      templateStatus
      activeStatus
      cloudMediaID
    }
  }
`;

/**
 * __useGetMessageTemplatesQuery__
 *
 * To run a query within a React component, call `useGetMessageTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageTemplatesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      category: // value for 'category'
 *      channelId: // value for 'channelId'
 *      excludeCat: // value for 'excludeCat'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMessageTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMessageTemplatesQuery,
    GetMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMessageTemplatesQuery,
    GetMessageTemplatesQueryVariables
  >(GetMessageTemplatesDocument, options);
}
export function useGetMessageTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageTemplatesQuery,
    GetMessageTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMessageTemplatesQuery,
    GetMessageTemplatesQueryVariables
  >(GetMessageTemplatesDocument, options);
}
export type GetMessageTemplatesQueryHookResult = ReturnType<
  typeof useGetMessageTemplatesQuery
>;
export type GetMessageTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetMessageTemplatesLazyQuery
>;
export type GetMessageTemplatesQueryResult = Apollo.QueryResult<
  GetMessageTemplatesQuery,
  GetMessageTemplatesQueryVariables
>;
export const TemplateCategoriesDocument = gql`
  query templateCategories {
    getMessageTemplateCaterogies
  }
`;

/**
 * __useTemplateCategoriesQuery__
 *
 * To run a query within a React component, call `useTemplateCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TemplateCategoriesQuery,
    TemplateCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TemplateCategoriesQuery,
    TemplateCategoriesQueryVariables
  >(TemplateCategoriesDocument, options);
}
export function useTemplateCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TemplateCategoriesQuery,
    TemplateCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TemplateCategoriesQuery,
    TemplateCategoriesQueryVariables
  >(TemplateCategoriesDocument, options);
}
export type TemplateCategoriesQueryHookResult = ReturnType<
  typeof useTemplateCategoriesQuery
>;
export type TemplateCategoriesLazyQueryHookResult = ReturnType<
  typeof useTemplateCategoriesLazyQuery
>;
export type TemplateCategoriesQueryResult = Apollo.QueryResult<
  TemplateCategoriesQuery,
  TemplateCategoriesQueryVariables
>;
export const GetFileUploadUrlDocument = gql`
  query GetFileUploadUrl($fileName: String!, $contentType: String!) {
    getUploadUrl(input: { fileName: $fileName, contentType: $contentType }) {
      url
      mediaName
    }
  }
`;

/**
 * __useGetFileUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetFileUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetFileUploadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileUploadUrlQuery, GetFileUploadUrlQueryVariables>(
    GetFileUploadUrlDocument,
    options
  );
}
export function useGetFileUploadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >(GetFileUploadUrlDocument, options);
}
export type GetFileUploadUrlQueryHookResult = ReturnType<
  typeof useGetFileUploadUrlQuery
>;
export type GetFileUploadUrlLazyQueryHookResult = ReturnType<
  typeof useGetFileUploadUrlLazyQuery
>;
export type GetFileUploadUrlQueryResult = Apollo.QueryResult<
  GetFileUploadUrlQuery,
  GetFileUploadUrlQueryVariables
>;
export const WebhookListDocument = gql`
  query WebhookList($tenantId: String!) {
    webhookList(tenantId: $tenantId) {
      id
      ChannelID
      TenantID
      TemplateID
      Name
      SupervisorID
      Enabled
    }
  }
`;

/**
 * __useWebhookListQuery__
 *
 * To run a query within a React component, call `useWebhookListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookListQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useWebhookListQuery(
  baseOptions: Apollo.QueryHookOptions<
    WebhookListQuery,
    WebhookListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebhookListQuery, WebhookListQueryVariables>(
    WebhookListDocument,
    options
  );
}
export function useWebhookListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebhookListQuery,
    WebhookListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebhookListQuery, WebhookListQueryVariables>(
    WebhookListDocument,
    options
  );
}
export type WebhookListQueryHookResult = ReturnType<typeof useWebhookListQuery>;
export type WebhookListLazyQueryHookResult = ReturnType<
  typeof useWebhookListLazyQuery
>;
export type WebhookListQueryResult = Apollo.QueryResult<
  WebhookListQuery,
  WebhookListQueryVariables
>;
export const WebhookDetailDocument = gql`
  query WebhookDetail($webhookID: String!) {
    webhookDetail(webhookID: $webhookID) {
      id
      ChannelID
      TenantID
      TemplateID
      Name
      SupervisorID
      Curl
      Link
      Enabled
      Token
      TokenExpiry
      TokenUpdatedAt
    }
  }
`;

/**
 * __useWebhookDetailQuery__
 *
 * To run a query within a React component, call `useWebhookDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookDetailQuery({
 *   variables: {
 *      webhookID: // value for 'webhookID'
 *   },
 * });
 */
export function useWebhookDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    WebhookDetailQuery,
    WebhookDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebhookDetailQuery, WebhookDetailQueryVariables>(
    WebhookDetailDocument,
    options
  );
}
export function useWebhookDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebhookDetailQuery,
    WebhookDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebhookDetailQuery, WebhookDetailQueryVariables>(
    WebhookDetailDocument,
    options
  );
}
export type WebhookDetailQueryHookResult = ReturnType<
  typeof useWebhookDetailQuery
>;
export type WebhookDetailLazyQueryHookResult = ReturnType<
  typeof useWebhookDetailLazyQuery
>;
export type WebhookDetailQueryResult = Apollo.QueryResult<
  WebhookDetailQuery,
  WebhookDetailQueryVariables
>;
export const ListMessagesDocument = gql`
  subscription ListMessages($input: ReceiveMessageSub!) {
    receiveMessage(input: $input) {
      room {
        id
        name
        channelID
        lastMessageTimestamp
        lastMessageID
      }
      accountUser {
        id
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      message {
        id
        sender
        receiver
        type
        status
        message
        time
        mediaURL
        mimeType
        mediaSize
        fileName
        sendByAgent
        senderName
      }
    }
  }
`;

/**
 * __useListMessagesSubscription__
 *
 * To run a query within a React component, call `useListMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListMessagesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ListMessagesSubscription,
    ListMessagesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ListMessagesSubscription,
    ListMessagesSubscriptionVariables
  >(ListMessagesDocument, options);
}
export type ListMessagesSubscriptionHookResult = ReturnType<
  typeof useListMessagesSubscription
>;
export type ListMessagesSubscriptionResult =
  Apollo.SubscriptionResult<ListMessagesSubscription>;
export const MessageStatusDocument = gql`
  subscription messageStatus($input: String!) {
    messageStatus(input: $input) {
      roomID
      messageID
      messageStatus
    }
  }
`;

/**
 * __useMessageStatusSubscription__
 *
 * To run a query within a React component, call `useMessageStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageStatusSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MessageStatusSubscription,
    MessageStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MessageStatusSubscription,
    MessageStatusSubscriptionVariables
  >(MessageStatusDocument, options);
}
export type MessageStatusSubscriptionHookResult = ReturnType<
  typeof useMessageStatusSubscription
>;
export type MessageStatusSubscriptionResult =
  Apollo.SubscriptionResult<MessageStatusSubscription>;
export const ChatStatusDocument = gql`
  subscription chatStatus {
    chatStatus {
      count
    }
  }
`;

/**
 * __useChatStatusSubscription__
 *
 * To run a query within a React component, call `useChatStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatStatusSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatStatusSubscription,
    ChatStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatStatusSubscription,
    ChatStatusSubscriptionVariables
  >(ChatStatusDocument, options);
}
export type ChatStatusSubscriptionHookResult = ReturnType<
  typeof useChatStatusSubscription
>;
export type ChatStatusSubscriptionResult =
  Apollo.SubscriptionResult<ChatStatusSubscription>;
export const TransferChatsubDocument = gql`
  subscription transferChatsub($input: String!) {
    transferChatSub(input: $input) {
      messages {
        id
        sender
        receiver
        type
        mediaURL
        mediaSize
      }
      room {
        id
        name
        agentID
      }
      accountUsers {
        id
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
    }
  }
`;

/**
 * __useTransferChatsubSubscription__
 *
 * To run a query within a React component, call `useTransferChatsubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransferChatsubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferChatsubSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferChatsubSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TransferChatsubSubscription,
    TransferChatsubSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TransferChatsubSubscription,
    TransferChatsubSubscriptionVariables
  >(TransferChatsubDocument, options);
}
export type TransferChatsubSubscriptionHookResult = ReturnType<
  typeof useTransferChatsubSubscription
>;
export type TransferChatsubSubscriptionResult =
  Apollo.SubscriptionResult<TransferChatsubSubscription>;
export const UnclaimedChatDocument = gql`
  subscription UnclaimedChat {
    unclaimedChat {
      room {
        id
        name
        channelID
        agentID
      }
      accountUser {
        id
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      message {
        id
        sender
        receiver
        type
        status
        message
        time
        sendByAgent
        mediaURL
        mimeType
        fileName
        mediaSize
      }
      add
    }
  }
`;

/**
 * __useUnclaimedChatSubscription__
 *
 * To run a query within a React component, call `useUnclaimedChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnclaimedChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnclaimedChatSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUnclaimedChatSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UnclaimedChatSubscription,
    UnclaimedChatSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UnclaimedChatSubscription,
    UnclaimedChatSubscriptionVariables
  >(UnclaimedChatDocument, options);
}
export type UnclaimedChatSubscriptionHookResult = ReturnType<
  typeof useUnclaimedChatSubscription
>;
export type UnclaimedChatSubscriptionResult =
  Apollo.SubscriptionResult<UnclaimedChatSubscription>;
export const MlcbChatDocument = gql`
  subscription MlcbChat {
    mlcbChat {
      room {
        id
        name
        channelID
        agentID
      }
      accountUser {
        id
        name
        active
      }
      contactUser {
        id
        name
        dp
        phoneNumber
      }
      message {
        id
        sender
        receiver
        type
        status
        message
        time
        sendByAgent
        mediaURL
        mimeType
        fileName
        mediaSize
      }
      add
    }
  }
`;

/**
 * __useMlcbChatSubscription__
 *
 * To run a query within a React component, call `useMlcbChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMlcbChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlcbChatSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMlcbChatSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MlcbChatSubscription,
    MlcbChatSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MlcbChatSubscription,
    MlcbChatSubscriptionVariables
  >(MlcbChatDocument, options);
}
export type MlcbChatSubscriptionHookResult = ReturnType<
  typeof useMlcbChatSubscription
>;
export type MlcbChatSubscriptionResult =
  Apollo.SubscriptionResult<MlcbChatSubscription>;
