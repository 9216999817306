import Link from "next/link";
import { Grid, IconButton } from "@mui/material";
import { ToastContainer } from "react-toastify";

import HeaderRightMenuItems from "./HeaderRightMenuItems";
import { useTenantContext } from "../Authentication/AuthContainer";
import { SircloChatLogo } from "../../commonAssets/svg/SircloChatLogo";

const Header = (props: {
  sideopen: boolean;
  setSideopen: (prev: any) => boolean | void;
}) => {
  const { tenant } = useTenantContext();

  const sidebaropen = () => {
    props.setSideopen((prev: any) => !prev);
  };

  const UserprofileArray = ["Profil Bisnis", "Keluar"];

  return (
    <Grid container>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeButton={false}
        limit={0}
      />

      <Grid item md={12} xs={12}>
        <Grid className="header" container spacing={0}>
          <Grid className="mobile-nav">
            {/* <IconButton  onClick={sidebaropen}> */}
            {props.sideopen ? (
              <IconButton aria-label="nav" onClick={sidebaropen}>
                <svg height="12" width="12">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton aria-label="nav" onClick={sidebaropen}>
                <svg height="16" width="20">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1.33333C0 0.596954 0.639593 0 1.42857 0H18.5714C19.3604 0 20 0.596954 20 1.33333C20 2.06971 19.3604 2.66667 18.5714 2.66667H1.42857C0.639593 2.66667 0 2.06971 0 1.33333Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 7.26362 0.639593 6.66667 1.42857 6.66667H18.5714C19.3604 6.66667 20 7.26362 20 8C20 8.73638 19.3604 9.33333 18.5714 9.33333H1.42857C0.639593 9.33333 0 8.73638 0 8Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 14.6667C0 13.9303 0.639593 13.3333 1.42857 13.3333H18.5714C19.3604 13.3333 20 13.9303 20 14.6667C20 15.403 19.3604 16 18.5714 16H1.42857C0.639593 16 0 15.403 0 14.6667Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            )}
          </Grid>
          <Grid
            className="layer-overlay"
            style={props.sideopen ? { display: "block" } : {}}
          ></Grid>
          <Grid className="logo-section" item md={4} sm={6} xs={8}>
            <Link href="/">
              <SircloChatLogo />
            </Link>
            {/* <Grid className="left-select" item>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {archive}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() =>handleClose(archive)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() =>handleClose('Admin')}>Chat Admin</MenuItem>
                <MenuItem onClick={() =>handleClose('Archived')}>Chat Archived</MenuItem>
              </Menu>
            </Grid> */}
          </Grid>
          <Grid className="right-part" item md={8} sm={6} xs={4}>
            <HeaderRightMenuItems
              classname="select"
            />
            {/* <HeaderMenu
              menuItems={SettingsArray}
              name={settingImage}
              classname="settings"
            />
            <HeaderMenu
              menuItems={EcomArray}
              name={logoutImage}
              classname="logout"
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
