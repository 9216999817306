import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";

import {
  ChatListData,
  ChatListMessage,
  MessageStatus,
  MessageType,
  SearchMessageList,
} from "../graphql";

export const sortChat = (chats: Maybe<ChatListData>[] | undefined) => {
  if (!chats) {
    return;
  }
  let chatsData = chats.slice().sort(function (left, right) {
    if (right?.messages && left?.messages) {
      if (right?.messages[0]?.time && left?.messages[0]?.time) {
        if (
          !Date.parse(right?.messages[0]?.time) &&
          !Date.parse(left?.messages[0]?.time)
        ) {
          return (
            parseInt(right?.messages[0]?.time) -
            parseInt(left?.messages[0]?.time)
          );
        } else if (
          Date.parse(right?.messages[0]?.time) &&
          !Date.parse(left?.messages[0]?.time)
        ) {
          return (
            Date.parse(right?.messages[0]?.time) -
            parseInt(left?.messages[0]?.time)
          );
        } else if (
          !Date.parse(right?.messages[0]?.time) &&
          Date.parse(left?.messages[0]?.time)
        ) {
          return (
            parseInt(right?.messages[0]?.time) -
            Date.parse(left?.messages[0]?.time)
          );
        } else {
          return (
            Date.parse(right?.messages[0]?.time) -
            Date.parse(left?.messages[0]?.time)
          );
        }
      }
    }
    return 0;
  });
  return chatsData;
};

export const formatMessage = (
  messages?: Maybe<ChatListMessage>[] | Maybe<SearchMessageList>[]
) => {
  if (!messages || messages?.length === 0) {
    return "";
  }

  // TODO: handle correct message display
  switch (messages[0]?.type) {
    case MessageType.Audio:
      return "audio";
    case MessageType.Document:
      return "document";
    case MessageType.Image:
      return "image";
    case MessageType.Location:
      return "location";
    case MessageType.Video:
      return "video";
    case MessageType.Sticker:
      return "stiker";
    case MessageType.Product:
      return "produk";
    case "INVOICE" as MessageType:
    case MessageType.Order:
      return "order";
    case MessageType.Interactive:
    case MessageType.Template:
    case MessageType.QuickReply:
    case MessageType.Text:
      return clampMessage(messages);
    default:
      return clampMessage(messages);
  }
};

export const clampMessage = (
  messages?: Maybe<ChatListMessage>[] | Maybe<SearchMessageList>[]
) => {
  var lastMsg = messages?.[0]?.message;

  if (!lastMsg) {
    return "";
  }

  if (lastMsg.length < 42) {
    return lastMsg;
  }

  return lastMsg.substring(0, 42) + "...";
};

export const formatDate = (
  messages?: Maybe<Maybe<ChatListMessage>[] | Maybe<SearchMessageList>[]>
) => {
  if (
    messages?.length === 0 ||
    messages?.[0]?.status === MessageStatus.Sending
  ) {
    return "";
  }
  const sentDate = moment
    .utc(messages?.[0]?.time.split("+")[0].trim())
    .local()
    .format("DD/MM/YYYY");
  return sentDate;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: "#81D4FA",
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")[1] ? name.split(" ")[1][0] : ""
    }`,
  };
};

export const notificationMessage = (messages: any) => {
  if (messages.length === 0) {
    return "";
  }
  if (messages.length < 42) {
    return messages;
  }
  return messages.substring(0, 42) + "...";
};
