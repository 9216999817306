import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";
import { ThemeOptions } from "@mui/system";

import palette from "./utils/_exports.module.scss";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    grey100: Palette["primary"];
    grey200: Palette["primary"];
    grey300: Palette["primary"];
    grey400: Palette["primary"];
    grey700: Palette["primary"];
    grey800: Palette["primary"];
    grey900: Palette["primary"];
    blue100: Palette["primary"];
    blue200: Palette["primary"];
    blue300: Palette["primary"];
    blue400: Palette["primary"];
    blue500: Palette["primary"];
    blue600: Palette["primary"];
    blue700: Palette["primary"];
    yellow100: Palette["primary"];
    yellow200: Palette["primary"];
    yellow300: Palette["primary"];
    yellow400: Palette["primary"];
    yellow500: Palette["primary"];
    yellow600: Palette["primary"];
    green100: Palette["primary"];
    green200: Palette["primary"];
    green300: Palette["primary"];
    green400: Palette["primary"];
    green500: Palette["primary"];
    green600: Palette["primary"];
    red100: Palette["primary"];
    red200: Palette["primary"];
    red300: Palette["primary"];
    red400: Palette["primary"];
    red500: Palette["primary"];
    red600: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    grey100?: PaletteOptions["primary"];
    grey200?: PaletteOptions["primary"];
    grey300?: PaletteOptions["primary"];
    grey400?: PaletteOptions["primary"];
    grey700?: PaletteOptions["primary"];
    grey800?: PaletteOptions["primary"];
    grey900?: PaletteOptions["primary"];
    blue100?: PaletteOptions["primary"];
    blue200?: PaletteOptions["primary"];
    blue300?: PaletteOptions["primary"];
    blue400?: PaletteOptions["primary"];
    blue500?: PaletteOptions["primary"];
    blue600?: PaletteOptions["primary"];
    blue700?: PaletteOptions["primary"];
    yellow100?: PaletteOptions["primary"];
    yellow200?: PaletteOptions["primary"];
    yellow300?: PaletteOptions["primary"];
    yellow400?: PaletteOptions["primary"];
    yellow500?: PaletteOptions["primary"];
    yellow600?: PaletteOptions["primary"];
    green100?: PaletteOptions["primary"];
    green200?: PaletteOptions["primary"];
    green300?: PaletteOptions["primary"];
    green400?: PaletteOptions["primary"];
    green500?: PaletteOptions["primary"];
    green600?: PaletteOptions["primary"];
    red100?: PaletteOptions["primary"];
    red200?: PaletteOptions["primary"];
    red300?: PaletteOptions["primary"];
    red400?: PaletteOptions["primary"];
    red500?: PaletteOptions["primary"];
    red600?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    blue300: true;
    grey200: true;
    grey700: true;
    red400: true;
  }
}

const defaultTheme = createTheme();

// override MUI shadows
// small
defaultTheme.shadows[1] =
  "0px 1px 3px rgba(61, 79, 92, 0.1), 0px 1px 2px -1px rgba(61, 79, 92, 0.1)";
// medium
defaultTheme.shadows[2] = " 0px 2px 8px rgba(61, 79, 92, 0.1)";
// large
defaultTheme.shadows[3] =
  "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.1)";

const customShadows: ThemeOptions["shadows"] = [...defaultTheme.shadows];

const muiTheme = createTheme({
  spacing: [0, 4, 8, 16, 32, 64, 128],
  shape: {
    borderRadius: 4,
  },
  mixins: {
    toolbar: {
      minHeight: "54px",
    },
  },
  shadows: customShadows as Shadows,
  typography: {
    htmlFontSize: 16,
    h1: {
      fontSize: 24,
      lineHeight: 1.25,
      letterSpacing: -0.2,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      lineHeight: 1.25,
      letterSpacing: -0.1,
      fontWeight: 700,
    },
    h3: {
      fontSize: 18,
      lineHeight: "22px",
      letterSpacing: -0.1,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.25,
      letterSpacing: -0.1,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: "18px",
      letterSpacing: -0.1,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.25,
      letterSpacing: -0.1,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.05,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 0.05,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.25,
      letterSpacing: 0.1,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.05,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 0.05,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: palette.yellow100,
          color: palette.yellow600,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 248,
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          background: "#222c33",
          // height: "54px",
          boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { disabled: true, variant: "outlined" },
          style: {
            backgroundColor: palette.buttonGray,
            borderColor: palette.borderColor_3,
            borderRadius: "4px",
          },
        },
      ],
    },
    MuiFormHelperText: {
      variants: [
        {
          props: { error: true },
          style: {
            "&.Mui-error": {
              color: palette.red400,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      variants: [
        {
          props: { disabled: true },
          style: {
            backgroundColor: palette.buttonGray,
            borderColor: palette.borderColor_3,
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.grey800,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: palette.blue300,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "4px",
          borderRadius: "5px 5px 0 0",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            boxShadow:
              "0px 1px 3px rgba(61, 79, 92, 0.1), 0px 1px 2px -1px rgba(61, 79, 92, 0.1)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.grey800,
          padding: "8px 8px",
          "&:hover": {
            backgroundColor: palette.grey200,
          },
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: palette.grey200,
            },
          },
        },
      },
      variants: [
        {
          props: { selected: true },
          style: {
            "&.Mui-selected": {
              backgroundColor: palette.grey200,
            },
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          color: palette.white,
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            fontWeight: 500,
          },
        },
        {
          props: { variant: "contained" },
          style: {
            fontWeight: 700,
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontWeight: 700,
          },
        },
        {
          props: { size: "large" },
          style: {
            minHeight: "48px",
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 700,
            textAlign: "center",
          },
        },
        {
          props: { size: "small" },
          style: {
            minHeight: "28px",
            fontSize: 12,
            lineHeight: "20px",
            fontWeight: 700,
            textAlign: "center",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h1", className: "display-xl" },
          style: {
            fontSize: 30,
            lineHeight: "38px",
            letterSpacing: -0.2,
            fontWeight: 800,
          },
        },
        {
          props: { variant: "subtitle1", className: "sub-lg" },
          style: {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: 0.05,
          },
        },
        {
          props: { variant: "subtitle1", className: "sub-md" },
          style: {
            fontSize: 14,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "subtitle1", className: "sub-md-2" },
          style: {
            fontSize: 14,
            lineHeight: "18px",
            letterSpacing: 0.05,
            fontWeight: 500,
          },
        },
        {
          props: { variant: "subtitle1", className: "sub-md-3" },
          style: {
            fontSize: 14,
            lineHeight: "18px",
            letterSpacing: 0.05,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "subtitle2", className: "sub-sm" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "subtitle2", className: "sub-sm-2" },
          style: {
            fontSize: 12,
            lineHeight: 1.25,
            letterSpacing: 0.05,
            fontWeight: 500,
          },
        },
        {
          props: { variant: "subtitle2", className: "sub-sm-3" },
          style: {
            fontSize: 12,
            lineHeight: 1.25,
            letterSpacing: 0.05,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "body1", className: "p-lg" },
          style: {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "body1", className: "alt-p-lg" },
          style: {
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "body1", className: "p-md" },
          style: {
            fontSize: 14,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "body1", className: "alt-p-md" },
          style: {
            fontSize: 14,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "body1", className: "p-sm" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "body1", className: "alt-p-sm" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "caption", className: "cp-md" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.1,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "caption", className: "alt-cp-md" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            letterSpacing: 0.05,
            fontWeight: 700,
          },
        },
        {
          props: { variant: "caption", className: "cp-sm" },
          style: {
            fontSize: 10,
            lineHeight: 1.2,
            letterSpacing: 0.1,
            fontWeight: 400,
          },
        },
        {
          props: { variant: "caption", className: "alt-cp-sm" },
          style: {
            fontSize: 10,
            lineHeight: 1.2,
            letterSpacing: 0.2,
            fontWeight: 700,
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          color: palette.grey800,
        },
      },
    },
  },
  palette: {
    action: {
      disabled: palette.buttonGray,
      disabledBackground: palette.borderColor_3,
    },
    text: {
      primary: palette.grey800,
      disabled: palette.lightGray,
    },
    neutral: {
      main: palette.neutral,
    },
    primary: {
      main: palette.blue300,
    },
    success: {
      main: palette.green300,
    },
    error: {
      main: palette.red400,
    },
    warning: {
      main: palette.yellow200,
    },
    grey100: {
      main: palette.grey100,
    },
    grey200: {
      main: palette.grey200,
    },
    grey300: {
      main: palette.grey300,
    },
    grey400: {
      main: palette.grey400,
    },
    grey700: {
      main: palette.grey700,
    },
    grey800: {
      main: palette.grey800,
    },
    grey900: {
      main: palette.grey900,
    },
    blue100: {
      main: palette.blue100,
    },
    blue200: {
      main: palette.blue200,
    },
    blue300: {
      main: palette.blue300,
    },
    blue400: {
      main: palette.blue400,
    },
    blue500: {
      main: palette.blue500,
    },
    blue600: {
      main: palette.blue600,
    },
    blue700: {
      main: palette.blue700,
    },
    yellow100: {
      main: palette.yellow100,
    },
    yellow200: {
      main: palette.yellow200,
    },
    yellow300: {
      main: palette.yellow300,
    },
    yellow400: {
      main: palette.yellow400,
    },
    yellow500: {
      main: palette.yellow500,
    },
    yellow600: {
      main: palette.yellow600,
    },
    green100: {
      main: palette.green100,
    },
    green200: {
      main: palette.green200,
    },
    green300: {
      main: palette.green300,
    },
    green400: {
      main: palette.green400,
    },
    green500: {
      main: palette.green500,
    },
    green600: {
      main: palette.green600,
    },
    red100: {
      main: palette.red100,
    },
    red200: {
      main: palette.red200,
    },
    red300: {
      main: palette.red300,
    },
    red400: {
      main: palette.red400,
    },
    red500: {
      main: palette.red500,
    },
    red600: {
      main: palette.red600,
    },
  },
});

export default muiTheme;
