import { Step } from "react-joyride";
import { Box } from "@mui/material";

import { useUpsertOnboardingMutation } from "../../../../graphql";
import { OnboardingType } from "../types";

import OnboardingTour from "../OnboardingTour";

const ChannelOnboardingTour = () => {
  const [upsertOnboarding] = useUpsertOnboardingMutation();

  const handleFinishOnboarding = () => {
    upsertOnboarding({
      variables: {
        input: {
          onboardingType: OnboardingType.ONBOARDING_CHANNEL,
          isFinished: true,
          stepIndex: 2,
        },
      },
    });
    localStorage.setItem("channelOnboardingStatus", "SKIPPED");
  };

  return <OnboardingTour steps={steps} onFinish={handleFinishOnboarding} />;
};

export default ChannelOnboardingTour;

const steps: Step[] = [
  {
    content:
      "Klik ikon ini untuk akses pengaturan channel percakapan yang ingin kamu hubungkan ke SIRCLO Chat.",
    disableBeacon: true,
    placement: "right",
    target: "#nav-menu",
    title: "Menu Channel Percakapan",
    showSkipButton: false,
    offset: 96,
    floaterProps: {
      styles: {
        floater: {
          bottom: "450px",
          top: "-90px",
        },
      },
    },
  },
  {
    content: (
      <>
        <Box marginBottom="20px">
          Klik dan pilih platform chat yang ingin kamu hubungkan.
        </Box>
        <b>Tiap platform memiliki ketentuan berbeda, </b>
        harap cermati dan ikuti panduannya dengan tepat.
      </>
    ),
    disableBeacon: true,
    placement: "right",
    target: "#add-channel",
    title: "Menu Channel Percakapan",
    showSkipButton: false,
    offset: 72,
  },
];
