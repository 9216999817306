export const HomeIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.8839 0.366117C10.3957 -0.122039 9.60427 -0.122039 9.11612 0.366117L0.366117 9.11612C-0.122039 9.60427 -0.122039 10.3957 0.366117 10.8839C0.854272 11.372 1.64573 11.372 2.13388 10.8839L2.5 10.5178V18.75C2.5 19.4404 3.05964 20 3.75 20H6.25C6.94036 20 7.5 19.4404 7.5 18.75V16.25C7.5 15.5596 8.05964 15 8.75 15H11.25C11.9404 15 12.5 15.5596 12.5 16.25V18.75C12.5 19.4404 13.0596 20 13.75 20H16.25C16.9404 20 17.5 19.4404 17.5 18.75V10.5178L17.8661 10.8839C18.3543 11.372 19.1457 11.372 19.6339 10.8839C20.122 10.3957 20.122 9.60427 19.6339 9.11612L10.8839 0.366117Z" />
  </svg>
);
