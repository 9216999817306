import React from "react";
import {
  Button,
  Grid,
} from "@mui/material";

type HeaderRightMenuItemsProps = {
  classname: string;
};

const HeaderRightMenuItems = (props: HeaderRightMenuItemsProps) => {

  const handleBackToOms = () => window.location.href = `${process.env.NEXT_PUBLIC_BACK_URI}`

  return (
    <Grid className={props.classname} item>
      <Button
        id="homepage-dropdown-profilemenu"
        aria-controls="basic-menu"
        aria-haspopup="true"
        onClick={handleBackToOms}
      >
        Kembali
      </Button>
    </Grid>
  );
};

export default HeaderRightMenuItems;
