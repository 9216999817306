export const SAVED_LAST_PATH = ["/chat"];

export const WHITELISTED_BRAND = [
  "chat",
  "tyotest",
  "ferris-testing",
  "eugene",
  "sirclo-commerce",
];

export const UNREQUIRED_AUTH_URL = [
  "/",
  "/facebookbutton",
  "/error",
  "/NotFound",
];
