import { TooltipRenderProps } from "react-joyride";
import { Box, Typography, IconButton } from "@mui/material";
import {
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

import styleOnboarding from "../../../../styles/components/Onboarding.module.scss";

import CheckIconSVG from "../../../../commonAssets/svg/CheckIconSVG";

const TourTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
  isLastStep,
  skipProps,
}: TooltipRenderProps) => {
  return (
    <Box {...tooltipProps} className={styleOnboarding.onboarding_tooltip}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="8px"
      >
        {step.title && (
          <Typography variant="h5" color="primary">
            {step.title}
          </Typography>
        )}
      </Box>
      <Box position="absolute" top="4px" right="10px">
        <IconButton {...skipProps}>
          <CloseIcon color="secondary" />
        </IconButton>
      </Box>

      {/* Content */}
      <Typography variant="body1">{step.content}</Typography>

      {/* Footer */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="15px"
      >
        <span>
          {index + 1} dari {size}
        </span>

        <Box display="flex" alignItems="center" gap="8px">
          {index > 0 && (
            <IconButton
              className={styleOnboarding.onboarding_button_arrow}
              {...backProps}
            >
              <ArrowBackIcon fontSize="small" color="secondary" />
            </IconButton>
          )}
          {!isLastStep && continuous && (
            <IconButton
              className={styleOnboarding.onboarding_button_arrow}
              {...primaryProps}
            >
              <ArrowForwardIcon fontSize="small" color="secondary" />
            </IconButton>
          )}
          {isLastStep && (
            <IconButton
              className={styleOnboarding.onboarding_button_done}
              {...closeProps}
            >
              <CheckIconSVG />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TourTooltip;
