import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "../../../commonAssets/svg/ArrowIconSVG";

export type PageHeadingProps = {
  title: string;
  backButton?: boolean;
};

const PageHeading: React.FC<PageHeadingProps> = ({ title, backButton }) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Grid className="inner-page-header" sx={{ boxShadow: 1 }}>
        {backButton && (
          <IconButton
            aria-label="back-nav"
            className="back-nav"
            onClick={() => router.back()}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Typography className="sub-lg">
          <b>{title}</b>
        </Typography>
      </Grid>
    </>
  );
};

export default PageHeading;
