import { useState } from "react";
import Joyride, {
  CallBackProps,
  STATUS,
  ACTIONS,
  Step,
  LIFECYCLE,
} from "react-joyride";

import {
  useTourContext,
  RESET_TOUR,
  SET_STATUS_TOUR,
  TourStatus,
} from "./TourContext";
import TourTooltip from "./components/TourTooltip";

type OnboardingTourProps = {
  steps: Step[];
  onFinish: () => void;
};

const OnboardingTour: React.FC<OnboardingTourProps> = ({ steps, onFinish }) => {
  const {
    state: { run },
    dispatch,
  } = useTourContext();

  const [stepIndex, setStepIndex] = useState(0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, lifecycle } = data;

    const isValidAction =
      (action === ACTIONS.NEXT || action === ACTIONS.PREV) &&
      lifecycle === LIFECYCLE.COMPLETE &&
      status !== STATUS.PAUSED;

    const isTourFinished =
      action === ACTIONS.CLOSE || status === STATUS.FINISHED;

    const isTourSkipped = action === ACTIONS.SKIP || status === STATUS.SKIPPED;

    if (isValidAction) {
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      return setStepIndex(newStepIndex);
    }

    if (isTourSkipped) {
      dispatch({
        type: SET_STATUS_TOUR,
        payload: {
          status: TourStatus.SKIPPED,
        },
      });
      dispatch({
        type: RESET_TOUR,
      });
      setStepIndex(0);
      return;
    }

    if (isTourFinished) {
      onFinish();
      dispatch({
        type: RESET_TOUR,
      });
      return setStepIndex(0);
    }
  };

  return (
    <Joyride
      continuous={true}
      run={run}
      steps={steps}
      tooltipComponent={TourTooltip}
      stepIndex={stepIndex}
      scrollToFirstStep={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      disableOverlayClose
      floaterProps={{
        hideArrow: true,
      }}
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default OnboardingTour;
