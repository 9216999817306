import { SetStateAction, Dispatch, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  IconButton,
  ListItemText,
  styled,
} from "@mui/material";
import router from "next/router";
import mixpanel from "mixpanel-browser";

import ConfirmationModal from "../ChatBot/ConfirmationModal";
import { useTenantContext } from "../Authentication/AuthContainer";
import { useUnSavedContext } from "../../context/UnSavedContext";
import CloseIconSVG from "../../commonAssets/svg/CloseIconSVG";
import navMenus from "./navMenus";
import { snowplowTrack } from "../ui_palette/Tracker/Snowplow/helpers";

const LeftNav = (props: {
  page: string;
  setSideopen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { unSaved, setUnSaved } = useUnSavedContext();
  const { tenant } = useTenantContext();
  const role = tenant?.role;

  const [confirm, setConfirm] = useState(false);
  const [targetUrl, setTargetUrl] = useState("");

  const sidebaropen = () => {
    props.setSideopen((prev) => !prev);
  };

  const handleNavigation = (url: string) => {
    if (unSaved) {
      setConfirm(true);
      setTargetUrl(url);
      return;
    }
    router.push(url);
  };

  const handleConfirmation = (option: boolean) => {
    setConfirm(false);
    if (option) {
      setUnSaved(false);
      router.push(`${window.location.origin}${targetUrl}`);
    }
  };

  const handleOnClick = (url: string, eventName: string) => {
    handleNavigation(url);
    mixpanel.track(eventName);
    snowplowTrack("menu_nav", eventName);
  };

  const description = `${
    props.page === "settings"
      ? "Kamu belum menyimpan perubahan chatbot dengan <b>klik button publish.</b>"
      : ""
  } Perubahan akan terhapus jika kamu keluar dari halaman ini. Apakah kamu yakin untuk melanjutkan?`;

  return (
    <Box className="left-nav" id="nav-menu">
      <Box className="close-nav-btn">
        <IconButton aria-label="delete" onClick={sidebaropen}>
          <CloseIconSVG />
        </IconButton>
      </Box>
      <Box sx={{ p: "8px" }} className="left-nav-inner">
        <nav aria-label="menu">
          <List style={{ padding: 0 }} disablePadding>
            {navMenus.map(
              (menu) =>
                ((role === "ADMIN" && !menu.supervisorAccessOnly) ||
                  role === "SUPERVISOR") && (
                  <ListItem
                    key={menu.id}
                    id={menu.id}
                    disablePadding
                    onClick={() => handleOnClick(menu.url, menu.trackerName)}
                  >
                    <ListItemButton
                      className={
                        "nav-list-btn " +
                        (props.page === menu.page ? "active" : "")
                      }
                    >
                      <ListItemIcon sx={{ minWidth: "auto" }}>
                        {menu.icon}
                      </ListItemIcon>
                      <StyledListItemText
                        className="left-nav-text"
                        primary={menu.label}
                      />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </nav>
      </Box>

      <ConfirmationModal
        open={confirm}
        handleConfirmation={handleConfirmation}
        btnOne="Lanjutkan"
        btnTwo="Batal"
        title="Perubahan Belum Disimpan"
        description={description}
        action="continue"
        setConfirm={setConfirm}
      />
    </Box>
  );
};

const StyledListItemText = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
});

export default LeftNav;
