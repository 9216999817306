import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";

export type UnSavedContextProps = {
  unSaved: boolean;
  setUnSaved: Dispatch<SetStateAction<boolean>>;
};

export const UnSavedContext = createContext({} as UnSavedContextProps);

export const useUnSavedContext = () => useContext(UnSavedContext);

export const UnSavedProvider: FC = ({ children }) => {
  const [unSaved, setUnSaved] = useState(false);

  return (
    <UnSavedContext.Provider value={{ unSaved, setUnSaved }}>
      {children}
    </UnSavedContext.Provider>
  );
};
