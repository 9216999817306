import React, { ReactNode, useContext, createContext, useState } from "react";

type chatFooterVisibleContextType = {
  chatFooterVisible: boolean;
  setChatFooterVisible: (value: boolean) => void;
};

const chatFooterVisibleContextTypeDefaultValues: chatFooterVisibleContextType =
  {
    chatFooterVisible: true,
    setChatFooterVisible: (value: boolean) => {},
  };

const ActiveChatFooterContext = createContext<chatFooterVisibleContextType>(
  chatFooterVisibleContextTypeDefaultValues
);

export function useActiveChatFooter() {
  return useContext(ActiveChatFooterContext);
}

type Props = {
  children: ReactNode;
};

export function ChatFooterVisibleProvider({ children }: Props) {
  const [chatFooterVisible, setChatFooterVisibleStatus] =
    useState<boolean>(true);

  const setChatFooterVisible = (value: boolean) => {
    setChatFooterVisibleStatus(value);
  };

  const value = {
    chatFooterVisible,
    setChatFooterVisible,
  };

  return (
    <>
      <ActiveChatFooterContext.Provider value={value}>
        {children}
      </ActiveChatFooterContext.Provider>
    </>
  );
}
