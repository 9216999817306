import { Button, DialogContent, DialogActions } from "@mui/material";
import { BootstrapDialog, BootstrapDialogTitle } from "../Modal/ModalComponent";

const ConfirmationModal = (props: {
  open: boolean;
  btnOne: string;
  btnTwo: string;
  title: string;
  action: string;
  description: string;
  handleConfirmation: (option: boolean) => void;
  setConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "360px",
          },
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => props.handleConfirmation(false)}
        style={{
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        />
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          id="confirmation-btn-1"
          variant={props.action === "delete" ? "contained" : "text"}
          color={props.action === "delete" ? "error" : "grey700"}
          fullWidth
          sx={{
            mb: 3,
            "&.MuiButton-textGrey700": {
              backgroundColor: (theme) => theme.palette.grey200.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey100.main,
              },
            },
          }}
          onClick={() => props.handleConfirmation(true)}
        >
          {props.btnOne}
        </Button>
        <Button
          id="confirmation-btn-2"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => props.handleConfirmation(false)}
        >
          {props.btnTwo}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmationModal;
