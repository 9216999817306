import { FC } from "react";

import componentProvider from "./componentProvider";

const ProviderComposer: FC = ({ children }) => (
  <>
    {componentProvider.reduceRight(
      (accumulator, Composer) => (
        <Composer>{accumulator}</Composer>
      ),
      children
    )}
  </>
);

export default ProviderComposer;
