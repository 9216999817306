import { v4 as uuidv4 } from "uuid";
import mixpanel from "mixpanel-browser";

const generateSessionId = () => {
  let token = localStorage.getItem("token");
  const hasSessionId = mixpanel.get_property("Session_id");

  if (!token || !hasSessionId) {
    mixpanel.register({
      Session_id: uuidv4(),
    });
  }
};

export default generateSessionId;
