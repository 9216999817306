import router from "next/router";
import { split, ApolloClient, InMemoryCache, from } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

//Initialize the client with a terminating Apollo Link using createUploadLink.
const uploadLink: any = createUploadLink({
  uri: `https://${process.env.NEXT_PUBLIC_GRAPHQL_SERVER}`,
});

const wsLink = process.browser
  ? new WebSocketLink({
      uri: `wss://${process.env.NEXT_PUBLIC_WEBSOCKET_SERVER}`,
      options: {
        reconnect: true,
        connectionParams: () => ({
          authToken: localStorage.getItem("token") || "",
        }),
        connectionCallback: () => {
          // exception handling for web socket authentication.
          // nothing to do here as we already have token validation in index
        },
      },
    })
  : undefined;

const splitLink = process.browser
  ? split(
      //only create the split in the browser
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation !== "subscription"
        );
      },
      uploadLink,
      wsLink
    )
  : uploadLink;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const url = window.location.pathname;

      if (url === "/facebookbutton") return;

      if (message === "Agent is Inactive") {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        router.push(`${window.location.origin}/NotFound`);
      }
      if (message === "authentication token is invalid") {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        router.push(`${window.location.origin}`);
      }
    });
  }

  if (networkError) {
    const neterror = JSON.stringify(networkError);
    const jsonneterror = JSON.parse(neterror);

    if (jsonneterror.statusCode == "403") {
      localStorage.clear();
      router.push("error")
    } else if (jsonneterror.statusCode == "422") {
      return;
    } else {
      return;
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  queryDeduplication: false,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chatList: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming;
              return incoming;
            },
          },
          messageList: {
            keyArgs: ["input", ["roomID"]],
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming;

              if (incoming.messages.length > 0) {
                return incoming;
              } else {
                return {
                  ...existing,
                  pageInfo: { ...incoming.pageInfo },
                };
              }
            },
          },
          fetchMessageByID: {
            keyArgs: ["input", ["messageID"]],
            merge(existing, incoming) {
              if (!incoming) return existing;
              if (!existing) return incoming;

              if (incoming.messages.length > 0) {
                return incoming;
              } else {
                const pageInfo = {
                  ...incoming.pageInfo,
                  hasMore: false,
                };
                return {
                  ...existing,
                  pageInfo: { ...pageInfo },
                };
              }
            },
          },
        },
      },
    },
  }),
});

export default client;
