const checkDeviceType = (): "mobile" | "desktop" => {
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobileDevice) return "mobile";
  return "desktop";
};

export default checkDeviceType;
