export const AnalyticIconSVG = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M-0.000488281 10.2857C-0.000488281 9.57563 0.559156 9 1.24951 9H3.74951C4.43987 9 4.99951 9.57563 4.99951 10.2857V16.7143C4.99951 17.4244 4.43987 18 3.74951 18H1.24951C0.559156 18 -0.000488281 17.4244 -0.000488281 16.7143V10.2857Z" />
    <path d="M7.49951 5.14286C7.49951 4.43278 8.05916 3.85714 8.74951 3.85714H11.2495C11.9399 3.85714 12.4995 4.43278 12.4995 5.14286V16.7143C12.4995 17.4244 11.9399 18 11.2495 18H8.74951C8.05916 18 7.49951 17.4244 7.49951 16.7143V5.14286Z" />
    <path d="M14.9995 1.28571C14.9995 0.575634 15.5592 0 16.2495 0H18.7495C19.4399 0 19.9995 0.575634 19.9995 1.28571V16.7143C19.9995 17.4244 19.4399 18 18.7495 18H16.2495C15.5592 18 14.9995 17.4244 14.9995 16.7143V1.28571Z" />
  </svg>
);
