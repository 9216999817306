import { NavMenuType } from "./types";
import {
  HomeIconSVG,
  ChatIconSVG,
  SettingIconSVG,
  AgentIconSVG,
  ChannelIconSVG,
  AnalyticIconSVG,
} from "./assets";

const navMenus: NavMenuType[] = [
  {
    id: "side-menu-home",
    label: "Beranda",
    url: "/DashboardHome",
    trackerName: "chatv2_menu_beranda",
    page: "DashboardHome",
    supervisorAccessOnly: false,
    icon: <HomeIconSVG />,
  },
  {
    id: "side-menu-chat",
    label: "Percakapan",
    url: "/chat",
    trackerName: "chatv2_menu_percakapan",
    page: "admin",
    supervisorAccessOnly: false,
    icon: <ChatIconSVG />,
  },
  {
    id: "side-menu-settings",
    label: "Pengaturan",
    url: "/setting",
    trackerName: "chatv2_menu_pengaturan",
    page: "settings",
    supervisorAccessOnly: false,
    icon: <SettingIconSVG />,
  },
  {
    id: "side-menu-account-manager",
    label: "Manajemen Agen",
    url: "/AccountManager",
    trackerName: "chatv2_menu_manajemen_agen",
    page: "accountmanager",
    supervisorAccessOnly: true,
    icon: <AgentIconSVG />,
  },
  {
    id: "side-menu-channels",
    label: "Channel Percakapan",
    url: "/channels",
    trackerName: "chatv2_menu_channel_percakapan",
    page: "channels",
    supervisorAccessOnly: false,
    icon: <ChannelIconSVG />,
  },
  {
    id: "side-menu-analytics",
    label: "Laporan",
    url: "/analytic",
    trackerName: "chatv2_menu_laporan",
    page: "analytics",
    supervisorAccessOnly: true,
    icon: <AnalyticIconSVG />,
  },
];

export default navMenus;
