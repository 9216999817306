import { Box, Skeleton } from "@mui/material";

import palette from "../../../styles/utils/_exports.module.scss";

type DotLoadingProps = {
  size?: number;
  gap?: number;
  dots?: number;
  color?: string;
};

const DotLoading: React.FC<DotLoadingProps> = ({
  size = 4,
  gap = 3,
  dots = 3,
  color = palette.grey400,
}) => {
  return (
    <Box display="flex" gap={`${gap}px`}>
      {[...Array(dots)].map((_, i) => (
        <Skeleton
          variant="circular"
          width={size}
          height={size}
          sx={{ bgcolor: color }}
          key={i}
        />
      ))}
    </Box>
  );
};

export default DotLoading;
