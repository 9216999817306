import React, { ReactNode, useContext, createContext, useState } from "react";

type chatMenuVisibleContextType = {
  chatMenuVisible: boolean;
  setChatMenuVisible: (value: boolean) => void;
};

const chatMenuVisibleContextTypeDefaultValues: chatMenuVisibleContextType = {
  chatMenuVisible: true,
  setChatMenuVisible: (value: boolean) => {},
};

const ActiveChatMenuContext = createContext<chatMenuVisibleContextType>(
  chatMenuVisibleContextTypeDefaultValues
);

export function useActiveChatMenu() {
  return useContext(ActiveChatMenuContext);
}

type Props = {
  children: ReactNode;
};

export function ChatMenuVisibleProvider({ children }: Props) {
  const [chatMenuVisible, setChatMenuVisibleStatus] = useState<boolean>(true);

  const setChatMenuVisible = (value: boolean) => {
    setChatMenuVisibleStatus(value);
  };

  const value = {
    chatMenuVisible,
    setChatMenuVisible,
  };

  return (
    <>
      <ActiveChatMenuContext.Provider value={value}>
        {children}
      </ActiveChatMenuContext.Provider>
    </>
  );
}
