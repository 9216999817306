export const ArrowBack = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5171 7.63994H4.36189L9.35617 2.83613C9.47247 2.72415 9.53959 2.57061 9.5428 2.4092C9.54601 2.24779 9.48506 2.0917 9.37332 1.97518L8.52189 1.09137C8.41024 0.975192 8.25701 0.908121 8.09591 0.904906C7.93482 0.901691 7.77904 0.962596 7.66284 1.07422L0.18665 8.26661C0.128352 8.32277 0.0818239 8.38998 0.0497824 8.46431C0.0177409 8.53865 0.000827536 8.61862 2.96148e-05 8.69957C-0.000768306 8.78051 0.0145652 8.86081 0.045135 8.93576C0.0757049 9.01072 0.120899 9.07883 0.178079 9.13613L7.65427 16.5971C7.89236 16.8352 8.27713 16.8352 8.51427 16.5961L9.38094 15.7276C9.49492 15.6133 9.55894 15.4585 9.55894 15.2971C9.55894 15.1357 9.49492 14.9809 9.38094 14.8666L4.54379 10.0409H19.5171C19.7838 10.0409 20 9.82565 20 9.55803V8.12184C20 8.05843 19.9875 7.99565 19.9632 7.93706C19.939 7.87848 19.9034 7.82525 19.8586 7.78041C19.8137 7.73557 19.7605 7.70001 19.7019 7.67574C19.6433 7.65148 19.5805 7.63899 19.5171 7.63899"
      fill="#222C33"
    />
  </svg>
);
