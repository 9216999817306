import { createContext, useContext } from "react";

export enum Tour {
  ONBOARDING_CHANNEL = "ONBOARDING_CHANNEL",
  ONBOARDING_MIGRATION_V2 = "ONBOARDING_MIGRATION_V2",
}

export enum TourStatus {
  STARTED = "STARTED",
  SKIPPED = "SKIPPED",
  DONE = "DONE",
}

export const SET_TOUR = "SET_TOUR";
export const SET_STATUS_TOUR = "SET_STATUS_TOUR";
export const RESET_TOUR = "RESET_TOUR";

export type TTourState = {
  tour?: Tour;
  run: boolean;
  status: TourStatus;
};

type TActionSetTour = {
  type: typeof SET_TOUR;
  payload: {
    tour: Tour;
  };
};

type TActionSetStatusTour = {
  type: typeof SET_STATUS_TOUR;
  payload: {
    status: TourStatus;
  };
};

type TActionResetTour = {
  type: typeof RESET_TOUR;
};

export type TAction = TActionSetTour | TActionResetTour | TActionSetStatusTour;

export type TourContextProps = {
  state: TTourState;
  dispatch: React.Dispatch<TAction>;
};

export const initialState: TTourState = {
  run: false,
  status: TourStatus.STARTED,
};

export const reducer = (state: TTourState, action: TAction): TTourState => {
  switch (action.type) {
    case SET_TOUR:
      return {
        ...state,
        run: true,
        tour: action.payload.tour,
        status: TourStatus.STARTED,
      };
    case SET_STATUS_TOUR:
      return {
        ...state,
        status: action.payload.status,
      };
    case RESET_TOUR:
      return {
        ...state,
        run: false,
        tour: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};

const TourContext = createContext({} as TourContextProps);

export default TourContext;

export const useTourContext = () => useContext(TourContext);
