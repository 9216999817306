import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import { useMediaQuery, useTheme } from "@mui/material";

import { useActiveRoom } from "../../context/RoomContext";

declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

const HubSpot: FC = ({ children }) => {
  const theme = useTheme();
  const router = useRouter();
  const matches = useMediaQuery(theme.breakpoints.down(991), {
    noSsr: true,
  });

  const { activeRoom } = useActiveRoom();

  useEffect(() => {
    if (window.location.pathname === "/facebookbutton") return;
    const script = document.createElement("script");
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js.hs-scripts.com/8269462.js";
    document.body.appendChild(script);
  }, []);

  // handle hubspot on mobile screen
  useEffect(() => {
    if (!window.HubSpotConversations || !matches) return;
    if (!activeRoom && router.pathname !== "/chat") {
      loadChat();
      return;
    }
    removeChat();
  }, [activeRoom, matches, router]);

  // handle hubspot on desktop screen
  useEffect(() => {
    if (!window.HubSpotConversations || matches) return;
    loadChat();
  }, [matches]);

  const loadChat = () => {
    return window.HubSpotConversations.widget.load();
  };

  const removeChat = () => {
    return window.HubSpotConversations.widget.remove({ resetWidget: true });
  };

  return <>{children}</>;
};

export default HubSpot;
