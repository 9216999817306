import { useEffect } from "react";
import { toast } from "react-toastify";
import useSound from "use-sound";
import { SvgIcon } from "@mui/material";

import { useListMessagesSubscription } from "../graphql";
import { useTenantContext } from "../components/Authentication/AuthContainer";
import { notificationMessage } from "../helpers/utils";
import { useActiveRoom } from "./RoomContext";

export const NotificationProvider: React.FC = ({ children }) => {
  const { tenant } = useTenantContext();
  const { activeRoom } = useActiveRoom();

  const { data } = useListMessagesSubscription({
    variables: {
      input: {
        userId: tenant?.userID ?? "",
        token: tenant?.token ?? "",
      },
    },
  });

  const boopSfx = require("../sounds/sound.mp3");
  const [play] = useSound(boopSfx);

  useEffect(() => {
    if (!data?.receiveMessage.message) return;

    if (
      (data?.receiveMessage?.message?.sender !== tenant?.userID &&
        data?.receiveMessage?.room?.id !== activeRoom?.id) ||
      activeRoom?.id === undefined
    ) {
      if (
        tenant?.role !== "ADMIN" ||
        data.receiveMessage.accountUser?.id !== tenant.userID ||
        data.receiveMessage.message.sendByAgent
      ) {
        return;
      }

      toast(
        <div className="toast">
          <div className="toast-icon">
            <SvgIcon width="20" height="20">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 2.5V12.5C20 13.8807 18.8807 15 17.5 15H11.25L5 20V15H2.5C1.11929 15 0 13.8807 0 12.5V2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5ZM6.25 6.25H3.75V8.75H6.25V6.25ZM8.75 6.25H11.25V8.75H8.75V6.25ZM16.25 6.25H13.75V8.75H16.25V6.25Z"
                fill="#2AAADA"
              />
            </SvgIcon>
          </div>
          <div className="toast-text">
            <h4>{data.receiveMessage.contactUser?.name}</h4>
            <p>{notificationMessage(data.receiveMessage.message.message)}</p>
          </div>
        </div>
      );
      play();
    }
  }, [data]);

  return <>{children}</>;
};
