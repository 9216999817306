import React, { ReactNode, useContext, createContext, useState } from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import { ChatListRoom } from "../graphql";

type ActiveRoomContextType = {
  activeRoom: Maybe<ChatListRoom> | undefined;
  setRoom: (room: Maybe<ChatListRoom> | undefined) => void;
};

const activeRoomContextDefaultValues: ActiveRoomContextType = {
  activeRoom: null,
  setRoom: (room: Maybe<ChatListRoom> | undefined) => {},
};

const ActiveRoomContext = createContext<ActiveRoomContextType>(
  activeRoomContextDefaultValues
);

export function useActiveRoom() {
  return useContext(ActiveRoomContext);
}

type Props = {
  children: ReactNode;
};

export function ActiveRoomProvider({ children }: Props) {
  const [activeRoom, setactiveRoom] = useState<
    Maybe<ChatListRoom> | undefined
  >();

  const setRoom = (room: Maybe<ChatListRoom> | undefined) => {
    setactiveRoom(room);
  };

  const value = {
    activeRoom,
    setRoom,
  };

  return (
    <>
      <ActiveRoomContext.Provider value={value}>
        {children}
      </ActiveRoomContext.Provider>
    </>
  );
}
