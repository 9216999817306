import { Box, CircularProgress, Typography } from "@mui/material";

type LoadingScreenProps = {
  message?: JSX.Element;
};

const LoadingScreen = ({ message }: LoadingScreenProps) => {
  return (
    <Box
      display="flex"
      bgcolor="grey50.main"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      gap={2}
    >
      <CircularProgress size={24} sx={{ color: "grey700.main" }} />
      <Typography variant="body2" color="grey700.main" textAlign="center">
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
