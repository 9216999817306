import { FC, createContext, useContext, useEffect } from "react";
import { useRouter } from "next/router";

import usePersistedState from "../../helpers/usePersistedState";
import { TBroadcastForm } from "./types";
import { persistedDataRoutes } from "./constants";

type ContextProps = {
  broadcastContext: TBroadcastForm;
  setBroadcastContext: (value: TBroadcastForm) => void;
};

const BroadcastContext = createContext({} as ContextProps);

const BROADCAST_DATA = "broadcast_data";

export const BroadcastProvider: FC = ({ children }) => {
  const router = useRouter();
  const [broadcastContext, setBroadcastContext] = usePersistedState(
    BROADCAST_DATA,
    {}
  );

  useEffect(() => {
    const pathWithPersistedContext = persistedDataRoutes.includes(
      router.pathname
    );
    const hasBroadcastData = localStorage.getItem(BROADCAST_DATA);
    if (!pathWithPersistedContext && hasBroadcastData) {
      localStorage.removeItem(BROADCAST_DATA);
    }
  }, [router]);

  return (
    <BroadcastContext.Provider
      value={{ broadcastContext, setBroadcastContext }}
    >
      {children}
    </BroadcastContext.Provider>
  );
};

export const useBroadcastContext = () => {
  return useContext(BroadcastContext);
};
