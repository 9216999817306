export const ChannelIconSVG = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H6V18C6 18.4045 6.24364 18.7691 6.61732 18.9239C6.99099 19.0787 7.42111 18.9931 7.70711 18.7071L12.4142 14H17C18.6569 14 20 12.6569 20 11V3C20 1.34315 18.6569 0 17 0H3ZM14 4.5C14 5.32843 13.3284 6 12.5 6C12.0955 6 11.7285 5.83992 11.4587 5.57966L8.98866 6.81467C8.99615 6.8754 9 6.93725 9 7C9 7.06274 8.99615 7.12459 8.98867 7.1853L11.4587 8.42032C11.7285 8.16007 12.0955 8 12.5 8C13.3284 8 14 8.67157 14 9.5C14 10.3284 13.3284 11 12.5 11C11.6716 11 11 10.3284 11 9.5C11 9.43725 11.0039 9.3754 11.0113 9.31467L8.54131 8.07966C8.27153 8.33992 7.90446 8.5 7.5 8.5C6.67157 8.5 6 7.82843 6 7C6 6.17157 6.67157 5.5 7.5 5.5C7.90445 5.5 8.27152 5.66007 8.5413 5.92032L11.0113 4.6853C11.0039 4.62458 11 4.56274 11 4.5C11 3.67157 11.6716 3 12.5 3C13.3284 3 14 3.67157 14 4.5Z"
    />
  </svg>
);
