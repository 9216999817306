import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { checkDeviceType, generateSessionId } from "./helpers";
import { useTenantContext } from "../../../Authentication/AuthContainer";

const Mixpanel: React.FC = ({ children }) => {
  const { tenant } = useTenantContext();

  useEffect(() => {
    if (!tenant || !tenant.username) return;

    const token = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

    if (!token) return;

    mixpanel.init(token, {
      debug: false,
      ignore_dnt: true,
      loaded: () => {
        if (!tenant.username) return;
        mixpanel.identify(tenant.username);

        generateSessionId();

        const brandId = window.location.hostname.split(".")[0];
        const deviceType = checkDeviceType();

        mixpanel.register({
          Site_id: brandId,
          Device_type: deviceType,
          User_agent: navigator.userAgent,
        });
      },
    });
  }, [tenant]);

  return <>{children}</>;
};

export default Mixpanel;
