import { BroadcastStatus } from "./types";

export const RECEIVED_MESSAGE_STATUS = ["SENT", "DELIVERED", "READ"];
export const BROADCAST_LIST_QUERY_LIMIT = 10;
export enum SUBSCRIBER_LIST_QUERY_LIMIT {
  _10 = 10,
  _25 = 25,
  _50 = 50,
}
export const BROADCAST_STATUS_CANCEL_BUTTON = [
  BroadcastStatus.STARTED,
  BroadcastStatus.COMPLETED,
  BroadcastStatus.INCOMPLETE,
  BroadcastStatus.CANCELED,
  BroadcastStatus.REJECTED,
  BroadcastStatus.FAILED,
];
export const BROADCAST_RECIPIENTS_LIMIT = 50;

export const persistedDataRoutes = [
  "/channel/[slug]/broadcast/create/send-broadcast",
  "/channel/[slug]/broadcast/create/import-contact",
];
