export const ChatIconSVG = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V11C20 12.6569 18.6569 14 17 14H12.4142L7.70711 18.7071C7.42111 18.9931 6.99099 19.0787 6.61732 18.9239C6.24364 18.7691 6 18.4045 6 18V14H3C1.34315 14 0 12.6569 0 11V3ZM5 7C5 6.44772 5.44772 6 6 6H6.01C6.56228 6 7.01 6.44772 7.01 7C7.01 7.55228 6.56228 8 6.01 8H6C5.44772 8 5 7.55228 5 7ZM9 7C9 6.44772 9.44771 6 10 6H10.01C10.5623 6 11.01 6.44772 11.01 7C11.01 7.55228 10.5623 8 10.01 8H10C9.44771 8 9 7.55228 9 7ZM13 7C13 6.44772 13.4477 6 14 6H14.01C14.5623 6 15.01 6.44772 15.01 7C15.01 7.55228 14.5623 8 14.01 8H14C13.4477 8 13 7.55228 13 7Z"
    />
  </svg>
);
