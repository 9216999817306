import { JSXElementConstructor, PropsWithChildren } from "react";

import { NotificationProvider } from "../../../context/NotificationProvider";
import { ActiveRoomProvider } from "../../../context/RoomContext";
import { ChatMenuVisibleProvider } from "../../../context/ChatMenuContext";
import { ChatFooterVisibleProvider } from "../../../context/ChatFooterContext";
import { UnSavedProvider } from "../../../context/UnSavedContext";
import { InteractiveProvider } from "../../ChatBody/InteractiveContext";
import TourProvider from "../../../components/ui_palette/OnboardingTour/TourProvider";
import HubSpot from "../../../components/HubSpot";
import Mixpanel from "../../../components/ui_palette/Tracker/Mixpanel";
import Snowplow from "../../../components/ui_palette/Tracker/Snowplow";
import { BroadcastProvider } from "../../Broadcast/BroadcastContext";

//hierarchy children to parent is from right to left
const components: JSXElementConstructor<PropsWithChildren<any>>[] = [
  ActiveRoomProvider,
  ChatMenuVisibleProvider,
  ChatFooterVisibleProvider,
  NotificationProvider,
  TourProvider,
  HubSpot,
  Mixpanel,
  Snowplow,
  UnSavedProvider,
  InteractiveProvider,
  BroadcastProvider,
];

export default components;
