import { createContext, useReducer, useContext } from "react";

import { InteractiveMessageType } from "./types";

export type TState = {
  open?: boolean;
  data?: InteractiveMessageType;
};

interface ContextProps {
  state: TState;
  dispatch: ({ type }: TAction) => void;
}

type TAction = { type: string; data?: InteractiveMessageType };

const InteractiveContext = createContext({} as ContextProps);

type TProps = {
  children: React.ReactElement;
};

export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

const initialState: TState = {
  open: false,
};

const reducer = (state: TState = initialState, action: TAction): TState => {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        open: true,
        data: action.data,
      };
    case CLOSE_DRAWER:
      return {
        open: false,
      };
    default:
      return state;
  }
};

export const InteractiveProvider = ({ children }: TProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <InteractiveContext.Provider value={{ state, dispatch }}>
      {children}
    </InteractiveContext.Provider>
  );
};

export const useInteractiveContext = () => {
  const contextValue = useContext(InteractiveContext);
  return contextValue;
};
