export const AgentIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8H4V14H1V8ZM16 8H19V14H16V8ZM10.5 19C11.3284 19 12 18.3284 12 17.5C12 16.6716 11.3284 16 10.5 16C9.67157 16 9 16.6716 9 17.5C9 18.3284 9.67157 19 10.5 19Z"
    />
    <path d="M10.0001 0C5.83969 0 2.45527 3.27957 2.45527 7.31102V8.10406H4.98982V7.31102C4.98982 4.09961 6.68604 2.48699 10.0001 2.48699C13.3142 2.48699 15.0104 4.09965 15.0104 7.31102V8.10406H17.545V7.31102C17.545 3.27957 14.1603 0 10.0001 0Z" />
    <path d="M4.29672 7.36059H2.40412C1.07854 7.36059 0 8.4057 0 9.6902V13.0855C0 14.37 1.07854 15.4151 2.40412 15.4151H4.29672C4.72052 15.4151 5.06397 15.0822 5.06397 14.6716V11.3879V8.1041C5.06401 7.69344 4.72052 7.36059 4.29672 7.36059ZM3.52946 13.9281H2.40412C1.91663 13.9281 1.53455 13.5581 1.53455 13.0855V9.69024C1.53455 9.21785 1.91667 8.84762 2.40412 8.84762H3.52946V13.9281Z" />
    <path d="M17.5959 7.36059H15.7035C15.2797 7.36059 14.9363 7.69344 14.9363 8.10406V14.6716C14.9363 15.0823 15.2798 15.4151 15.7035 15.4151H17.5961C18.9217 15.4151 20.0002 14.37 20 13.0855V9.6902C20 8.4057 18.9217 7.36059 17.5959 7.36059ZM18.4654 13.0855C18.4654 13.5581 18.0836 13.9281 17.5959 13.9281H16.4708V8.84758H17.5959C18.0836 8.84758 18.4654 9.21785 18.4654 9.6902V13.0855Z" />
    <path d="M16.5478 14.6716C16.5478 15.7103 15.6756 16.5552 14.6038 16.5552H12.5577V19.0421H14.6038C16.5217 19.0421 19.0821 16.5301 19.0823 14.6716H16.5478Z" />
    <path d="M13.2992 17.2818C13.2897 15.8003 12.0426 14.5975 10.5114 14.5975C8.97454 14.5975 7.72391 15.8092 7.72391 17.2987C7.72391 18.7882 8.98603 20 10.5372 20C11.3032 20 12.0411 19.6974 12.5613 19.1695C13.0662 18.6577 13.3275 17.9881 13.2992 17.2818ZM11.4523 18.1418C11.2198 18.3775 10.8863 18.5128 10.5375 18.5128C9.84435 18.5128 9.2587 17.9566 9.2587 17.2984C9.2587 16.629 9.82061 16.0843 10.5117 16.0843C11.2027 16.0843 11.7649 16.6293 11.7651 17.2981C11.7651 17.3095 11.7654 17.3207 11.7659 17.3321C11.7799 17.6347 11.6687 17.9224 11.4523 18.1418Z" />
  </svg>
);
