import { trackStructEvent } from "@snowplow/browser-tracker";

const snowplowTrack = (
  category: string,
  action: string,
  label?: string,
  property?: string,
  value?: number
) => {
  trackStructEvent(
    {
      category,
      action,
      label,
      property,
      value,
    },
    ["Chat V2"]
  );
};

export default snowplowTrack;
