import { Maybe } from "graphql/jsutils/Maybe";

import {
  AddMessageTemplateInput,
  MessageType,
  Subscriber,
} from "../../graphql";

export enum BroadcastStatus {
  PENDING = "PENDING",
  SCHEDULED = "SCHEDULED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  STARTED = "STARTED",
  INCOMPLETE = "INCOMPLETE",
  REBROADCAST = "REBROADCAST",
  COMPLETED = "COMPLETED",
}

export type TMessageTemplate = {
  id: number;
  key: string;
  value: string;
  type: MessageType;
  status: boolean;
  category: string;
  attachment?: string;
  attachmentType?: MessageType;
  statusText?: string;
  headerFormat?: string;
  headerText?: string;
  headerLink?: string;
  footer?: string;
};

export type TBroadcastForm = {
  broadcastTitle?: string;
  templateName?: string;
  newTemplate?: AddMessageTemplateInput;
  contactList?: Maybe<Maybe<Subscriber>[]>;
};

export type TPagination = {
  cursorBefore: Maybe<number>;
  cursorAfter: Maybe<number>;
};

export enum IMPORT_PURPOSE {
  IMPORT_ONLY = "IMPORT_ONLY",
  IMPORT_BROADCAST = "IMPORT_BROADCAST",
}
