import { FC, useState } from "react";
import { Box } from "@mui/material";

import LeftNav from "../LeftNav/LeftNav";
import Header from "../MainHeader/Header";
import { PageHeading } from "../ui_palette/PageHeading";

const Layout: FC<{
  title?: string;
  page?: string;
  padding?: string | number;
  backButton?: boolean;
}> = ({ children, title, page, padding, backButton }) => {
  const [sideMenuOpenState, setSideMenuOpenState] = useState(false);

  return (
    <Box className="main-container supervisor-page" sx={{ flexGrow: 1 }}>
      <Header setSideopen={setSideMenuOpenState} sideopen={sideMenuOpenState} />
      <Box className="main-chat-section">
        <Box
          className="left-nav-wrapper"
          style={sideMenuOpenState ? { left: 0 } : {}}
        >
          <LeftNav page={page ?? ""} setSideopen={setSideMenuOpenState} />
        </Box>
        <Box className="inner-page-section">
          {title && <PageHeading title={title} backButton={backButton} />}
          <Box
            className="page-content-container"
            style={padding !== undefined ? { padding: padding } : {}}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
