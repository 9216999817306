import { useEffect, FC } from "react";
import { newTracker, setUserId } from "@snowplow/browser-tracker";

import { useTenantContext } from "../../../Authentication/AuthContainer";
import checkPlatformType from "./helpers/checkPlatformType";

const Snowplow: FC = ({ children }) => {
  const { tenant } = useTenantContext();

  useEffect(() => {
    if (!tenant) return;

    const SNOWPLOW_TARGET = process.env.NEXT_PUBLIC_SNOWPLOW_TARGET ?? "";
    const platform = checkPlatformType();

    newTracker("Chat V2", SNOWPLOW_TARGET, {
      appId: tenant.brandID ?? "",
      platform,
      eventMethod: "post",
      contexts: {
        webPage: true,
      },
    });
    setUserId(tenant.username ?? "", ["Chat V2"]);
  }, [tenant]);

  return <>{children}</>;
};

export default Snowplow;
