import { createContext, useEffect, useContext, useState } from "react";
import { useRouter } from "next/router";

import {
  useAccessTokenLazyQuery,
  useAdminDeletedSubscription,
} from "../../graphql";
import { LoadingScreen } from "../ui_palette/Loading";
import { UNREQUIRED_AUTH_URL, WHITELISTED_BRAND } from ".";

export type TenantType = {
  userID?: string | null;
  username?: string | null;
  fullName?: string | null;
  role?: string | null;
  tenantID?: string | null;
  brandID?: string | null;
  expiresIn?: string | null;
  token?: string | null;
  clientType?: string | null;
};

export type TenantContextType = {
  tenant: TenantType | undefined;
  setTenant: (tenant: TenantType) => void;
  whitelistedBrand: string[];
};

const initialTenantContext = {
  tenant: {
    userID: typeof window !== "undefined" ? localStorage.getItem("userID") : "",
    username:
      typeof window !== "undefined" ? localStorage.getItem("username") : "",
    fullName:
      typeof window !== "undefined" ? localStorage.getItem("fullName") : "",
    role: typeof window !== "undefined" ? localStorage.getItem("role") : "",
    tenantID:
      typeof window !== "undefined" ? localStorage.getItem("tenantID") : "",
    brandID:
      typeof window !== "undefined" ? localStorage.getItem("brandID") : "",
    expiresIn: "",
    token: typeof window !== "undefined" ? localStorage.getItem("token") : "",
    clientType:
      typeof window !== "undefined" ? localStorage.getItem("clientType") : "",
  },
  setTenant: () => {},
  whitelistedBrand: WHITELISTED_BRAND,
};

export const TenantContext =
  createContext<TenantContextType>(initialTenantContext);

export const useTenantContext = () => {
  const { tenant, setTenant, whitelistedBrand } = useContext(TenantContext);
  return { tenant, setTenant, whitelistedBrand };
};

type AuthContainerProps = {
  children: React.ReactElement;
};

const AuthContainer = ({ children }: AuthContainerProps) => {
  const router = useRouter();
  const [tenantData, setTenantData] = useState<TenantType>(
    initialTenantContext.tenant
  );

  const [getUserData] = useAccessTokenLazyQuery({
    onCompleted: (data) => {
      if (data) {
        localStorage.setItem("token", data.token?.accessToken ?? "");
        localStorage.setItem("username", data.token?.userName ?? "");
        localStorage.setItem("userID", data.token?.id ?? "");
        localStorage.setItem("role", data.token?.role ?? "");
        localStorage.setItem("fullName", data.token?.fullName ?? "");
        localStorage.setItem("tenantID", data.token?.TenantID ?? "");
        localStorage.setItem(
          "brandID",
          window.location.hostname.split(".")[0] ?? ""
        );
        localStorage.setItem("clientType", data.token?.clientType ?? "");

        setTenantData({
          ...tenantData,
          userID: data.token?.id ?? "",
          username: data.token?.userName ?? "",
          fullName: data.token?.fullName ?? "",
          role: data.token?.role ?? "",
          tenantID: data.token?.TenantID ?? "",
          brandID: window.location.hostname.split(".")[0] ?? "",
          token: data.token?.accessToken ?? "",
          clientType: data.token?.clientType ?? "",
        });
  
        router.push("/DashboardHome");
      }
    },
    onError() {
      router.push("/error");
    },
  });

  const { data: dataAdminDeletedSubs } = useAdminDeletedSubscription({
    variables: {
      input: {
        token: tenantData?.token ?? "",
        accountID: tenantData?.userID ?? "",
      },
    },
  });

  // handle logout deleted user
  useEffect(() => {
    if (!dataAdminDeletedSubs) return;
    if (
      dataAdminDeletedSubs.adminDeleted.shouldLogout === true ||
      dataAdminDeletedSubs.adminDeleted === null
    ) {
      router.push("/logout");
    }
  }, [dataAdminDeletedSubs]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) localStorage.setItem("token", token);

    const input = {
      clientId: process.env.NEXT_PUBLIC_CLIENT_ID ?? "",
      redirectUri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }`,
      code: `${token}`,
      cc: "",
    };
    
    getUserData({
      variables: {
        input,
      },
    });
  }, []);

  return (
    <TenantContext.Provider
      value={{
        tenant: tenantData,
        setTenant: setTenantData,
        whitelistedBrand: WHITELISTED_BRAND,
      }}
    >
      {!tenantData.token && !UNREQUIRED_AUTH_URL.includes(router.pathname) ? (
        <LoadingScreen />
      ) : (
        <div>{children}</div>
      )}
    </TenantContext.Provider>
  );
};

export default AuthContainer;
