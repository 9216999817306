import { useEffect } from "react";
import router from "next/router";
import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "../styles/globals.css";
import "../styles/main.scss";
import client from "../apollo-client";
import muiTheme from "../styles/muiTheme";

import { Page } from "../types/page";
import { BaseLayout } from "../components/Layout";
import { AuthContainer, SAVED_LAST_PATH } from "../components/Authentication";
import ProviderComposer from "../components/ui_palette/ProviderComposer";

type Props = AppProps & {
  Component: Page;
};

const MyApp = ({ Component: NextComponent, pageProps }: Props) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
        
    const url = window.location.pathname;
    const token = localStorage.getItem("token");
    const accessCode = params.get("code");

    if (url === "/facebookbutton") return;
    if (accessCode) return;

    if (!token) {
      router.push(`${window.location.origin}`);
      localStorage.setItem(
        "lastPath",
        SAVED_LAST_PATH.includes(url) ? url : "/"
      );
    }
  }, []);

  const Layout = NextComponent.layout ?? BaseLayout;
  const AnyLayout = Layout as any;

  return (
    <>
      <CssBaseline />
      <ApolloProvider client={client}>
        <StyledEngineProvider>
          <ThemeProvider theme={muiTheme}>
            <AuthContainer>
              <ProviderComposer>
                <AnyLayout {...NextComponent}>
                  <NextComponent {...pageProps} />
                </AnyLayout>
              </ProviderComposer>
            </AuthContainer>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
