const CheckIconSVG = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7489 0.961263C12.0837 1.29633 12.0837 1.83959 11.7489 2.17466L4.89181 9.03866C4.55707 9.37373 4.01436 9.37373 3.67962 9.03866L0.251051 5.60666C-0.0836838 5.27159 -0.0836838 4.72833 0.251051 4.39326C0.585786 4.05819 1.1285 4.05819 1.46323 4.39326L4.28571 7.21857L10.5368 0.961263C10.8715 0.626193 11.4142 0.626193 11.7489 0.961263Z"
      fill="white"
    />
  </svg>
);

export default CheckIconSVG;
