export const SettingIconSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8615 1.46368C11.3877 -0.487892 8.61225 -0.487893 8.13847 1.46368C7.83242 2.72437 6.38806 3.32264 5.28021 2.64761C3.56523 1.60265 1.60266 3.56523 2.64761 5.28021C3.32264 6.38806 2.72437 7.83242 1.46368 8.13847C-0.487893 8.61225 -0.487892 11.3877 1.46368 11.8615C2.72437 12.1676 3.32264 13.6119 2.64761 14.7198C1.60266 16.4348 3.56523 18.3973 5.28021 17.3524C6.38806 16.6774 7.83242 17.2756 8.13847 18.5363C8.61225 20.4879 11.3877 20.4879 11.8615 18.5363C12.1676 17.2756 13.6119 16.6774 14.7198 17.3524C16.4348 18.3973 18.3973 16.4348 17.3524 14.7198C16.6774 13.6119 17.2756 12.1676 18.5363 11.8615C20.4879 11.3877 20.4879 8.61225 18.5363 8.13847C17.2756 7.83242 16.6774 6.38806 17.3524 5.28021C18.3973 3.56523 16.4348 1.60266 14.7198 2.64761C13.6119 3.32264 12.1676 2.72437 11.8615 1.46368ZM10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z"
    />
  </svg>
);
